<template>
  <div v-if="hidden === true">
    <forum-modal :hide-link="true"
                 :bionic="true"
                 :data-qa="dataQa"
                 @show="handleForumModalShow()"/>
  </div>
  <div v-else-if="inline">
    <!--<b-btn variant="bionic-nav-menu-item"-->
    <!--       @click="openSelfHelp()">-->
    <!--  <fluency-icon type="review" class="mr-2" />-->
    <!--  Self Help-->
    <!--</b-btn>-->
    <nuxt-link to="/insights/feedback/"
               :data-qa="`${dataQa}-userForum`"
               class="btn btn-bionic-nav-menu-item"
               @click="resetUserForumPage()">
      <fluency-icon type="learningMode" class="mr-2" />
      User Forum
    </nuxt-link>

    <forum-modal v-if="!hideSupportModal"
                 :bionic="true"
                 :data-qa="dataQa"
                 @show="handleForumModalShow()"/>
    <hr />

    <b-btn variant="bionic-nav-menu-item"
           :data-qa="`${dataQa}-searchResources`"
           @click="openSelfHelp()">
      <fluency-icon type="review" class="mr-2" /> Search Resources
    </b-btn>
  </div>
  <b-dropdown v-else variant="link-info" toggle-class="p-0 border-0 no-focus" toggle-tag="div" right no-caret>
    <template #button-content>
      <header-icon-notifications icon-type="helpChat"
                                 v-p-tooltip.bottom="`Help`"
                                 class="header-info dropdown-toggle "/>
    </template>
    <div class="section-title d-flex align-items-center mb-3">
      <fluency-icon type="helpChat" class="text-primary" size="md"/>
      <div class="font-weight-bold pl-2 pr-4 bg-white text-primary">
        Help
      </div>
      <div class="flex-grow-1 border-top"/>
    </div>
    <a href="http://help.fluency.inc/"
       target="_blank"
       class="dropdown-item">Help Center <fluency-icon type="linkOut" /></a>
    <b-dropdown-item @click="openSelfHelp()">Self Help</b-dropdown-item>
    <nuxt-link to="/insights/feedback/"
               :class="[
                 'dropdown-item',
                 'userForum'
               ]"
               @click="resetUserForumPage()"
    >
      User Forum
    </nuxt-link>

    <forum-modal/>
  </b-dropdown>
</template>
<script>
import ForumModal from '@/components/common/forum/forumModal'
import HeaderIconNotifications from '@/components/common/headerIconNotifications'

export default {
  name: 'FluencySupport',
  components: { HeaderIconNotifications, ForumModal },
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    },
    hideSupportModal: {
      type: Boolean,
      default: false
    },
    dataQa: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      notificationCount: 0
    }
  },
  computed: {
    capabilities () {
      return this.$store.getters.user?.capabilities || {}
    }
  },
  methods: {
    openSelfHelp () {
      const button = document.getElementById('_wfx_self_help_launcher')
      if (button) {
        this.$emit('itemClick', { item: 'selfHelp' })
        button.click()
      }
    },
    resetUserForumPage () {
      this.$emit('itemClick', { item: 'userForum' })
      this.$store.commit('forum/resetState')
    },
    handleForumModalShow () {
      this.$emit('itemClick', { item: 'requestSupport' })
    }
  }
}
</script>
