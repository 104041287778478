import { default as crawlerDataYaceJV1rtkMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/account/crawlerData.vue?macro=true";
import { default as crawlerDebugBrowserFT1eJtJmmwMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/account/crawlerDebugBrowser.vue?macro=true";
import { default as indexphU3gYis8pMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/alerts/anomalies/index.vue?macro=true";
import { default as indexNYKqdjV5osMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/alerts/index.vue?macro=true";
import { default as api_45doc0Y1LrA6EHHMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/api-doc.vue?macro=true";
import { default as bionic25xFkw6pU0DMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/bionic/bionic2.vue?macro=true";
import { default as bionic3CCWoeJmjdGMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/bionic/bionic3.vue?macro=true";
import { default as bionic4stfQhr7u0kMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/bionic/bionic4.vue?macro=true";
import { default as indexMRwutDZGIEMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/bionic/index.vue?macro=true";
import { default as indexPbDm9WLwQIMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/blueprint-editor/index.vue?macro=true";
import { default as config7tD9MMwAe0Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/blueprints/config.vue?macro=true";
import { default as indexIWTAYQJFGmMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/blueprints/index.vue?macro=true";
import { default as tag_45reviewJbeGsgtIicMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/blueprints/tag-review.vue?macro=true";
import { default as bulk_45editpivdsvqsuDMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/bulk-edit.vue?macro=true";
import { default as indexAWfCQAldNKMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/bulk/index.vue?macro=true";
import { default as receiptL8jmRZake6Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/bulk/receipt.vue?macro=true";
import { default as colabagHPBqrKvNMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/colab.vue?macro=true";
import { default as broadcastPdAwlBNiThMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/customer/broadcast.vue?macro=true";
import { default as example_45widget7cRmfTLORaMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/dashboard/example-widget.vue?macro=true";
import { default as indexMOZwTynogIMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/dashboard/index.vue?macro=true";
import { default as deckHelperPxySJNHiA7Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/deckHelper.vue?macro=true";
import { default as errorlKWL027ML7Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/error.vue?macro=true";
import { default as html5_45adsIHr5buVRwnMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/html5-ads.vue?macro=true";
import { default as indexlS2LJyRQxAMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/index.vue?macro=true";
import { default as account_45performanceLmKbaYrtPCMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/account-performance.vue?macro=true";
import { default as analyst_45scorecardkaqW9BMwO8Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/analyst-scorecard.vue?macro=true";
import { default as budget_45adjustmentsv5u9KvKUlWMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/budget-adjustments.vue?macro=true";
import { default as budget_45groups1kuf2RtmjwMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/budget-groups.vue?macro=true";
import { default as change_45historyiYjQBbOrafMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/change-history.vue?macro=true";
import { default as crawlerVKwuINyapVMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/crawler.vue?macro=true";
import { default as feedback0uh7dxxxDMMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/feedback.vue?macro=true";
import { default as keyword_45recommendationscB6kjgbOGjMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/keyword-recommendations.vue?macro=true";
import { default as logsW52n5CxaetMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/logs.vue?macro=true";
import { default as partner_45sync_45statusrkL2Jrz4M0Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/partner-sync-status.vue?macro=true";
import { default as peer_45insights4T0mcYt0PoMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/peer-insights.vue?macro=true";
import { default as performance_45reviewzbo89x9DhIMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/performance-review.vue?macro=true";
import { default as reverse_45syncowU5WZHbY7Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/insights/reverse-sync.vue?macro=true";
import { default as adRenderersEIOIonJ0RMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/adRenderer.vue?macro=true";
import { default as ai_45helpaEuGXZNFM7Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/ai-help.vue?macro=true";
import { default as api_45testsmCmUSJZUFMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/api-test.vue?macro=true";
import { default as blueprint_45demorP0AZFzG2pMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/blueprint-demo.vue?macro=true";
import { default as blueprint_45tag_45languageQFFYuerPykMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/blueprint-tag-language.vue?macro=true";
import { default as chartRenderIG1XPTSmYZMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/chartRender.vue?macro=true";
import { default as colab_45managerrlPDz4GX8vMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/colab-manager.vue?macro=true";
import { default as colab_45testm6KztBUkPJMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/colab-test.vue?macro=true";
import { default as indexgdJaWN1qAoMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/customers/index.vue?macro=true";
import { default as drag_45n_45dropjjKEDTtTjPMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/drag-n-drop.vue?macro=true";
import { default as dynamic_45form533Rn7YE6EMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/dynamic-form.vue?macro=true";
import { default as dynamic_45reportppYSncCZ5PMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/dynamic-report.vue?macro=true";
import { default as engineeringAuditCustomerBreakdownqKSJCkHuPXMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/engineering-audit/engineeringAuditCustomerBreakdown.vue?macro=true";
import { default as engineering_45auditEaVOpdzCjAMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/engineering-audit.vue?macro=true";
import { default as indexvBpPTRAaQkMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/index.vue?macro=true";
import { default as interact_45but_45goodX1Yzq9im7ZMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/interact-but-good.vue?macro=true";
import { default as layout_45builderX28pb34QjZMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/layout-builder.vue?macro=true";
import { default as logocUFvJKiss6Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/logo.vue?macro=true";
import { default as muse_45chatvDZBwn8XNSMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/muse-chat.vue?macro=true";
import { default as prime_45vueqwbzxQs3SAMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/prime-vue.vue?macro=true";
import { default as sandbox4HKqbVtLW4Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/sandbox.vue?macro=true";
import { default as smoke_45testhdH5woHQnaMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/smoke-test.vue?macro=true";
import { default as style_45guidexN6cPP8hy6Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/style-guide.vue?macro=true";
import { default as vue3LUaVa4wVxVMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/internal/vue3.vue?macro=true";
import { default as indextCm24YlpW7Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/launch/index.vue?macro=true";
import { default as indexegwPzweM1mMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/launch/status/index.vue?macro=true";
import { default as launchiijIny8BV3Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/launch.vue?macro=true";
import { default as indexy6jX0RVtRwMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/login/index.vue?macro=true";
import { default as _91manageType_939yUeVKq3qhMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/manage/[manageType].vue?macro=true";
import { default as indexBtu6XiU9TZMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/manage/create/index.vue?macro=true";
import { default as indexDkC39XTdZgMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/manage/index.vue?macro=true";
import { default as manage9vYLryulmmMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/manage.vue?macro=true";
import { default as office_45mateEpizr8wAPiMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/office-mate.vue?macro=true";
import { default as page_45not_45foundLXWxdyP54vMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/page-not-found.vue?macro=true";
import { default as indexez38VUvewwMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/prospecting/index.vue?macro=true";
import { default as billingHel0hMl11xMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/billing.vue?macro=true";
import { default as data_45explorerklcL7p7gpMMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/data-explorer.vue?macro=true";
import { default as historyAuef5UzVHcMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/history.vue?macro=true";
import { default as indexMfX9BNAXExMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/index.vue?macro=true";
import { default as previewdgPXBf3Zr4Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/preview.vue?macro=true";
import { default as account_45ingestR271dMgOL7Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/settings/account-ingest.vue?macro=true";
import { default as crawlSiteDetectorTYOvcXSfQWMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/settings/crawlSiteDetector.vue?macro=true";
import { default as customerTafpy86RLTMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/settings/customer.vue?macro=true";
import { default as customerPackageSelectoreDTcodQ5b3Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/settings/customerPackageSelector.vue?macro=true";
import { default as data48m7P2KXqxMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/settings/data.vue?macro=true";
import { default as fluency9urBfDfFeOMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/settings/fluency.vue?macro=true";
import { default as shared_45audiences1gonZIRYTFMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/settings/shared-audiences.vue?macro=true";
import { default as target_45setsiRDZqJSW08Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/settings/target-sets.vue?macro=true";
import { default as _91partner_9365EyqE3WRXMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/setup/[partner].vue?macro=true";
import { default as indexOTPTcd4PyyMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/setup/index.vue?macro=true";
import { default as statusj4oA9FnEc5Meta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/status.vue?macro=true";
import { default as indexSWpeDAN5DFMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/style/index.vue?macro=true";
import { default as indexILMqUCCmblMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/welcome/index.vue?macro=true";
import { default as welcome7uIJWbzxtYMeta } from "/home/production/git/fluent-ui/apps/backpack/src/pages/welcome.vue?macro=true";
export default [
  {
    name: crawlerDataYaceJV1rtkMeta?.name ?? "account-crawlerData",
    path: crawlerDataYaceJV1rtkMeta?.path ?? "/account/crawlerData",
    meta: crawlerDataYaceJV1rtkMeta || {},
    alias: crawlerDataYaceJV1rtkMeta?.alias || [],
    redirect: crawlerDataYaceJV1rtkMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/account/crawlerData.vue").then(m => m.default || m)
  },
  {
    name: crawlerDebugBrowserFT1eJtJmmwMeta?.name ?? "account-crawlerDebugBrowser",
    path: crawlerDebugBrowserFT1eJtJmmwMeta?.path ?? "/account/crawlerDebugBrowser",
    meta: crawlerDebugBrowserFT1eJtJmmwMeta || {},
    alias: crawlerDebugBrowserFT1eJtJmmwMeta?.alias || [],
    redirect: crawlerDebugBrowserFT1eJtJmmwMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/account/crawlerDebugBrowser.vue").then(m => m.default || m)
  },
  {
    name: indexphU3gYis8pMeta?.name ?? "alerts-anomalies",
    path: indexphU3gYis8pMeta?.path ?? "/alerts/anomalies",
    meta: indexphU3gYis8pMeta || {},
    alias: indexphU3gYis8pMeta?.alias || [],
    redirect: indexphU3gYis8pMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/alerts/anomalies/index.vue").then(m => m.default || m)
  },
  {
    name: indexNYKqdjV5osMeta?.name ?? "alerts",
    path: indexNYKqdjV5osMeta?.path ?? "/alerts",
    meta: indexNYKqdjV5osMeta || {},
    alias: indexNYKqdjV5osMeta?.alias || [],
    redirect: indexNYKqdjV5osMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/alerts/index.vue").then(m => m.default || m)
  },
  {
    name: api_45doc0Y1LrA6EHHMeta?.name ?? "api-doc",
    path: api_45doc0Y1LrA6EHHMeta?.path ?? "/api-doc",
    meta: api_45doc0Y1LrA6EHHMeta || {},
    alias: api_45doc0Y1LrA6EHHMeta?.alias || [],
    redirect: api_45doc0Y1LrA6EHHMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/api-doc.vue").then(m => m.default || m)
  },
  {
    name: bionic25xFkw6pU0DMeta?.name ?? "bionic-bionic2",
    path: bionic25xFkw6pU0DMeta?.path ?? "/bionic/bionic2",
    meta: bionic25xFkw6pU0DMeta || {},
    alias: bionic25xFkw6pU0DMeta?.alias || [],
    redirect: bionic25xFkw6pU0DMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/bionic/bionic2.vue").then(m => m.default || m)
  },
  {
    name: bionic3CCWoeJmjdGMeta?.name ?? "bionic-bionic3",
    path: bionic3CCWoeJmjdGMeta?.path ?? "/bionic/bionic3",
    meta: bionic3CCWoeJmjdGMeta || {},
    alias: bionic3CCWoeJmjdGMeta?.alias || [],
    redirect: bionic3CCWoeJmjdGMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/bionic/bionic3.vue").then(m => m.default || m)
  },
  {
    name: bionic4stfQhr7u0kMeta?.name ?? "bionic-bionic4",
    path: bionic4stfQhr7u0kMeta?.path ?? "/bionic/bionic4",
    meta: bionic4stfQhr7u0kMeta || {},
    alias: bionic4stfQhr7u0kMeta?.alias || [],
    redirect: bionic4stfQhr7u0kMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/bionic/bionic4.vue").then(m => m.default || m)
  },
  {
    name: indexMRwutDZGIEMeta?.name ?? "bionic",
    path: indexMRwutDZGIEMeta?.path ?? "/bionic",
    meta: indexMRwutDZGIEMeta || {},
    alias: indexMRwutDZGIEMeta?.alias || [],
    redirect: indexMRwutDZGIEMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/bionic/index.vue").then(m => m.default || m)
  },
  {
    name: indexPbDm9WLwQIMeta?.name ?? "blueprint-editor",
    path: indexPbDm9WLwQIMeta?.path ?? "/blueprint-editor",
    meta: indexPbDm9WLwQIMeta || {},
    alias: indexPbDm9WLwQIMeta?.alias || [],
    redirect: indexPbDm9WLwQIMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/blueprint-editor/index.vue").then(m => m.default || m)
  },
  {
    name: config7tD9MMwAe0Meta?.name ?? "blueprints-config",
    path: config7tD9MMwAe0Meta?.path ?? "/blueprints/config",
    meta: config7tD9MMwAe0Meta || {},
    alias: config7tD9MMwAe0Meta?.alias || [],
    redirect: config7tD9MMwAe0Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/blueprints/config.vue").then(m => m.default || m)
  },
  {
    name: indexIWTAYQJFGmMeta?.name ?? "blueprints",
    path: indexIWTAYQJFGmMeta?.path ?? "/blueprints",
    meta: indexIWTAYQJFGmMeta || {},
    alias: indexIWTAYQJFGmMeta?.alias || [],
    redirect: indexIWTAYQJFGmMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/blueprints/index.vue").then(m => m.default || m)
  },
  {
    name: tag_45reviewJbeGsgtIicMeta?.name ?? "blueprints-tag-review",
    path: tag_45reviewJbeGsgtIicMeta?.path ?? "/blueprints/tag-review",
    meta: tag_45reviewJbeGsgtIicMeta || {},
    alias: tag_45reviewJbeGsgtIicMeta?.alias || [],
    redirect: tag_45reviewJbeGsgtIicMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/blueprints/tag-review.vue").then(m => m.default || m)
  },
  {
    name: bulk_45editpivdsvqsuDMeta?.name ?? "bulk-edit",
    path: bulk_45editpivdsvqsuDMeta?.path ?? "/bulk-edit",
    meta: bulk_45editpivdsvqsuDMeta || {},
    alias: bulk_45editpivdsvqsuDMeta?.alias || [],
    redirect: bulk_45editpivdsvqsuDMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/bulk-edit.vue").then(m => m.default || m)
  },
  {
    name: indexAWfCQAldNKMeta?.name ?? "bulk",
    path: indexAWfCQAldNKMeta?.path ?? "/bulk",
    meta: indexAWfCQAldNKMeta || {},
    alias: indexAWfCQAldNKMeta?.alias || [],
    redirect: indexAWfCQAldNKMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/bulk/index.vue").then(m => m.default || m)
  },
  {
    name: receiptL8jmRZake6Meta?.name ?? "bulk-receipt",
    path: receiptL8jmRZake6Meta?.path ?? "/bulk/receipt",
    meta: receiptL8jmRZake6Meta || {},
    alias: receiptL8jmRZake6Meta?.alias || [],
    redirect: receiptL8jmRZake6Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/bulk/receipt.vue").then(m => m.default || m)
  },
  {
    name: colabagHPBqrKvNMeta?.name ?? "colab",
    path: colabagHPBqrKvNMeta?.path ?? "/colab",
    meta: colabagHPBqrKvNMeta || {},
    alias: colabagHPBqrKvNMeta?.alias || [],
    redirect: colabagHPBqrKvNMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/colab.vue").then(m => m.default || m)
  },
  {
    name: broadcastPdAwlBNiThMeta?.name ?? "customer-broadcast",
    path: broadcastPdAwlBNiThMeta?.path ?? "/customer/broadcast",
    meta: broadcastPdAwlBNiThMeta || {},
    alias: broadcastPdAwlBNiThMeta?.alias || [],
    redirect: broadcastPdAwlBNiThMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/customer/broadcast.vue").then(m => m.default || m)
  },
  {
    name: example_45widget7cRmfTLORaMeta?.name ?? "dashboard-example-widget",
    path: example_45widget7cRmfTLORaMeta?.path ?? "/dashboard/example-widget",
    meta: example_45widget7cRmfTLORaMeta || {},
    alias: example_45widget7cRmfTLORaMeta?.alias || [],
    redirect: example_45widget7cRmfTLORaMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/dashboard/example-widget.vue").then(m => m.default || m)
  },
  {
    name: indexMOZwTynogIMeta?.name ?? "dashboard",
    path: indexMOZwTynogIMeta?.path ?? "/dashboard",
    meta: indexMOZwTynogIMeta || {},
    alias: indexMOZwTynogIMeta?.alias || [],
    redirect: indexMOZwTynogIMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: deckHelperPxySJNHiA7Meta?.name ?? "deckHelper",
    path: deckHelperPxySJNHiA7Meta?.path ?? "/deckHelper",
    meta: deckHelperPxySJNHiA7Meta || {},
    alias: deckHelperPxySJNHiA7Meta?.alias || [],
    redirect: deckHelperPxySJNHiA7Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/deckHelper.vue").then(m => m.default || m)
  },
  {
    name: errorlKWL027ML7Meta?.name ?? "error",
    path: errorlKWL027ML7Meta?.path ?? "/error",
    meta: errorlKWL027ML7Meta || {},
    alias: errorlKWL027ML7Meta?.alias || [],
    redirect: errorlKWL027ML7Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/error.vue").then(m => m.default || m)
  },
  {
    name: html5_45adsIHr5buVRwnMeta?.name ?? "html5-ads",
    path: html5_45adsIHr5buVRwnMeta?.path ?? "/html5-ads",
    meta: html5_45adsIHr5buVRwnMeta || {},
    alias: html5_45adsIHr5buVRwnMeta?.alias || [],
    redirect: html5_45adsIHr5buVRwnMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/html5-ads.vue").then(m => m.default || m)
  },
  {
    name: indexlS2LJyRQxAMeta?.name ?? "index",
    path: indexlS2LJyRQxAMeta?.path ?? "/",
    meta: indexlS2LJyRQxAMeta || {},
    alias: indexlS2LJyRQxAMeta?.alias || [],
    redirect: indexlS2LJyRQxAMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: account_45performanceLmKbaYrtPCMeta?.name ?? "insights-account-performance",
    path: account_45performanceLmKbaYrtPCMeta?.path ?? "/insights/account-performance",
    meta: account_45performanceLmKbaYrtPCMeta || {},
    alias: account_45performanceLmKbaYrtPCMeta?.alias || [],
    redirect: account_45performanceLmKbaYrtPCMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/account-performance.vue").then(m => m.default || m)
  },
  {
    name: analyst_45scorecardkaqW9BMwO8Meta?.name ?? "insights-analyst-scorecard",
    path: analyst_45scorecardkaqW9BMwO8Meta?.path ?? "/insights/analyst-scorecard",
    meta: analyst_45scorecardkaqW9BMwO8Meta || {},
    alias: analyst_45scorecardkaqW9BMwO8Meta?.alias || [],
    redirect: analyst_45scorecardkaqW9BMwO8Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/analyst-scorecard.vue").then(m => m.default || m)
  },
  {
    name: budget_45adjustmentsv5u9KvKUlWMeta?.name ?? "insights-budget-adjustments",
    path: budget_45adjustmentsv5u9KvKUlWMeta?.path ?? "/insights/budget-adjustments",
    meta: budget_45adjustmentsv5u9KvKUlWMeta || {},
    alias: budget_45adjustmentsv5u9KvKUlWMeta?.alias || [],
    redirect: budget_45adjustmentsv5u9KvKUlWMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/budget-adjustments.vue").then(m => m.default || m)
  },
  {
    name: budget_45groups1kuf2RtmjwMeta?.name ?? "insights-budget-groups",
    path: budget_45groups1kuf2RtmjwMeta?.path ?? "/insights/budget-groups",
    meta: budget_45groups1kuf2RtmjwMeta || {},
    alias: budget_45groups1kuf2RtmjwMeta?.alias || [],
    redirect: budget_45groups1kuf2RtmjwMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/budget-groups.vue").then(m => m.default || m)
  },
  {
    name: change_45historyiYjQBbOrafMeta?.name ?? "insights-change-history",
    path: change_45historyiYjQBbOrafMeta?.path ?? "/insights/change-history",
    meta: change_45historyiYjQBbOrafMeta || {},
    alias: change_45historyiYjQBbOrafMeta?.alias || [],
    redirect: change_45historyiYjQBbOrafMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/change-history.vue").then(m => m.default || m)
  },
  {
    name: crawlerVKwuINyapVMeta?.name ?? "insights-crawler",
    path: crawlerVKwuINyapVMeta?.path ?? "/insights/crawler",
    meta: crawlerVKwuINyapVMeta || {},
    alias: crawlerVKwuINyapVMeta?.alias || [],
    redirect: crawlerVKwuINyapVMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/crawler.vue").then(m => m.default || m)
  },
  {
    name: feedback0uh7dxxxDMMeta?.name ?? "insights-feedback",
    path: feedback0uh7dxxxDMMeta?.path ?? "/insights/feedback",
    meta: feedback0uh7dxxxDMMeta || {},
    alias: feedback0uh7dxxxDMMeta?.alias || [],
    redirect: feedback0uh7dxxxDMMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/feedback.vue").then(m => m.default || m)
  },
  {
    name: keyword_45recommendationscB6kjgbOGjMeta?.name ?? "insights-keyword-recommendations",
    path: keyword_45recommendationscB6kjgbOGjMeta?.path ?? "/insights/keyword-recommendations",
    meta: keyword_45recommendationscB6kjgbOGjMeta || {},
    alias: keyword_45recommendationscB6kjgbOGjMeta?.alias || [],
    redirect: keyword_45recommendationscB6kjgbOGjMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/keyword-recommendations.vue").then(m => m.default || m)
  },
  {
    name: logsW52n5CxaetMeta?.name ?? "insights-logs",
    path: logsW52n5CxaetMeta?.path ?? "/insights/logs",
    meta: logsW52n5CxaetMeta || {},
    alias: logsW52n5CxaetMeta?.alias || [],
    redirect: logsW52n5CxaetMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/logs.vue").then(m => m.default || m)
  },
  {
    name: partner_45sync_45statusrkL2Jrz4M0Meta?.name ?? "insights-partner-sync-status",
    path: partner_45sync_45statusrkL2Jrz4M0Meta?.path ?? "/insights/partner-sync-status",
    meta: partner_45sync_45statusrkL2Jrz4M0Meta || {},
    alias: partner_45sync_45statusrkL2Jrz4M0Meta?.alias || [],
    redirect: partner_45sync_45statusrkL2Jrz4M0Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/partner-sync-status.vue").then(m => m.default || m)
  },
  {
    name: peer_45insights4T0mcYt0PoMeta?.name ?? "insights-peer-insights",
    path: peer_45insights4T0mcYt0PoMeta?.path ?? "/insights/peer-insights",
    meta: peer_45insights4T0mcYt0PoMeta || {},
    alias: peer_45insights4T0mcYt0PoMeta?.alias || [],
    redirect: peer_45insights4T0mcYt0PoMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/peer-insights.vue").then(m => m.default || m)
  },
  {
    name: performance_45reviewzbo89x9DhIMeta?.name ?? "insights-performance-review",
    path: performance_45reviewzbo89x9DhIMeta?.path ?? "/insights/performance-review",
    meta: performance_45reviewzbo89x9DhIMeta || {},
    alias: performance_45reviewzbo89x9DhIMeta?.alias || [],
    redirect: performance_45reviewzbo89x9DhIMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/performance-review.vue").then(m => m.default || m)
  },
  {
    name: reverse_45syncowU5WZHbY7Meta?.name ?? "insights-reverse-sync",
    path: reverse_45syncowU5WZHbY7Meta?.path ?? "/insights/reverse-sync",
    meta: reverse_45syncowU5WZHbY7Meta || {},
    alias: reverse_45syncowU5WZHbY7Meta?.alias || [],
    redirect: reverse_45syncowU5WZHbY7Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/insights/reverse-sync.vue").then(m => m.default || m)
  },
  {
    name: adRenderersEIOIonJ0RMeta?.name ?? "internal-adRenderer",
    path: adRenderersEIOIonJ0RMeta?.path ?? "/internal/adRenderer",
    meta: adRenderersEIOIonJ0RMeta || {},
    alias: adRenderersEIOIonJ0RMeta?.alias || [],
    redirect: adRenderersEIOIonJ0RMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/adRenderer.vue").then(m => m.default || m)
  },
  {
    name: ai_45helpaEuGXZNFM7Meta?.name ?? "internal-ai-help",
    path: ai_45helpaEuGXZNFM7Meta?.path ?? "/internal/ai-help",
    meta: ai_45helpaEuGXZNFM7Meta || {},
    alias: ai_45helpaEuGXZNFM7Meta?.alias || [],
    redirect: ai_45helpaEuGXZNFM7Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/ai-help.vue").then(m => m.default || m)
  },
  {
    name: api_45testsmCmUSJZUFMeta?.name ?? "internal-api-test",
    path: api_45testsmCmUSJZUFMeta?.path ?? "/internal/api-test",
    meta: api_45testsmCmUSJZUFMeta || {},
    alias: api_45testsmCmUSJZUFMeta?.alias || [],
    redirect: api_45testsmCmUSJZUFMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/api-test.vue").then(m => m.default || m)
  },
  {
    name: blueprint_45demorP0AZFzG2pMeta?.name ?? "internal-blueprint-demo",
    path: blueprint_45demorP0AZFzG2pMeta?.path ?? "/internal/blueprint-demo",
    meta: blueprint_45demorP0AZFzG2pMeta || {},
    alias: blueprint_45demorP0AZFzG2pMeta?.alias || [],
    redirect: blueprint_45demorP0AZFzG2pMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/blueprint-demo.vue").then(m => m.default || m)
  },
  {
    name: blueprint_45tag_45languageQFFYuerPykMeta?.name ?? "internal-blueprint-tag-language",
    path: blueprint_45tag_45languageQFFYuerPykMeta?.path ?? "/internal/blueprint-tag-language",
    meta: blueprint_45tag_45languageQFFYuerPykMeta || {},
    alias: blueprint_45tag_45languageQFFYuerPykMeta?.alias || [],
    redirect: blueprint_45tag_45languageQFFYuerPykMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/blueprint-tag-language.vue").then(m => m.default || m)
  },
  {
    name: chartRenderIG1XPTSmYZMeta?.name ?? "internal-chartRender",
    path: chartRenderIG1XPTSmYZMeta?.path ?? "/internal/chartRender",
    meta: chartRenderIG1XPTSmYZMeta || {},
    alias: chartRenderIG1XPTSmYZMeta?.alias || [],
    redirect: chartRenderIG1XPTSmYZMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/chartRender.vue").then(m => m.default || m)
  },
  {
    name: colab_45managerrlPDz4GX8vMeta?.name ?? "internal-colab-manager",
    path: colab_45managerrlPDz4GX8vMeta?.path ?? "/internal/colab-manager",
    meta: colab_45managerrlPDz4GX8vMeta || {},
    alias: colab_45managerrlPDz4GX8vMeta?.alias || [],
    redirect: colab_45managerrlPDz4GX8vMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/colab-manager.vue").then(m => m.default || m)
  },
  {
    name: colab_45testm6KztBUkPJMeta?.name ?? "internal-colab-test",
    path: colab_45testm6KztBUkPJMeta?.path ?? "/internal/colab-test",
    meta: colab_45testm6KztBUkPJMeta || {},
    alias: colab_45testm6KztBUkPJMeta?.alias || [],
    redirect: colab_45testm6KztBUkPJMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/colab-test.vue").then(m => m.default || m)
  },
  {
    name: indexgdJaWN1qAoMeta?.name ?? "internal-customers",
    path: indexgdJaWN1qAoMeta?.path ?? "/internal/customers",
    meta: indexgdJaWN1qAoMeta || {},
    alias: indexgdJaWN1qAoMeta?.alias || [],
    redirect: indexgdJaWN1qAoMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/customers/index.vue").then(m => m.default || m)
  },
  {
    name: drag_45n_45dropjjKEDTtTjPMeta?.name ?? "internal-drag-n-drop",
    path: drag_45n_45dropjjKEDTtTjPMeta?.path ?? "/internal/drag-n-drop",
    meta: drag_45n_45dropjjKEDTtTjPMeta || {},
    alias: drag_45n_45dropjjKEDTtTjPMeta?.alias || [],
    redirect: drag_45n_45dropjjKEDTtTjPMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/drag-n-drop.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45form533Rn7YE6EMeta?.name ?? "internal-dynamic-form",
    path: dynamic_45form533Rn7YE6EMeta?.path ?? "/internal/dynamic-form",
    meta: dynamic_45form533Rn7YE6EMeta || {},
    alias: dynamic_45form533Rn7YE6EMeta?.alias || [],
    redirect: dynamic_45form533Rn7YE6EMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/dynamic-form.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45reportppYSncCZ5PMeta?.name ?? "internal-dynamic-report",
    path: dynamic_45reportppYSncCZ5PMeta?.path ?? "/internal/dynamic-report",
    meta: dynamic_45reportppYSncCZ5PMeta || {},
    alias: dynamic_45reportppYSncCZ5PMeta?.alias || [],
    redirect: dynamic_45reportppYSncCZ5PMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/dynamic-report.vue").then(m => m.default || m)
  },
  {
    name: engineering_45auditEaVOpdzCjAMeta?.name ?? "internal-engineering-audit",
    path: engineering_45auditEaVOpdzCjAMeta?.path ?? "/internal/engineering-audit",
    meta: engineering_45auditEaVOpdzCjAMeta || {},
    alias: engineering_45auditEaVOpdzCjAMeta?.alias || [],
    redirect: engineering_45auditEaVOpdzCjAMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/engineering-audit.vue").then(m => m.default || m),
    children: [
  {
    name: engineeringAuditCustomerBreakdownqKSJCkHuPXMeta?.name ?? "internal-engineering-audit-engineeringAuditCustomerBreakdown",
    path: engineeringAuditCustomerBreakdownqKSJCkHuPXMeta?.path ?? "engineeringAuditCustomerBreakdown",
    meta: engineeringAuditCustomerBreakdownqKSJCkHuPXMeta || {},
    alias: engineeringAuditCustomerBreakdownqKSJCkHuPXMeta?.alias || [],
    redirect: engineeringAuditCustomerBreakdownqKSJCkHuPXMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/engineering-audit/engineeringAuditCustomerBreakdown.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexvBpPTRAaQkMeta?.name ?? "internal",
    path: indexvBpPTRAaQkMeta?.path ?? "/internal",
    meta: indexvBpPTRAaQkMeta || {},
    alias: indexvBpPTRAaQkMeta?.alias || [],
    redirect: indexvBpPTRAaQkMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/index.vue").then(m => m.default || m)
  },
  {
    name: interact_45but_45goodX1Yzq9im7ZMeta?.name ?? "internal-interact-but-good",
    path: interact_45but_45goodX1Yzq9im7ZMeta?.path ?? "/internal/interact-but-good",
    meta: interact_45but_45goodX1Yzq9im7ZMeta || {},
    alias: interact_45but_45goodX1Yzq9im7ZMeta?.alias || [],
    redirect: interact_45but_45goodX1Yzq9im7ZMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/interact-but-good.vue").then(m => m.default || m)
  },
  {
    name: layout_45builderX28pb34QjZMeta?.name ?? "internal-layout-builder",
    path: layout_45builderX28pb34QjZMeta?.path ?? "/internal/layout-builder",
    meta: layout_45builderX28pb34QjZMeta || {},
    alias: layout_45builderX28pb34QjZMeta?.alias || [],
    redirect: layout_45builderX28pb34QjZMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/layout-builder.vue").then(m => m.default || m)
  },
  {
    name: logocUFvJKiss6Meta?.name ?? "internal-logo",
    path: logocUFvJKiss6Meta?.path ?? "/internal/logo",
    meta: logocUFvJKiss6Meta || {},
    alias: logocUFvJKiss6Meta?.alias || [],
    redirect: logocUFvJKiss6Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/logo.vue").then(m => m.default || m)
  },
  {
    name: muse_45chatvDZBwn8XNSMeta?.name ?? "internal-muse-chat",
    path: muse_45chatvDZBwn8XNSMeta?.path ?? "/internal/muse-chat",
    meta: muse_45chatvDZBwn8XNSMeta || {},
    alias: muse_45chatvDZBwn8XNSMeta?.alias || [],
    redirect: muse_45chatvDZBwn8XNSMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/muse-chat.vue").then(m => m.default || m)
  },
  {
    name: prime_45vueqwbzxQs3SAMeta?.name ?? "internal-prime-vue",
    path: prime_45vueqwbzxQs3SAMeta?.path ?? "/internal/prime-vue",
    meta: prime_45vueqwbzxQs3SAMeta || {},
    alias: prime_45vueqwbzxQs3SAMeta?.alias || [],
    redirect: prime_45vueqwbzxQs3SAMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/prime-vue.vue").then(m => m.default || m)
  },
  {
    name: sandbox4HKqbVtLW4Meta?.name ?? "internal-sandbox",
    path: sandbox4HKqbVtLW4Meta?.path ?? "/internal/sandbox",
    meta: sandbox4HKqbVtLW4Meta || {},
    alias: sandbox4HKqbVtLW4Meta?.alias || [],
    redirect: sandbox4HKqbVtLW4Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/sandbox.vue").then(m => m.default || m)
  },
  {
    name: smoke_45testhdH5woHQnaMeta?.name ?? "internal-smoke-test",
    path: smoke_45testhdH5woHQnaMeta?.path ?? "/internal/smoke-test",
    meta: smoke_45testhdH5woHQnaMeta || {},
    alias: smoke_45testhdH5woHQnaMeta?.alias || [],
    redirect: smoke_45testhdH5woHQnaMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/smoke-test.vue").then(m => m.default || m)
  },
  {
    name: style_45guidexN6cPP8hy6Meta?.name ?? "internal-style-guide",
    path: style_45guidexN6cPP8hy6Meta?.path ?? "/internal/style-guide",
    meta: style_45guidexN6cPP8hy6Meta || {},
    alias: style_45guidexN6cPP8hy6Meta?.alias || [],
    redirect: style_45guidexN6cPP8hy6Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/style-guide.vue").then(m => m.default || m)
  },
  {
    name: vue3LUaVa4wVxVMeta?.name ?? "internal-vue3",
    path: vue3LUaVa4wVxVMeta?.path ?? "/internal/vue3",
    meta: vue3LUaVa4wVxVMeta || {},
    alias: vue3LUaVa4wVxVMeta?.alias || [],
    redirect: vue3LUaVa4wVxVMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/internal/vue3.vue").then(m => m.default || m)
  },
  {
    name: launchiijIny8BV3Meta?.name ?? undefined,
    path: launchiijIny8BV3Meta?.path ?? "/launch",
    meta: launchiijIny8BV3Meta || {},
    alias: launchiijIny8BV3Meta?.alias || [],
    redirect: launchiijIny8BV3Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/launch.vue").then(m => m.default || m),
    children: [
  {
    name: indextCm24YlpW7Meta?.name ?? "launch",
    path: indextCm24YlpW7Meta?.path ?? "",
    meta: indextCm24YlpW7Meta || {},
    alias: indextCm24YlpW7Meta?.alias || [],
    redirect: indextCm24YlpW7Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/launch/index.vue").then(m => m.default || m)
  },
  {
    name: indexegwPzweM1mMeta?.name ?? "launch-status",
    path: indexegwPzweM1mMeta?.path ?? "status",
    meta: indexegwPzweM1mMeta || {},
    alias: indexegwPzweM1mMeta?.alias || [],
    redirect: indexegwPzweM1mMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/launch/status/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexy6jX0RVtRwMeta?.name ?? "login",
    path: indexy6jX0RVtRwMeta?.path ?? "/login",
    meta: indexy6jX0RVtRwMeta || {},
    alias: indexy6jX0RVtRwMeta?.alias || [],
    redirect: indexy6jX0RVtRwMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: manage9vYLryulmmMeta?.name ?? undefined,
    path: manage9vYLryulmmMeta?.path ?? "/manage",
    meta: manage9vYLryulmmMeta || {},
    alias: manage9vYLryulmmMeta?.alias || [],
    redirect: manage9vYLryulmmMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/manage.vue").then(m => m.default || m),
    children: [
  {
    name: _91manageType_939yUeVKq3qhMeta?.name ?? "manage-manageType",
    path: _91manageType_939yUeVKq3qhMeta?.path ?? ":manageType()",
    meta: _91manageType_939yUeVKq3qhMeta || {},
    alias: _91manageType_939yUeVKq3qhMeta?.alias || [],
    redirect: _91manageType_939yUeVKq3qhMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/manage/[manageType].vue").then(m => m.default || m)
  },
  {
    name: indexBtu6XiU9TZMeta?.name ?? "manage-create",
    path: indexBtu6XiU9TZMeta?.path ?? "create",
    meta: indexBtu6XiU9TZMeta || {},
    alias: indexBtu6XiU9TZMeta?.alias || [],
    redirect: indexBtu6XiU9TZMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/manage/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexDkC39XTdZgMeta?.name ?? "manage",
    path: indexDkC39XTdZgMeta?.path ?? "",
    meta: indexDkC39XTdZgMeta || {},
    alias: indexDkC39XTdZgMeta?.alias || [],
    redirect: indexDkC39XTdZgMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/manage/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: office_45mateEpizr8wAPiMeta?.name ?? "office-mate",
    path: office_45mateEpizr8wAPiMeta?.path ?? "/office-mate",
    meta: office_45mateEpizr8wAPiMeta || {},
    alias: office_45mateEpizr8wAPiMeta?.alias || [],
    redirect: office_45mateEpizr8wAPiMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/office-mate.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45foundLXWxdyP54vMeta?.name ?? "page-not-found",
    path: page_45not_45foundLXWxdyP54vMeta?.path ?? "/page-not-found",
    meta: page_45not_45foundLXWxdyP54vMeta || {},
    alias: page_45not_45foundLXWxdyP54vMeta?.alias || [],
    redirect: page_45not_45foundLXWxdyP54vMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: indexez38VUvewwMeta?.name ?? "prospecting",
    path: indexez38VUvewwMeta?.path ?? "/prospecting",
    meta: indexez38VUvewwMeta || {},
    alias: indexez38VUvewwMeta?.alias || [],
    redirect: indexez38VUvewwMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/prospecting/index.vue").then(m => m.default || m)
  },
  {
    name: billingHel0hMl11xMeta?.name ?? "reporting-billing",
    path: billingHel0hMl11xMeta?.path ?? "/reporting/billing",
    meta: billingHel0hMl11xMeta || {},
    alias: billingHel0hMl11xMeta?.alias || [],
    redirect: billingHel0hMl11xMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/billing.vue").then(m => m.default || m)
  },
  {
    name: data_45explorerklcL7p7gpMMeta?.name ?? "reporting-data-explorer",
    path: data_45explorerklcL7p7gpMMeta?.path ?? "/reporting/data-explorer",
    meta: data_45explorerklcL7p7gpMMeta || {},
    alias: data_45explorerklcL7p7gpMMeta?.alias || [],
    redirect: data_45explorerklcL7p7gpMMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/data-explorer.vue").then(m => m.default || m)
  },
  {
    name: historyAuef5UzVHcMeta?.name ?? "reporting-history",
    path: historyAuef5UzVHcMeta?.path ?? "/reporting/history",
    meta: historyAuef5UzVHcMeta || {},
    alias: historyAuef5UzVHcMeta?.alias || [],
    redirect: historyAuef5UzVHcMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/history.vue").then(m => m.default || m)
  },
  {
    name: indexMfX9BNAXExMeta?.name ?? "reporting",
    path: indexMfX9BNAXExMeta?.path ?? "/reporting",
    meta: indexMfX9BNAXExMeta || {},
    alias: indexMfX9BNAXExMeta?.alias || [],
    redirect: indexMfX9BNAXExMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/index.vue").then(m => m.default || m)
  },
  {
    name: previewdgPXBf3Zr4Meta?.name ?? "reporting-preview",
    path: previewdgPXBf3Zr4Meta?.path ?? "/reporting/preview",
    meta: previewdgPXBf3Zr4Meta || {},
    alias: previewdgPXBf3Zr4Meta?.alias || [],
    redirect: previewdgPXBf3Zr4Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/reporting/preview.vue").then(m => m.default || m)
  },
  {
    name: account_45ingestR271dMgOL7Meta?.name ?? "settings-account-ingest",
    path: account_45ingestR271dMgOL7Meta?.path ?? "/settings/account-ingest",
    meta: account_45ingestR271dMgOL7Meta || {},
    alias: account_45ingestR271dMgOL7Meta?.alias || [],
    redirect: account_45ingestR271dMgOL7Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/settings/account-ingest.vue").then(m => m.default || m)
  },
  {
    name: crawlSiteDetectorTYOvcXSfQWMeta?.name ?? "settings-crawlSiteDetector",
    path: crawlSiteDetectorTYOvcXSfQWMeta?.path ?? "/settings/crawlSiteDetector",
    meta: crawlSiteDetectorTYOvcXSfQWMeta || {},
    alias: crawlSiteDetectorTYOvcXSfQWMeta?.alias || [],
    redirect: crawlSiteDetectorTYOvcXSfQWMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/settings/crawlSiteDetector.vue").then(m => m.default || m)
  },
  {
    name: customerTafpy86RLTMeta?.name ?? "settings-customer",
    path: customerTafpy86RLTMeta?.path ?? "/settings/customer",
    meta: customerTafpy86RLTMeta || {},
    alias: customerTafpy86RLTMeta?.alias || [],
    redirect: customerTafpy86RLTMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/settings/customer.vue").then(m => m.default || m)
  },
  {
    name: customerPackageSelectoreDTcodQ5b3Meta?.name ?? "settings-customerPackageSelector",
    path: customerPackageSelectoreDTcodQ5b3Meta?.path ?? "/settings/customerPackageSelector",
    meta: customerPackageSelectoreDTcodQ5b3Meta || {},
    alias: customerPackageSelectoreDTcodQ5b3Meta?.alias || [],
    redirect: customerPackageSelectoreDTcodQ5b3Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/settings/customerPackageSelector.vue").then(m => m.default || m)
  },
  {
    name: data48m7P2KXqxMeta?.name ?? "settings-data",
    path: data48m7P2KXqxMeta?.path ?? "/settings/data",
    meta: data48m7P2KXqxMeta || {},
    alias: data48m7P2KXqxMeta?.alias || [],
    redirect: data48m7P2KXqxMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/settings/data.vue").then(m => m.default || m)
  },
  {
    name: fluency9urBfDfFeOMeta?.name ?? "settings-fluency",
    path: fluency9urBfDfFeOMeta?.path ?? "/settings/fluency",
    meta: fluency9urBfDfFeOMeta || {},
    alias: fluency9urBfDfFeOMeta?.alias || [],
    redirect: fluency9urBfDfFeOMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/settings/fluency.vue").then(m => m.default || m)
  },
  {
    name: shared_45audiences1gonZIRYTFMeta?.name ?? "settings-shared-audiences",
    path: shared_45audiences1gonZIRYTFMeta?.path ?? "/settings/shared-audiences",
    meta: shared_45audiences1gonZIRYTFMeta || {},
    alias: shared_45audiences1gonZIRYTFMeta?.alias || [],
    redirect: shared_45audiences1gonZIRYTFMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/settings/shared-audiences.vue").then(m => m.default || m)
  },
  {
    name: target_45setsiRDZqJSW08Meta?.name ?? "settings-target-sets",
    path: target_45setsiRDZqJSW08Meta?.path ?? "/settings/target-sets",
    meta: target_45setsiRDZqJSW08Meta || {},
    alias: target_45setsiRDZqJSW08Meta?.alias || [],
    redirect: target_45setsiRDZqJSW08Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/settings/target-sets.vue").then(m => m.default || m)
  },
  {
    name: _91partner_9365EyqE3WRXMeta?.name ?? "setup-partner",
    path: _91partner_9365EyqE3WRXMeta?.path ?? "/setup/:partner()",
    meta: _91partner_9365EyqE3WRXMeta || {},
    alias: _91partner_9365EyqE3WRXMeta?.alias || [],
    redirect: _91partner_9365EyqE3WRXMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/setup/[partner].vue").then(m => m.default || m)
  },
  {
    name: indexOTPTcd4PyyMeta?.name ?? "setup",
    path: indexOTPTcd4PyyMeta?.path ?? "/setup",
    meta: indexOTPTcd4PyyMeta || {},
    alias: indexOTPTcd4PyyMeta?.alias || [],
    redirect: indexOTPTcd4PyyMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: statusj4oA9FnEc5Meta?.name ?? "status",
    path: statusj4oA9FnEc5Meta?.path ?? "/status",
    meta: statusj4oA9FnEc5Meta || {},
    alias: statusj4oA9FnEc5Meta?.alias || [],
    redirect: statusj4oA9FnEc5Meta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/status.vue").then(m => m.default || m)
  },
  {
    name: indexSWpeDAN5DFMeta?.name ?? "style",
    path: indexSWpeDAN5DFMeta?.path ?? "/style",
    meta: indexSWpeDAN5DFMeta || {},
    alias: indexSWpeDAN5DFMeta?.alias || [],
    redirect: indexSWpeDAN5DFMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/style/index.vue").then(m => m.default || m)
  },
  {
    name: welcome7uIJWbzxtYMeta?.name ?? undefined,
    path: welcome7uIJWbzxtYMeta?.path ?? "/welcome",
    meta: welcome7uIJWbzxtYMeta || {},
    alias: welcome7uIJWbzxtYMeta?.alias || [],
    redirect: welcome7uIJWbzxtYMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/welcome.vue").then(m => m.default || m),
    children: [
  {
    name: indexILMqUCCmblMeta?.name ?? "welcome",
    path: indexILMqUCCmblMeta?.path ?? "",
    meta: indexILMqUCCmblMeta || {},
    alias: indexILMqUCCmblMeta?.alias || [],
    redirect: indexILMqUCCmblMeta?.redirect,
    component: () => import("/home/production/git/fluent-ui/apps/backpack/src/pages/welcome/index.vue").then(m => m.default || m)
  }
]
  }
]