<template>
  <div class="column-filter cursor-pointer" :class="[inline ? 'inline' : 'absolute', {'left': left}]">
    <t-button-inline ref="popoverTarget" @click.stop="columnFilterPopover.toggle($event)" @mousedown.prevent>
      <fluency-icon :type="icon" :class="{'text-info': !!filterData, 'text-muted': !filterData}" />
    </t-button-inline>
    <p-popover ref="columnFilterPopover"
               @show="handleShown()">
      <div class="filter-body p-2">
        <span class="mr-3">Sort:</span>
        <b-button-group class="mb-2">
          <b-button size="sm" :variant="currentSort.desc === false ? 'secondary' : 'outline-secondary'" @click="setSort(false)">ascending</b-button>
          <b-button size="sm" :variant="currentSort.desc === true ? 'secondary' : 'outline-secondary'" @click="setSort(true)">descending</b-button>
        </b-button-group>
        <hr>

        <b-form-group v-for="(option, index) of options" :key="tableKey + columnKey + index">
          <template #label>
            <span v-if="option === 'string'"> {{$filters.convertFromCamelCase(label)}} Includes:</span>
            <span v-if="['function', 'object'].includes(typeof option)">Only Show:</span>
          </template>
          <b-form-input v-if="option === 'string'"
                        v-model="stringInputFilter"
                        autofocus
                        @keyup.enter="applyFilter"></b-form-input>
          <b-form-checkbox-group v-if="Array.isArray(option)"
                                 :checked="checkboxFilter"
                                 @input="checkboxFilter = $event"
                                 :options="format(option)"
                                 stacked />
          <b-form-checkbox-group v-if="typeof option === 'function'"
                                 :checked="checkboxFilter"
                                 @input="checkboxFilter = $event"
                                 :options="format(option(columnKey))"
                                 stacked />
          <template v-if="option === 'numeric'">
            <div v-for="(rule, index) in numberFilter" :key="index + 'numberFilter'" class="d-flex mb-2">
              <div>
                <label v-if="index === 0" class="d-block mb-0 ml-2">Rule</label>
                <b-form-select v-model="rule.rule" :options="numberOptions" class="w-auto"></b-form-select>
              </div>
              <div>
                <label v-if="index === 0" class="d-block mb-0 ml-2">Amount</label>
                <b-form-input v-model="rule.value" class="ml-2 w-auto" @keyup.enter="applyFilter"></b-form-input>
              </div>
            </div>
            <t-button-inline @click="numberFilter.push({ filter: 'greaterThan' })">Add Another Rule</t-button-inline>
          </template>

        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button size="sm" @click="columnFilterPopover.hide()">Close</b-button>
          <b-button size="sm"
                    class="ml-2 column-filter-apply-btn"
                    variant="primary"
                    @click="applyFilter">Apply</b-button>
        </div>
      </div>
    </p-popover>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const popoverTarget = ref(null)
const columnFilterPopover = ref(null)
</script>

<script>
import _isFunction from 'lodash.isfunction'
export default {
  name: 'ColumnFilter',
  components: { },
  props: {
    tableKey: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      default: () => {}
    },
    inline: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      stringInputFilter: '',
      checkboxFilter: [],
      numberFilter: [{ rule: 'greaterThan' }],
      options: this.field.filterOptions,
      label: this.field.label,
      columnKey: this.field.key,
      numberOptions: [
        {
          text: 'Equals',
          value: 'equals'
        },
        {
          text: 'Greater Than',
          value: 'greaterThan'
        },
        {
          text: 'Less Than',
          value: 'lessThan'
        }
      ]
    }
  },
  mounted () {
    if (this.$route.query?.columnFilter) {
      const [field, val] = this.$route.query?.columnFilter.split(':')
      if (this.field.key === field) {
        // console.log('route query', this.field, this.tableKey, this.$route.query)
        // console.log('route query', field, val, this.field.key, this.tableKey, this.$route.query)
        const query = Object.assign({}, this.$route.query)
        delete query.columnFilter
        this.$router.replace({ query })
        this.checkboxFilter = [val]
        this.applyFilter()
      }
    }
  },
  computed: {
    filterData () {
      const data = this.$store.getters['table/columnFilters'](this.tableKey)
      if (data && data[this.columnKey]) {
        return data[this.columnKey]
      } else {
        return false
      }
    },
    currentSort () {
      return this.$store.getters['table/currentSort'] || {}
    },
    icon () {
      if (this.tableKey === this.currentSort.table && this.columnKey === this.currentSort.key) {
        return this.currentSort.desc ? 'arrowDown' : 'arrowUp'
      } else {
        return 'filter'
      }
    },
    getFieldFormatter () {
      if (_isFunction(this.field.filterByFormatted)) {
        return this.field.filterByFormatted
      } else if (this.field.filterByFormatted === true) {
        return this.field.formatter
      }
      return false
    }
  },
  methods: {
    applyFilter () {
      // if there are none set, clear the filter and return true
      if (this.stringInputFilter === '' && this.checkboxFilter.length === 0 && !this.numberFilter.some(f => f.value)) {
        this.$store.commit('table/removeColumnFilter', { table: this.tableKey, key: this.columnKey })
        this.$refs.columnFilterPopover.hide()
        return true
      }

      // proceed with setting the filter
      const numberFilter = this.numberFilter.reduce((accumulator, current) => {
        return {
          ...accumulator,
          [current.rule]: current.value
        }
      }, {})
      const options = {
        text: this.stringInputFilter,
        checkboxValues: this.checkboxFilter,
        label: this.label,
        ...this.applyFormat(numberFilter),
        filterByFormatted: this.getFieldFormatter,
        formatter: this.field.formatter
      }
      this.$store.commit('table/columnFilters', {
        options,
        table: this.tableKey,
        key: this.columnKey
      })
      this.$refs.columnFilterPopover.hide()
      this.$res.set.trackUserEvent('COLUMN_FILTER_APPLY', {
        field: this.label,
        table: this.tableKey
      })
    },
    applyFormat (filters) {
      const formatted = {}
      for (const key of Object.keys(filters)) {
        const numb = parseFloat(filters[key])
        if (!isNaN(numb)) {
          formatted[key] = this.field?.summable?.format === 'percentage' ? numb / 100 : numb
        }
      }
      return formatted
    },
    setSort (desc) {
      this.$emit('sort', desc)
      this.$store.commit('table/currentSort', {
        desc,
        table: this.tableKey,
        key: this.columnKey
      })
    },
    format (options) {
      return options.map(opt => {
        if (typeof opt === 'string') {
          let formatted
          const formatter = this.getFieldFormatter
          if (formatter) {
            formatted = formatter(opt)
          } else if (typeof opt[0] === 'string' && opt[0] === opt[0].toUpperCase()) {
            formatted = this.$filters.convertFromCapsUnderscore(opt)
          } else {
            formatted = this.$filters.convertFromCamelCase(opt)
          }
          return { value: opt, text: formatted }
        }
        return opt
      })
    },
    handleShown () {
      this.$res.set.trackUserEvent('COLUMN_FILTER_OPEN', {
        field: this.label,
        table: this.tableKey
      })
    }
  },
  watch: {
    filterData (val) {
      if (val) {
        this.stringInputFilter = val.text
        this.checkboxFilter = val.checkboxValues
        // this.numberFilter = { equals: val.equals, lessThan: val.lessThan, greaterThan: val.greaterThan }
      } else {
        this.stringInputFilter = ''
        this.checkboxFilter = []
        this.numberFilter = [{ filter: 'greaterThan' }]
      }
    }
  }
}
</script>

<style lang="scss">
.column-filter {
  font-size: 87%;
  transform: scaleX(.9);
  &.absolute {
    position:absolute;
    right: 0;
    bottom: 4.6px;
    &.left {
      right: auto;
      left: 0;
    }
  }
  &.inline {
    position: relative;
    transform: translate(-2px, 3px);
    font-size: 78%;
  }
}

.filter-body {
  max-height: calc(100vh - 75px);
  overflow: auto;
}
</style>
