export const Navigation = [
  {
    link: '/alerts/',
    childPath: '/alerts/',
    text: 'Notifications',
    roles: ['user'],
    classes: 'alerts',
    persona: 'persona_notifications_viewRelevantToMyAccounts'
  },
  {
    link: '/dashboard/',
    text: 'Dashboard',
    roles: ['user'],
    classes: 'dashboard',
    shortkey: ['meta', 'ctrl', 'shift', 'd']
  },
  {
    link: '/launch/',
    text: 'Launch',
    roles: ['user'],
    capabilities: ['Blueprints'],
    childPath: '/launch/',
    classes: 'launch',
    shortkey: ['meta', 'ctrl', 'shift', 'l'],
    persona: 'persona_launch_view'
  },
  {
    link: '/manage/',
    text: 'Manage',
    roles: ['user'],
    capabilities: ['Manage'],
    classes: 'manage',
    shortkey: ['meta', 'ctrl', 'shift', 'm'],
    persona: 'persona_manage_basic'
  },
  {
    link: '/blueprints/',
    text: 'Blueprints',
    roles: ['user'],
    capabilities: ['BlueprintEditor'],
    classes: 'blueprints',
    shortkey: ['meta', 'ctrl', 'shift', 'b'],
    persona: 'persona_blueprints_view'
  }
]

export const Reporting = [
  {
    text: 'Reporting',
    icon: 'document',
    sections: [
      [
        {
          link: '/reporting/data-explorer/',
          text: 'Data Explorer',
          roles: ['user'],
          classes: 'dataExplorer'
        },
        {
          link: '/reporting/',
          text: 'Report Designer',
          roles: ['user'],
          persona: 'persona_reports_view',
          classes: 'reporting'
        },
        {
          link: '/reporting/history/',
          text: 'Report History',
          roles: ['user'],
          classes: 'reportHistory'
        }
      ]
    ]
  },
  {
    text: 'Budgets',
    icon: 'budget',
    sections: [
      [
        {
          link: '/insights/budget-groups/',
          text: 'Budget Groups',
          roles: ['user'],
          classes: 'reporting',
          capabilities: ['CampaignGroups']
        }
      ]
    ]
  },
  {
    text: 'Performance',
    icon: 'graph',
    sections: [
      [
        {
          text: 'Keyword Recommendations',
          link: '/insights/keyword-recommendations/',
          roles: ['user'],
          classes: 'keywordRecommendations',
          persona: 'persona_notifications_viewRecommendedActionsToMyAccounts'
        }
      ],
      [
        {
          text: 'Account Performance',
          link: '/insights/account-performance/',
          roles: ['user'],
          classes: 'accountPerformance'
        },
        {
          text: 'Analyst Portfolio Health',
          link: '/insights/analyst-scorecard/',
          roles: ['manager'],
          classes: 'aph'
        },
        {
          text: 'Reverse Sync Logs',
          link: '/insights/reverse-sync/',
          beta: true,
          roles: ['manager', 'admin'],
          classes: 'reverseSyncLogs'
        },
        {
          text: 'Budget Rollover & Adjustments',
          link: '/insights/budget-adjustments/',
          beta: true,
          roles: ['manager'],
          classes: 'budgetRollover'
        }
      ]
    ]
  },
  {
    text: 'Sales',
    icon: 'person',
    sections: [
      [
        {
          text: 'Prospects',
          link: '/prospecting/',
          roles: ['user'],
          beta: true,
          classes: 'prospects',
          persona: 'persona_manage_budgetRecommendations'
        }
      ]
    ]
  }
]

export const Settings = [
  {
    text: 'Settings',
    icon: 'settings',
    sections: [
      [
        {
          text: 'Customer Info',
          link: '/settings/customer/?customerForm=customerInfo',
          roles: ['manager', 'user-manager'],
          persona: 'persona_settings_userAndGlobal',
          classes: 'customerInfo'
        },
        {
          text: 'Accounts',
          link: '/settings/customer/?customerForm=accounts',
          persona: 'persona_settings_userAndGlobal',
          roles: ['user', 'manager', 'user-manager'],
          classes: 'accounts'
        },
        {
          text: 'Users',
          link: '/settings/customer/?customerForm=users',
          persona: 'persona_settings_userAndGlobal',
          roles: ['manager', 'user-manager'],
          classes: 'users'
        }
      ],
      [
        {
          text: 'Catalogs',
          link: '/settings/customer/?customerForm=inventoryCatalogs',
          roles: ['manager', 'user-manager'],
          classes: 'inventoryCatalogs'
        },
        {
          text: 'Lead Forms',
          link: '/settings/customer/?customerForm=leadForms',
          roles: ['manager', 'user-manager'],
          classes: 'leadForms'
        }
      ],
      [
        {
          text: 'Data Sources',
          link: '/settings/customer/?customerForm=dataSources',
          roles: ['manager', 'user-manager'],
          capabilities: ['BlueprintEditor'],
          classes: 'dataSources'
        },
        {
          text: 'Account Ingest',
          link: '/settings/account-ingest/',
          roles: ['user-manager', 'manager', 'admin'],
          classes: 'accountIngest'
        },
        {
          text: 'Shared Audiences',
          link: '/settings/shared-audiences/',
          roles: ['user-manager', 'manager', 'admin'],
          classes: 'sharedAudiences',
          capabilities: ['Manage', 'CustomerSharedAudiences']
        },
        {
          text: 'Target Sets',
          link: '/settings/target-sets/',
          roles: ['manager', 'admin'],
          classes: 'libraries',
          channels: [29, 30]
        },
        {
          text: 'HTML Ad Templates',
          link: '/html5-ads/',
          roles: ['manager', 'admin'],
          classes: 'html-ad-templates',
          channels: [29, 14, 15, 16, 17, 18]
        },
        {
          text: 'Billing',
          link: '/reporting/billing/',
          roles: ['billing'],
          classes: 'billing'
        },
        {
          text: 'DSP Macros',
          link: '/settings/customer/?customerForm=dspMacros',
          roles: ['user', 'manager', 'admin'],
          channels: [29, 30]
        }
        // {
        //   text: 'Identity Providers',
        //   link: '/settings/customer/?customerForm=identityProviders',
        //   roles: ['manager'],
        //   classes: 'identityProviders'
        // }
      ]
    ]
  },
  {
    text: 'Bulk',
    icon: 'bulk',
    sections: [
      [
        {
          text: 'Bulk Manage',
          link: '/bulk/',
          roles: ['user'],
          capabilities: ['BulkOperations'],
          classes: 'bulkManage',
          persona: 'persona_manage_bulkActions'
        },
        {
          text: 'Bulk Receipts',
          link: '/bulk/receipt/',
          roles: ['user'],
          capabilities: ['BulkOperations'],
          classes: 'bulkReceipts',
          persona: 'persona_manage_bulkActions'
        },
        {
          text: 'Recommendation Policies',
          link: '/settings/customer/?customerForm=recommendationPolicy',
          roles: ['manager', 'user-manager'],
          capabilities: ['BulkOperations'],
          classes: 'RecommendationPolicies',
          persona: 'persona_notifications_viewRecommendedActionsToMyAccounts'
        }
      ]
    ]
  },
  {
    text: 'Measurement',
    icon: 'budget',
    sections: [
      [
        {
          text: 'Conv. Actions',
          link: '/settings/customer/?customerForm=conversionActions',
          roles: ['manager', 'user-manager'],
          capabilities: ['Manage'],
          classes: 'convActions'
        },
        {
          text: 'Performance Bounds',
          link: '/settings/customer/?customerForm=performanceBounds',
          roles: ['manager', 'user-manager'],
          classes: 'performanceBounds'
        }
        /*,
        {
          text: 'Sync. Status',
          link: '/settings/customer/?customerForm=synchronizationStatus',
          roles: ['manager', 'user-manager'],
          classes: 'syncStatus'
        }
         */
      ]
    ]
  },
  {
    text: 'Tools',
    icon: 'toolbox',
    sections: [
      [
        {
          text: 'Tag Tester',
          link: '/blueprints/tag-review/',
          roles: ['manager'],
          capabilities: ['BlueprintEditor'],
          classes: 'tagTester',
          persona: 'persona_blueprints_view'
        },
        {
          text: 'Webhooks',
          link: '/settings/customer/?customerForm=webhooks',
          roles: ['manager', 'user-manager'],
          capabilities: ['BlueprintEditor'],
          persona: 'persona_settings_developerLevel',
          classes: 'webhooks'
        },
        {
          text: 'Web Provider Analysis',
          link: '/settings/customer/?customerForm=webProviderAnalysis',
          roles: ['manager', 'user-manager'],
          capabilities: ['BlueprintEditor'],
          classes: 'webProvider'
        },
        {
          text: 'Muse Chat',
          link: '/internal/muse-chat/',
          roles: ['user'],
          capabilities: ['AIChat'],
          classes: 'museChat'
        }
      ],
      [
        {
          text: 'Platform Integrations',
          link: '/setup/link',
          roles: ['manager'],
          classes: 'platformIntegrations'
        },
        {
          text: 'Download SDF',
          link: '#',
          roles: ['user'],
          action: 'download-sdf',
          channels: [31]
        }
      ]
    ]
  }
]

export const PagesNotInNavigation = [
  {
    link: '/settings/customer/',
    roles: ['user', 'manager', 'user-manager']
  }
]

export const getNavigationForUser = (user, channels, opts = {}) => {
  const bionicSwitcher = opts?.bionic
  const internalUser = opts?.internalUser
  const enabledChannels = channels?.filter(ch => ch.enabled).map(ch => ch.advertisingChannelId) || []
  const shouldShow = (link) => {
    if (!user.capabilities) return false
    if (link.channels) {
      let channelMatches = 0
      for (const channel of link.channels) {
        if (enabledChannels.includes(channel)) {
          channelMatches++
        }
      }
      if (channelMatches === 0) {
        return false
      }
    }
    if (link.capabilities) {
      for (const capability of link.capabilities) {
        if (!user.capabilities[capability]) {
          return false
        }
      }
    }
    if (link.roles) {
      let roleMatch = 0
      for (const role of link.roles) {
        if (user.roles?.includes(role)) {
          roleMatch++
        }
      }
      if (roleMatch === 0) {
        return false
      }
    }
    if (link.persona && user.capabilities[link.persona] === 'OFF') {
      return false
    }
    if (link.internalUser && !internalUser) {
      return false
    }
    return true
  }

  let navigation = null
  let reporting = null
  let settings = null

  if (bionicSwitcher) {
    navigation = BionicNavigation.filter(shouldShow)
    reporting = []
    settings = []
  } else {
    navigation = Navigation.filter(shouldShow)

    reporting = Reporting.map(category => ({
      ...category,
      sections: category.sections.map(section => section.filter(shouldShow)).filter(section => section.length > 0)
    })).filter(category => category.sections.length > 0)

    settings = Settings.map(category => ({
      ...category,
      sections: category.sections.map(section => section.filter(shouldShow)).filter(section => section.length > 0)
    })).filter(category => category.sections.length > 0)
  }

  return {
    navigation,
    reporting,
    settings
  }
}

export const BionicNavigation = [
  // *******************************************************************************************************************
  // MANAGE ************************************************************************************************************
  // *******************************************************************************************************************
  {
    link: '/manage/',
    text: 'Accounts',
    roles: ['user'],
    capabilities: ['Manage'],
    shortkey: ['meta', 'ctrl', 'shift', 'm'],
    persona: 'persona_manage_basic',
    qa: 'manage',
    tracking: 'manage',
    placements: [
      { top: 'manage', sub: 'portfolio', primary: true }
    ]
  },
  {
    link: '/bulk/',
    text: 'Bulk Edit',
    roles: ['user'],
    capabilities: ['BulkOperations'],
    persona: 'persona_manage_bulkActions',
    qa: 'bulkEdit',
    tracking: 'bulkEdit',
    placements: [
      { top: 'manage', sub: 'bulkManage' }
    ]
  },
  {
    text: 'Bulk Receipts',
    link: '/bulk/receipt/',
    roles: ['user'],
    capabilities: ['BulkOperations'],
    persona: 'persona_manage_bulkActions',
    qa: 'bulkReceipts',
    tracking: 'bulkReceipts',
    placements: [
      { top: 'manage', sub: 'bulkManage' }
    ]
  },
  {
    text: 'Performance Bounds',
    link: '/settings/customer/?customerForm=performanceBounds',
    roles: ['manager', 'user-manager'],
    qa: 'performanceBounds',
    tracking: 'performanceBounds',
    placements: [
      { top: 'manage', sub: 'tools' }
    ]
  },
  {
    text: 'Target Sets',
    link: '/settings/target-sets/',
    roles: ['manager', 'admin'],
    channels: [29, 30],
    qa: 'targetSets',
    tracking: 'targetSets',
    placements: [
      { top: 'manage', sub: 'tools' }
    ]
  },
  {
    text: 'Conversion Actions',
    link: '/settings/customer/?customerForm=conversionActions',
    roles: ['manager'],
    qa: 'conversionActions',
    tracking: 'conversionActions',
    placements: [
      { top: 'manage', sub: 'tools' }
    ]
  },
  {
    text: 'Budget Rollover',
    link: '/insights/budget-adjustments/',
    beta: true,
    roles: ['manager'],
    qa: 'budgetRollover',
    tracking: 'budgetRollover',
    placements: [
      { top: 'manage', sub: 'tools' }
    ]
  },
  {
    text: 'DSP Macros',
    link: '/settings/customer/?customerForm=dspMacros',
    roles: ['user', 'manager', 'admin'],
    channels: [29, 30],
    qa: 'dspMacros',
    tracking: 'dspMacros',
    placements: [
      { top: 'manage', sub: 'tools' }
    ]
  },
  {
    text: 'Shared Audiences',
    link: '/settings/shared-audiences/',
    roles: ['user-manager', 'manager', 'admin'],
    capabilities: ['Manage', 'CustomerSharedAudiences'],
    qa: 'sharedAudiences',
    tracking: 'sharedAudiences',
    placements: [
      { top: 'manage', sub: 'tools' }
    ]
  },
  // *******************************************************************************************************************
  // AUTOMATE **********************************************************************************************************
  // *******************************************************************************************************************
  {
    link: '/blueprints/',
    text: 'Blueprint Editor',
    roles: ['user'],
    capabilities: ['BlueprintEditor'],
    shortkey: ['meta', 'ctrl', 'shift', 'b'],
    persona: 'persona_blueprints_view',
    qa: 'blueprints',
    tracking: 'blueprints',
    placements: [
      { top: 'automate', sub: 'blueprints', primary: true }
    ]
  },
  {
    text: 'Tag Tester',
    link: '/blueprints/tag-review/',
    roles: ['manager'],
    capabilities: ['BlueprintEditor'],
    persona: 'persona_blueprints_view',
    qa: 'tagTester',
    tracking: 'tagTester',
    placements: [
      { top: 'automate', sub: 'blueprints' }
    ]
  },
  {
    link: '/launch/',
    text: 'Launch',
    roles: ['user'],
    capabilities: ['Blueprints'],
    childPath: '/launch/',
    shortkey: ['meta', 'ctrl', 'shift', 'l'],
    persona: 'persona_launch_view',
    qa: 'launch',
    tracking: 'launch',
    placements: [
      { top: 'automate', sub: 'launch' }
    ]
  },
  {
    link: '/launch/status/',
    text: 'Launch Status',
    roles: ['user'],
    capabilities: ['Blueprints'],
    persona: 'persona_launch_view',
    qa: 'launchStatus',
    tracking: 'launchStatus',
    placements: [
      { top: 'automate', sub: 'launch' }
    ]
  },
  {
    text: 'Account Ingest',
    link: '/settings/account-ingest/',
    roles: ['user-manager', 'manager', 'admin'],
    qa: 'accountIngest',
    tracking: 'accountIngest',
    placements: [
      { top: 'automate', sub: 'blueprintData' }
    ]
  },
  {
    text: 'Data Sources',
    link: '/settings/customer/?customerForm=dataSources',
    roles: ['manager', 'user-manager'],
    capabilities: ['BlueprintEditor'],
    qa: 'dataSources',
    tracking: 'dataSources',
    placements: [
      { top: 'automate', sub: 'blueprintData' }
    ]
  },
  {
    text: 'Inventory Catalogs',
    link: '/settings/customer/?customerForm=inventoryCatalogs',
    roles: ['manager', 'user-manager'],
    qa: 'inventoryCatalogs',
    tracking: 'inventoryCatalogs',
    placements: [
      { top: 'automate', sub: 'blueprintData' }
    ]
  },
  // {
  //   text: 'Form Designer',
  //   link: '/',
  //   qa: 'formDesigner',
  //   tracking: 'formDesigner',
  //   placements: [
  //     { top: 'automate', sub: 'blueprintData' }
  //   ]
  // },
  {
    text: 'HTML Ad Templates',
    link: '/html5-ads/',
    roles: ['manager', 'admin'],
    classes: 'html-ad-templates',
    channels: [29, 14, 15, 16, 17, 18],
    qa: 'htmlAdTemplates',
    tracking: 'htmlAdTemplates',
    placements: [
      { top: 'automate', sub: 'blueprintData' }
    ]
  },
  {
    text: 'Facebook Lead Forms',
    link: '/settings/customer/?customerForm=leadForms',
    roles: ['manager', 'user-manager'],
    qa: 'leadForms',
    tracking: 'leadForms',
    placements: [
      { top: 'automate', sub: 'blueprintData' }
    ]
  },
  {
    text: 'Recommendation Policies',
    link: '/settings/customer/?customerForm=recommendationPolicy',
    roles: ['manager', 'user-manager'],
    capabilities: ['BulkOperations'],
    persona: 'persona_notifications_viewRecommendedActionsToMyAccounts',
    qa: 'recommendationPolicies',
    tracking: 'recommendationPolicies',
    placements: [
      { top: 'automate', sub: 'automationTools' }
    ]
  },
  {
    text: 'Budget Reallocation Strategy',
    link: '/settings/customer/?customerForm=budgetReallocation',
    qa: 'budgetReallocationStrategy',
    tracking: 'budgetReallocationStrategy',
    placements: [
      { top: 'automate', sub: 'automationTools' }
    ]
  },
  {
    link: '/settings/customer/?customerForm=webProviderAnalysis',
    text: 'Web Provider Analysis',
    roles: ['manager', 'user-manager'],
    capabilities: ['BlueprintEditor'],
    qa: 'webProviderAnalysis',
    tracking: 'webProviderAnalysis',
    placements: [
      { top: 'automate', sub: 'automationTools' }
    ]
  },
  {
    link: '/settings/customer/?customerForm=webhooks',
    text: 'Webhooks',
    roles: ['manager', 'user-manager'],
    capabilities: ['BlueprintEditor'],
    persona: 'persona_settings_developerLevel',
    qa: 'webhooks',
    tracking: 'webhooks',
    placements: [
      { top: 'automate', sub: 'automationTools' }
    ]
  },
  // *******************************************************************************************************************
  // INSIGHTS **********************************************************************************************************
  // *******************************************************************************************************************
  {
    link: '/reporting/data-explorer/',
    text: 'Data Explorer',
    roles: ['user'],
    qa: 'dataExplorer',
    tracking: 'dataExplorer',
    placements: [
      { top: 'insights', sub: 'reportingTools', primary: true }
    ]
  },
  {
    link: '/reporting/',
    text: 'Report Designer',
    roles: ['user'],
    persona: 'persona_reports_view',
    qa: 'reporting',
    tracking: 'reporting',
    placements: [
      { top: 'insights', sub: 'reportingTools' }
    ]
  },
  {
    link: '/reporting/history/',
    text: 'Report History',
    roles: ['user'],
    qa: 'reportHistory',
    tracking: 'reportHistory',
    placements: [
      { top: 'insights', sub: 'reportingTools' }
    ]
  },
  {
    link: '/alerts/',
    text: 'Notifications',
    roles: ['user'],
    persona: 'persona_notifications_viewRelevantToMyAccounts',
    qa: 'alerts',
    tracking: 'alerts',
    placements: [
      { top: 'insights', sub: 'monitor' }
    ]
  },
  {
    link: '/alerts/anomalies/',
    text: 'Anomalies',
    roles: ['user'],
    persona: 'persona_notifications_viewRelevantToMyAccounts',
    qa: 'alerts',
    tracking: 'alerts',
    placements: [
      { top: 'insights', sub: 'monitor' }
    ]
  },
  {
    link: '/dashboard/',
    text: 'Dashboard',
    roles: ['user'],
    shortkey: ['meta', 'ctrl', 'shift', 'd'],
    qa: 'dashboard',
    tracking: 'dashboard',
    placements: [
      { top: 'insights', sub: 'monitor' }
    ]
  },
  {
    text: 'Keyword Recommendations',
    link: '/insights/keyword-recommendations/',
    roles: ['user'],
    classes: 'keywordRecommendations',
    persona: 'persona_notifications_viewRecommendedActionsToMyAccounts',
    qa: 'keywordRecommendations',
    tracking: 'keywordRecommendations',
    placements: [
      { top: 'insights', sub: 'monitor' }
    ]
  },
  {
    link: '/insights/analyst-scorecard/',
    text: 'Analyst Portfolio Health',
    roles: ['manager'],
    qa: 'analystPortfolioHealth',
    tracking: 'analystPortfolioHealth',
    placements: [
      { top: 'insights', sub: 'monitor' }
    ]
  },
  {
    link: '/insights/budget-groups/',
    text: 'Budget Groups',
    roles: ['user'],
    qa: 'budgetGroups',
    tracking: 'budgetGroups',
    capabilities: ['CampaignGroups'],
    placements: [
      { top: 'insights', sub: 'monitor' }
    ]
  },
  {
    link: '/prospecting/',
    text: 'Prospects',
    roles: ['user'],
    beta: true,
    persona: 'persona_manage_budgetRecommendations',
    qa: 'prospects',
    tracking: 'prospects',
    placements: [
      { top: 'insights', sub: 'monitor' }
    ]
  },
  // *******************************************************************************************************************
  // SETTINGS **********************************************************************************************************
  // *******************************************************************************************************************
  {
    link: '/settings/customer/?customerForm=customerInfo',
    text: 'Customer Info',
    icon: 'info',
    roles: ['manager', 'user-manager'],
    persona: 'persona_settings_userAndGlobal',
    qa: 'customerInfo',
    tracking: 'customerInfo',
    placements: [
      { top: 'settings', sub: 'globalSettings' }
    ]
  },
  {
    link: '/settings/customer/?customerForm=accounts',
    text: 'Accounts Management',
    icon: 'accountManagement',
    persona: 'persona_settings_userAndGlobal',
    roles: ['user', 'manager', 'user-manager'],
    qa: 'accountManagement',
    tracking: 'accountManagement',
    placements: [
      { top: 'settings', sub: 'globalSettings' }
    ]
  },
  {
    link: '/settings/customer/?customerForm=users',
    text: 'User Management',
    icon: 'userManagement',
    persona: 'persona_settings_userAndGlobal',
    roles: ['user', 'manager', 'user-manager'],
    qa: 'userManagement',
    tracking: 'userManagement',
    placements: [
      { top: 'settings', sub: 'globalSettings' }
    ]
  },
  {
    link: '/setup/link',
    text: 'Platform Integrations',
    icon: 'integrations',
    roles: ['manager'],
    qa: 'platformIntegrations',
    tracking: 'platformIntegrations',
    placements: [
      { top: 'settings', sub: 'globalSettings' }
    ]
  },
  {
    link: '/reporting/billing/',
    text: 'Billing',
    icon: 'budget',
    internalUser: true,
    qa: 'billing',
    tracking: 'billing',
    placements: [
      { top: 'settings', sub: 'internalTools' }
    ]
  },
  {
    link: '/internal/muse-chat/',
    text: 'MuseAI™ Chat',
    icon: 'muse',
    roles: ['user'],
    capabilities: ['AIChat'],
    internalUser: true,
    qa: 'museChat',
    tracking: 'museChat',
    placements: [
      { top: 'settings', sub: 'internalTools' }
    ]
  }
]
