import { unref, ref } from 'vue'

const repositionRight = (navPopoverRef, { top, bottom } = {}) => {
  const navPopoverInstance = unref(navPopoverRef)
  const container = navPopoverInstance.container
  const target = navPopoverInstance.target
  const containerRect = container.getBoundingClientRect()
  // navPopoverInstance.alignOverlay()

  // move the popover to the right by increasing the left value
  // const left = containerRect.left + target.offsetWidth
  container.style.left = '63px'
  if (top) {
    container.style.top = top
    return
  } else if (bottom) {
    container.style.top = 'auto'
    container.style.bottom = bottom
    return
  }

  let newTop = containerRect.top
  if (target.offsetTop + target.offsetHeight < containerRect.top) {
    // bottom pop, adjust up by decreasing the top a little more than the height of the hover activator
    newTop = containerRect.top - (target.offsetHeight * 1.5)
  } else {
    // top pop, adjust down by increasing the top a little more than the height of the hover activator
    newTop = (containerRect.top + (target.offsetHeight * 1.5))
  }

  // top and bottom window boundary
  newTop = Math.max(20, newTop)
  newTop = Math.min(window.innerHeight - (target.offsetHeight * 3) - containerRect.height, newTop)

  container.style.top = newTop + 'px'
}

const DEFAULT_SHOW_DELAY = 300
const DEFAULT_HIDE_DELAY = 300
const showNavWithDelay = ($event, navId, showDelay) => {
  const nav = navPopoverTracker[navId]
  const { popoverRef, targetEl } = nav
  const showTimeoutId = setTimeout(() => {
    hideAllNavs([navId])

    nav.open.value = true
    popoverRef.show($event, targetEl)
  }, showDelay || DEFAULT_SHOW_DELAY)

  targetEl.dataset.tsId = `${showTimeoutId}`

  if (targetEl.dataset.thId) {
    clearTimeout(targetEl.dataset.thId)
    targetEl.dataset.thId = ''
  }
}
const cancelShowIfUnopened = (navId) => {
  const nav = navPopoverTracker[navId]
  const { targetEl } = nav
  // if the nav isn't open, stop any show delay and hide it immediately.
  if (!nav.open.value) {
    if (targetEl.dataset.tsId) {
      clearTimeout(targetEl.dataset.tsId)
      targetEl.dataset.tsId = ''
    }
    hideNavImmediate(navId)
    return true
  }
  return false
}

const hideNavWithDelay = (navId, hideDelay) => {
  const nav = navPopoverTracker[navId]
  const { targetEl } = nav

  // if the nav isn't open, stop any show delay and hide it immediately.
  const didCancel = cancelShowIfUnopened(navId)
  if (didCancel) return

  if (!nav.open.value) {
    if (targetEl.dataset.tsId) {
      clearTimeout(targetEl.dataset.tsId)
      targetEl.dataset.tsId = ''
    }
    hideNavImmediate(navId)
    return
  }
  const hideTimeoutId = setTimeout(() => {
    hideNavImmediate(navId)
  }, hideDelay || DEFAULT_HIDE_DELAY)
  targetEl.dataset.thId = `${hideTimeoutId}`
}
const hideNavImmediate = (navId) => {
  const nav = navPopoverTracker[navId]
  const { popoverRef, targetEl } = nav
  popoverRef.hide()
  nav.open.value = false
  targetEl.dataset.thId = ''
}
const hideAllNavs = (exceptNavIds = []) => {
  Object.values(navPopoverTracker).forEach(nav => {
    if (!exceptNavIds.includes(nav.navId) && nav.open.value === true) {
      hideNavImmediate(nav.navId)
    }
  })
}

const navPopoverTracker = {}
const addNavPopover = ({ targetEl, popoverRef, navId }) => {
  navPopoverTracker[navId] = { targetEl, popoverRef, navId, open: ref(false) }
}

const bionicNavPopoverDesignTokens = {
  gutter: '0px',
  arrowOffset: 'none'
}

export function useBionicNavUtils () {
  return {
    repositionRight,
    addNavPopover,
    showNavWithDelay,
    hideNavWithDelay,
    hideAllNavs,
    cancelShowIfUnopened,
    bionicNavPopoverDesignTokens
  }
}
