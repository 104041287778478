<template>
  <div>
    <b-button v-p-tooltip.top="{ value: 'Message of the Day' }"
              variant="ghost-light"
              @click.stop="showGoodNews(value.accountPlanId)">
      <fluency-icon type="speechBubble"/>
    </b-button>
    <b-modal v-if="showModal" v-model="showModal" size="sm" :title="`${value.name} - Message of the Day`" button-size="sm">

      <template v-if="value.soundBytesMonthOverMonth">
        <h3>Reflecting on Last Month</h3>
        <div class="p-3 my-4 border rounded">
          <div :key="`col-quote-item-${soundByteCount}`" v-for="(soundByte, soundByteCount) in value.soundBytesMonthOverMonth" class="m-4">
            {{soundByte}}
          </div>
        </div>
      </template>

      <template v-if="value.soundBytes">
        <h3>Emerging Trends this Month</h3>
        <div class="p-3 my-4  border rounded">
          <div :key="`col-quote-item-${soundByteCount}`" v-for="(soundByte, soundByteCount) in value.soundBytes" class="m-4">
            {{soundByte}}
          </div>
        </div>
      </template>
      <template #modal-footer="{ cancel, ok }">
        <b-button @click="ok">
          {{ 'OK' }}
        </b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'soundBytes',
  props: ['value'],
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    showGoodNews (ap) {
      this.$res.set.trackUserEvent('show-motd', {accountPlanId: ap})
      this.showModal = true
    }
  }
}
</script>

<style scoped>

</style>
