export default async function useAssets (asset) {
  const assets = import.meta.glob('/assets/img/**/*.{png,svg}')

  const getAssetUrl = async () => {
    if (assets[asset]) {
      return (await assets[asset]()).default
    }
  }

  return await getAssetUrl()
}
