<template>
  <div>
    <b-modal v-model="modalShown"
             size="sm"
             title="Report Details"
             centered
             ok-title="Run Report"
             @ok="emitReport()"
             body-class="overflow-visible" :ok-disabled="okDisabled">
      <div>
        <div :key="`field-${fieldIndex}`" v-for="(field, fieldIndex) in userInputJson">
          <b-form-group :label="`${field.fieldName} ${$filters.convertFromCapsUnderscore(field.dbOperator)}`">

            <template #label>
              {{field.fieldName}}
              <span class="text-muted font-italic">{{labelizeOperator(field.dbOperator)}}</span>
              <info-tooltip v-if="field.fieldType === 'TEXT' && field.allowMultipleValues"
                            title="Did you know: You can enter a comma separated list and we'll split it into separate values."
                            variant="info"></info-tooltip>
            </template>
            <template #default>
              <div class="mad-lib-container">
                <mad-lib-input v-if="['SELECT', 'ENUM'].includes(field.fieldType) && field.allowMultipleValues" :type-ahead-options="enumValues[field.fieldName] || field.selectValues" v-model="field.values"></mad-lib-input>
                <basic-mad-lib-input v-else-if="['SELECT', 'ENUM'].includes(field.fieldType)" :value="field.values[0]" :type-ahead-options="enumValues[field.fieldName] || field.selectValues" @input="field.values = [$event]"></basic-mad-lib-input>
                <single-date-picker v-else-if="field.fieldType === 'DATE'" v-model="field.values" :min-date="null"></single-date-picker>
                <mad-lib-input v-else-if="field.fieldType === 'TEXT' && field.allowMultipleValues" v-model="field.values"/>
                <basic-mad-lib-input v-else-if="field.fieldType === 'TEXT'" :value="field.values[0]" @input="field.values = [$event]"></basic-mad-lib-input>
                <template v-else-if="field.fieldType === 'RANGE'">
                  <basic-mad-lib-input v-model="field.values[0]"/><span class="mx-3"> to </span><basic-mad-lib-input v-model="field.values[1]"/>
                </template>
              </div>
            </template>
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import madLibInput from 'core-ui/components/inputs/madLibInput.vue'
import basicMadLibInput from 'core-ui/components/inputs/basicMadLibInput.vue'
import SingleDatePicker from '~/components/common/dateSelector/components/singleDatePicker.vue'

export default {
  name: 'manageReportDefinitionsDialog',
  components: { SingleDatePicker, madLibInput, basicMadLibInput },
  props: ['activateDialog', 'report'],
  data () {
    return {
      modalShown: false,
      userInputJson: [],
      enumValues: {}
    }
  },
  watch: {
    activateDialog: {
      handler (newValue) {
        if (newValue) {
          this.modalShown = true
          this.$nextTick(() => this.prefillAccount())
        }
      },
      immediate: true
    },
    modalShown (val) {
      if (!val) {
        this.$emit('update:activateDialog', false)
      }
    },
    'report.userInputJson': {
      handler (val) {
        this.$setCompat(this, 'userInputJson', this.mutateUserInputJson(val))
        this.fetchEnums()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    okDisabled () {
      return this.userInputJson.some(field => field.required &&
        (!field.values || field.values.length === 0) &&
        (!field.defaultValue || field.defaultValue.length === 0))
    }
  },
  methods: {
    emitReport (reportUserInputJson) {
      const payload = {
        userInput: []
      }
      this.userInputJson.forEach(field => {
        payload.userInput.push({
          fieldName: field.fieldName,
          values: field.values
        })
      })

      this.$emit('runReport', payload)
    },
    mutateUserInputJson (userInputJson) {
      return userInputJson.map(field => ({
        ...field,
        values: field.fieldType === 'DATE' ? null : []
      }))
    },
    prefillAccount () {
      const accountField = this.userInputJson.find(f => f.dbField === 'account_plan.name')
      if (accountField) {
        if (this.$route.name === 'manage-manageType') {
          this.$setCompat(accountField, 'values', [this.$store.getters.activeItem.name])
        } else if (this.$route.name === 'manage') {
          this.$setCompat(accountField, 'values', this.$store.getters['table/selectedRowObjs']('account').map(a => a.name))
        }
      }
    },
    labelizeOperator (op) {
      switch (op) {
        case 'IN':
          return 'Is Any Of The Following'
        case 'CONTAINS':
          return 'Contains Any Of The Following'
        default:
          return this.$filters.convertFromCapsUnderscore(op)
      }
    },
    async fetchEnums () {
      const enumFields = this.userInputJson.filter(field => field.fieldType === 'ENUM')
      if (enumFields.length > 0) {
        const resp = await Promise.all(enumFields.map(field => this.$res.fetch.generic(field.enumValueLoader)))
        if (resp) {
          const enumValues = resp.reduce((accumulator, current, index) => ({
            ...accumulator,
            [enumFields[index].fieldName]: current.map(v => enumFields[index].enumResponseKey ? v[enumFields[index].enumResponseKey] : v)
          }), {})
          this.$setCompat(this, 'enumValues', enumValues)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.report-definition-modal {
  overflow-y: visible;
  .vs__selected-options {
    .vs__selected {
      background-color: $secondary;
      color: white;
      font-weight: bold;
      padding-right: 0.6em;
      padding-left: 0.6em;
      border-radius: 10rem;
    }
    .vs__deselect {
      background: white;
      border-radius: 50%;
      padding: 1px;
      svg {
        transform: scale(.6);
        fill: $secondary;
      }
    }
  }
}
</style>
