import dateShortcuts from '@/assets/js/dateShortcuts'
import { useAccountsById } from '@/composables/useAccountsById'
import { useActiveChildItems } from '../composables/useActiveChildItems'
import { useActiveBudgets } from '../composables/useActiveBudgets'
import { useCampaignNavigation } from '../composables/useCampaignNavigation'
import { useAdGroupNavigation } from '../composables/useAdGroupNavigation'

export default {
  oktaAccessToken (state) {
    return state.okta.accessToken
  },
  oktaIdToken (state) {
    return state.okta.idToken
  },
  oktaUserId (state) {
    return state.okta.userId
  },
  init (state) {
    return state.app.init
  },
  activeItem (state) {
    return state.data.activeItem
  },
  settingsItem (state) {
    return state.data.settingsItem
  },
  activeChildItems: (state) => (type) => {
    return useActiveChildItems().getActiveChildItems(type)
  },
  isFluencyUser (state) {
    const user = state.user
    const isLive = state.app.environment === 'production'

    if (!isLive) {
      return user?.internalUser
    }

    return false
  },
  actingAsUser (state) {
    return state.data.actingAsUser
  },
  packageVersion (state) {
    return state.data.packageVersion
  },
  isLocalDev (state) {
    return state.data.isLocalDev
  },
  customer (state) {
    return state.app.customer || {}
  },
  customerState (state) {
    return state.app.customerState || {}
  },
  accountsById (state) {
    return useAccountsById().accountsByIdRef.value
  },
  setting (state) {
    return function (key) {
      return state.app.settings[key]
    }
  },
  backpackVersion (state) {
    return state.app.settings.backpackVersion
  },
  toast (state) {
    return state.data.toast
  },
  // state is passed to each getter by default
  getScrolledPastHeader (state) {
    return state.app.scrolledPastHeader
  },
  getHeaderHeight (state) {
    return state.app.headerHeight
  },
  getBreadcrumbHeight (state) {
    return state.app.breadcrumbHeight
  },
  account (state) {
    return state.data.active.account
  },
  activeAccount (state) {
    return state.data.active.account
  },
  activeBudget (state) {
    return state.data.active.budget
  },
  activeCampaign (state) {
    return state.data.active.campaign
  },
  accountListFilter (state) {
    return state.data.accountListFilter
  },
  userMode (state) {
    if (!state.user) return null
    return state.user.userMode
  },
  appUnsavedTools (state) {
    return (state.app.unsavedTools)
  },
  nodeEnv (state) {
    return state.app.nodeEnv
  },
  apiUri (state) {
    return state.data.apiUri
  },
  timeRange (state) {
    if (state.app.settings.timeRangeShortcut) {
      return dateShortcuts[state.app.settings.timeRangeShortcut]?.dateRange || dateShortcuts.thisMonth.dateRange
    } else if (state.app.customTimeRange && state.app.customTimeRange.length > 1) {
      return state.app.customTimeRange
    } else {
      return dateShortcuts.thisMonth.dateRange
    }
  },
  showAthenaWarningForReporting (state) {
    return state.app.settings.showAthenaWarningForReporting
  },
  timeRangeShortcut (state) {
    return state.app.settings.timeRangeShortcut
  },
  timeRangeCompare (state) {
    return state.app.settings.timeRangeCompare
  },
  timeRangeCompareOverride (state) {
    return state.app.settings.timeRangeCompareOverride
  },
  clipboard (state) {
    return state.data.clipboard
  },
  overrides (state) {
    return state.data.overrides
  },
  activeAccountBudgets (state) {
    return useActiveBudgets().getActiveBudgets()
  },
  advertisingChannels (state) {
    return state.data.advertisingChannels
  },
  partners (state) {
    return state.data.partners
  },
  verticals (state) {
    return state.data.verticals
  },
  skeletons (state) {
    return state.data.skeletons
  },
  strategyEditorFields (state) {
    return state.app.config.strategyEditorFields
  },
  seRecipes (state) {
    return state.data.strategyEditor.recipes
  },
  reportConfigurations (state) {
    return state.data.reportConfigurations
  },
  googleConfigReportFields (state) {
    return state.data.googleConfigReportFields
  },
  reportDefinitions (state) {
    return state.data.reportDefinitions
  },
  activeReportDefinition (state) {
    return state.data.active.reportDefinition
  },
  reportDownload (state) {
    return state.data.active.reportDownload
  },
  isReportDownloading (state) {
    return state.data.active.isReportDownloading
  },
  activeAccountNotifications (state) {
    return state.data.active.accountNotifications
  },
  newSkeleton (state) {
    return function (type, defaultValue = '') {
      const skeleton = Object.assign({}, state.data.skeletons[type])

      for (const prop in skeleton) {
        skeleton[prop] = defaultValue
      }
      return skeleton
    }
  },
  templates (state) {
    return state.data.templates
  },
  enumerations (state) {
    return state.data.enumerations
  },
  user (state) {
    return state.user
  },
  userCapabilities (state) {
    return state?.user?.capabilities
  },
  expandedWidget (state) {
    return state.data.expandedWidget
  },
  appForksBionicLayout (state) {
    return true
    // return state.app?.settings?.forks?.nav === 'bionic'
  },
  splitpanesBlueprintEditor (state) {
    return state.app.settings.splitpanes.blueprintEditor
  },
  splitpanesBlueprintEditor2 (state) {
    return state.app.settings.splitpanes.blueprintEditor2
  },
  splitpanesManageDetails (state) {
    // TODO: probably safe to remove this fallback once this feature is live and we're not supporting both versions of Manage
    return state.app.settings?.splitpanes?.manageDetails || { nav: 15, table: 85 }
  },
  splitpanesBulkEdit (state) {
    return state.app.settings.splitpanes.bulkEdit
  },
  splitpanesBulkReceipt (state) {
    return state.app.settings.splitpanes.bulkReceipt
  },
  splitpanesAlertsWorkshop (state) {
    return state.app.settings.splitpanes.alertsWorkshop
  },
  splitpanesUserFeedback (state) {
    return state.app.settings.splitpanes.userFeedback
  },
  seColDT (state) {
    return state.app.settings.seShowDT
  },
  seColCP (state) {
    return state.app.settings.seShowCP
  },
  usersForCustomer (state) {
    return state.data.usersForCustomer
  },
  keywordAnalysisSummary (state) {
    return state.data.keywordAnalysisSummary
  },
  showPlanStatus (state) {
    return state.app.settings.showPlanStatus
  },
  includeReadOnlyAccounts (state) {
    return state.app.settings.includeReadOnlyAccounts
  },
  tableToggleFilters (state) {
    return state.app.settings.tableToggleFilters
  },
  showActiveBlueprintsOnly (state) {
    return state.app.settings.showActiveBlueprintsOnly
  },
  copyBin (state) {
    return state.app.copyBin
  },
  manageStatsPaneSize (state) {
    return state.app.settings.manageStatsPaneSize
  },
  manageNavigationCollapsed (state) {
    return state.app.settings.manageNavigationCollapsed
  },
  broadcasts (state) {
    return state.data.broadcasts
  },
  customerBroadcasts (state) {
    return state.data.customerBroadcasts
  },
  loading (state) {
    return state.data.loading
  },
  accountPrimaryDomainWithProtocol (state) {
    let primaryDomain, secure
    if (state.data.activeItem) { // check for active item first
      primaryDomain = state.data.activeItem.primaryDomain
      secure = state.data.activeItem.secureDomain
    } else if (state.data.active.account) {
      primaryDomain = state.data.active.account.primaryDomain
      secure = state.data.active.account.secureDomain
    }
    return ((secure) ? 'https://' : 'http://') + primaryDomain
  },
  audiencePlans (state) {
    return state.data.audiencePlans
  },
  activeAudiencePlan (state) {
    return state.data.activeAudiencePlan
  },
  launchPlans (state) {
    return state.data.launchPlans
  },
  navigationSearchTree (state) {
    return state.data.navigationSearchTree
  },
  navigationSearchTokens (state) {
    return state.data.navigationSearchTokens
  },
  googleContentLinkStatus (state) {
    return state.data.googleContentLinkStatus
  },
  googleMyBusinessLinkStatus (state) {
    return state.data.googleMyBusinessLinkStatus
  },
  facebookPageLinkStatus (state) {
    return state.data.facebookPageLinkStatus
  },
  facebookPageLinkStatusMap (state) {
    if (state.data.facebookPageLinkStatus) {
      const arr = Object.keys(state.data.facebookPageLinkStatus).flatMap(key =>
        state.data.facebookPageLinkStatus[key].map(s => typeof s === 'number'
          ? { accountPlanId: s, facebookPageLinkStatus: key }
          : {
              ...s,
              facebookPageLinkStatus: key
            }))
      return arr.toMap(i => i.accountPlanId)
    }
    return {}
  },
  facebookAccountList (state) {
    return state.data.facebookAccountList
  },
  whiteLabel (state, getters) {
    let headerLogo = {
      path: 'fluency-logo-header-4',
      height: 35,
      style: ''
    }
    let className = 'white-label-none'
    let loginLogo = {}
    let bionicLogo = {
      path: 'fluency-logo-small.svg'
    }
    let welcome

    const email = getters.user?.email || ''

    /* == DEALER.COM ===============================================================================================  */
    if (email.indexOf('@coxautoinc.com') !== -1 || email.indexOf('+coxautoinc@fluency') !== -1 || getters.actingAsUser === 219516) {
      headerLogo = {
        path: 'clients/dealer-dot-com',
        height: 26,
        style: 'margin-top: 5px;'
      }
      bionicLogo = { path: 'ddc-logo.svg' }
      className = 'white-label-dealer'
      welcome = '/assets/img/clients/dealer-dot-com.png'

      // TODO: setup cox sub domain
      // TODO: add the login logo
    }

    return {
      headerLogo,
      loginLogo,
      bionicLogo,
      className,
      welcome
    }
  },
  accountPartnerTrackingCodes (state) {
    return state.data.accountPartnerTrackingCodes
  },
  blueprints (state) {
    return state.data.blueprints
  },
  activeSegment (state, getters) {
    // if user hasn't set anything via the UI, try to use `defaultSavedSegmentId`
    if (!state.app.settings.activeSegment.hasOwnProperty('savedSegmentId') && getters.user?.defaultSavedSegmentId !== undefined) {
      const savedSegmentToUse = getters.userSavedSegments.filter(seg => seg.savedSegmentId === getters.user.defaultSavedSegmentId)
      if (Array.isArray(savedSegmentToUse) && savedSegmentToUse.length > 0) {
        return savedSegmentToUse[0]
      }
    }

    return state.app.settings.activeSegment
  },
  channelGroups (state) {
    return state.data.channelGroups
  },
  channelGroupsFilter (state) {
    return state.app.settings.channelGroupsFilter
  },
  metricsBarItems (state) {
    return state.app.settings.metricsBarItems
  },
  createSingle (state) {
    return state.app.createSingle
  },
  campaignNavigation (state) {
    return useCampaignNavigation().getCampaignNavigation()
  },
  adGroupNavigation (state) {
    return useAdGroupNavigation().getAdGroupNavigation()
  },
  creativeSpecs (state) {
    return state.data.creativeSpecs
  },
  timeZones (state) {
    return state.data.timeZones
  },
  productScopePlans: (state) => (advertisingChannelId) => {
    return state.data.productScopePlans[advertisingChannelId]
  },
  allProductScopePlans (state) {
    return state.data.productScopePlans
  },
  accountPollingData (state) {
    return state.data.active.accountPollingData
  },
  accountQaStatus (state) {
    return state.data.accountQaStatus
  },
  userSavedSegments (state) {
    return state.data.userSavedSegments
  },
  savedSegmentFields (state) {
    return state.data.savedSegmentFields
  },
  activelyRepublishingBlueprints (state) {
    return state.data.activelyRepublishingBlueprints
  },
  partnerPages (state) {
    return state.data.partnerPages
  },
  trainingVideos (state) {
    return state.app.trainingVideos
  },
  accountVideos (state) {
    return state.app.accountVideos
  },
  lastFetchedTrainingVideos (state) {
    return state.app.lastFetchedTrainingVideos
  },
  accountDataSaveInProgress (state) {
    return state.data.accountDataSaveInProgress
  },
  reportRequestResponse (state) {
    return state.data.reportRequestResponse
  },
  manageDrawerWidth (state) {
    const storedVal = state.app.settings.drawers.manage.width
    const defaultVal = 60

    if (isNaN(storedVal) || storedVal > 90 || storedVal < 5) {
      return defaultVal
    }

    return storedVal
  },
  manageDrawerBootstrapSize (state) {
    return state.app.settings.drawers.manage.bootstrapSize
  },
  blueprintCurrentEditingObject (state) {
    return state.data.blueprintEditing
  },
  customerSettings (state) {
    return state.data.customerSettings
  },
  questionnaireSpecs (state) {
    return state.data.questionnaireSpecs
  },
  adTemplateDataChoices (state) {
    return state.data.adTemplateDataChoices
  },
  blueprintEditorNavFilter (state) {
    return state.app.settings.blueprintEditor2.navFilter
  },
  strictSegments (state) {
    return state.app.settings.strictSegments
  },
  gridViewMedia (state) {
    return state.app.settings.gridViewMedia
  },
  blueprintEditorCurrentDynamicTags (state) {
    return state.data?.blueprintEditor2?.dynamicTags?.all || []
  },
  blueprintEditorCurrentDynamicTagAliases (state) {
    return state.data?.blueprintEditor2?.dynamicTags?.aliases || []
  },
  officeMateIntroDismissed: (state) => (tool) => {
    return state.app.settings.officeMateIntroDismissed[tool]
  },
  activeReverseSyncs (state) {
    return state.app.active.reverseSyncs
  },
  customerSwitcher (state) {
    return state.data.actingAsUser
  },
  peerInsights (state) {
    return state.data.peerInsights
  },
  accountPageInstantExperiences (state) {
    return state.data.accountPageInstantExperiences
  },
  instantExperienceLayouts (state) {
    return state.data.instantExperienceLayouts
  },
  instantExperienceTemplates (state) {
    return state.data.instantExperienceTemplates
  },
  layoutBuilderPath (state) {
    return state.data.layoutBuilderPath
  },
  navigationForUser (state) {
    return state.app.navigationForUser
  },
  blueprintEditorShowAdvancedSettings (state) {
    return !!state.app.settings.blueprintEditorShowAdvancedSettings
  },
  activeSettingsLink (state) {
    return state.app.activeSettingsLink
  }
}
