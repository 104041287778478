<template>
  <drawer :show="drawerOpen"
          right
          drawer-width="690px"
          no-scrim
          card-body-classes="m-0"
          size-tracker
          @update:show="handleOpenClose($event)"
          @visible-size="drawerSize = $event">
    <template #title>
      <div class="flex-grow-1 d-flex-center justify-content-between">
        <h3 class="d-flex-center">
          <fluency-icon type="muse"/>
          <span class="muse-text-gradient right-border pl-3">Muse AI</span>Insights
        </h3>

        <p class="mr-3 mb-0 text-info" style="font-size: 0.75rem">
          {{ $filters.number(balanceAvailable, 0, true) }} / {{ $filters.number(balanceTotal, 0, true) }} Remaining Balance

          <small>
            <info-tooltip :title="`Any interaction with Muse Chat uses credits. You currently have ${$filters.number(balanceAvailable, 0, true)} remaining out of the total ${$filters.number(balanceTotal, 0, true)}. To acquire more credits, please contact support.`"></info-tooltip>
          </small>
        </p>

      </div>
    </template>
    <fluent-gpt v-bind="planOverviewProps"
                :width="gptWidth"
                ref="museChat"
                :suggestions="suggestions"
                :csvData="csvData"
                :title="title"
                :enable="drawerOpen"
                :immediatePrompt="immediatePrompt"
                :placeholder="placeholder"
                @newBalance="setBalance"></fluent-gpt>
  </drawer>
</template>

<script>
import Drawer from 'core-ui/components/common/drawer.vue'
import FluentGpt from 'core-ui/components/common/fluentGpt'

export default {
  name: 'MuseChatDrawer',
  components: { FluentGpt, Drawer },
  data () {
    return {
      drawerOpen: false,
      csvData: undefined,
      suggestions: [],
      title: undefined,
      placeholder: undefined,
      drawerSize: 690,
      immediatePrompt: false,
      planType: undefined,
      planTypeId: undefined,
      balanceTotal: undefined,
      balanceAvailable: undefined
    }
  },
  computed: {
    gptWidth () {
      return this.drawerSize - 130
    },
    activeItem () {
      return this.$store.getters.activeItem
    },
    planOverviewProps () {
      if (this.planType && this.planTypeId) {
        return {
          planType: this.planType,
          planTypeId: this.planTypeId
        }
      }
      if (this.activeItem) {
        const planType = this.activeItem.planType
        const key = planType === 'account' ? 'accountPlanId' : 'campaignPlanId'
        return {
          planType,
          planTypeId: {
            [key]: this.activeItem[key]
          }
        }
      }
      return {}
    }
  },
  watch: {
    drawerOpen (val) {
      if (val) {
        setTimeout(() => {
          const el = document.getElementById('muse-chat-textarea')
          if (el) {
            el.focus()
          }
        }, 400)
      }
    }
  },
  methods: {
    initDrawer (payload) {
      this.csvData = payload.csvData
      this.suggestions = payload.suggestions
      this.title = payload.title
      this.placeholder = payload.placeholder
      this.immediatePrompt = payload.immediatePrompt
      this.planType = payload.planType
      this.planTypeId = payload.planTypeId
      this.drawerOpen = true
    },
    cleanUpDrawer () {
      this.drawerOpen = false
      window.setTimeout(() => {
        // wait for drawer close animation to finish
        this.csvData = undefined
        this.suggestions = []
        this.title = undefined
        this.placeholder = undefined
        this.immediatePrompt = undefined
        this.planType = undefined
        this.planTypeId = undefined
        if (this.$refs.museChat) {
          this.$refs.museChat.clearHistory()
        }
      }, 300)
    },
    handleOpenClose (open) {
      if (!open) {
        this.cleanUpDrawer()
      }
    },
    setBalance ({ availableCredits, totalCredits }) {
      this.balanceTotal = totalCredits
      this.balanceAvailable = availableCredits
    },
    async remainingBalance () {
      const response = await this.$res.ai.balance()

      if (response) {
        this.setBalance(response)
      }
    }
  },
  async mounted () {
    await this.remainingBalance()
    this.$eventBus.$on('openMuseChatDrawer', this.initDrawer)
    this.$eventBus.$on('closeMuseChatDrawer', this.cleanUpDrawer)
  },
  beforeUnmount () {
    this.$eventBus.$off('closeMuseChatDrawer', this.cleanUpDrawer)
    this.$eventBus.$off('openMuseChatDrawer', this.initDrawer)
  }
}
</script>
