<template>
  <div>
    <template v-if="bionic">
<!--      This bionic nav popover item works slightly differently than the other bionic nav popover items
       due to the interaction needed with the autocomplete. -->
      <p-popover ref="customerSelectorPopover"
                 @show="popoverShowSideEffects">
<!--        TODO change this so that we can see a tall scrollable list of customers, not just an input and a dropdown and-->
        <div v-if="selectOptions.length > 0" class="d-flex" tabindex="0">
          <p-auto-complete v-model="primeVueInput"
                          :suggestions="filteredCustomerList"
                          optionLabel="text"
                          :dropdown="true"
                          placeholder="Select a Customer"
                          :force-selection="true"
                          :complete-on-focus="true"
                          class="flex-grow-1"
                          size="small"
                          ref="customerSelectorAutoComplete"
                          @complete="pvAutoComplete_complete($event)"/>
          <t-button-inline class="customer-switcher-reload-btn"
                           @click="reloadDropdown()"><fluency-icon type="loop" /></t-button-inline>
        </div>
      </p-popover>

      <div class="d-flex-center-center" >
        <div tabindex="0"
             ref="customerSelectorTarget"
             @mouseenter="showNavWithDelay($event, navPopoverId)"
             @mouseleave="cancelShowIfUnopened(navPopoverId)"
             @click.stop="showNavWithDelay($event, navPopoverId, 0)">
          <div class="bionic-customer-switcher-circle cursor-pointer d-flex-center-center">
            {{bionicNavBtnText}}
          </div>
        </div>
      </div>

    </template>
    <template v-else>
      <div v-if="selectOptions.length > 0">
        <div class="d-flex">
          <basic-mad-lib-input :value="selectedActingAsUser"
                               class="userCustomerSelector"
                               data-qa="userCustomerSelector"
                               :type-ahead-options="selectOptions"
                               placeholder="Select Customer"
                               :retain-value-on-focus="false"
                               text-variant="info font-weight-normal"
                               require-option
                               :pagingSize="100"
                               @input="switchCustomer($event)" />
          <b-btn @click="reloadDropdown()" size="sm" variant="ghost-secondary"><fluency-icon type="loop" /></b-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useBionicNavUtils } from '@/composables/useBionicNavUtils.js'

const { addNavPopover, repositionRight, showNavWithDelay, cancelShowIfUnopened } = useBionicNavUtils()

const customerSelectorPopover = ref(null)
const customerSelectorTarget = ref(null)
const customerSelectorAutoComplete = ref(null)
const navPopoverId = 'userCustomerSelector'
onMounted(() => {
  addNavPopover({
    navId: navPopoverId,
    targetEl: customerSelectorTarget.value,
    popoverRef: customerSelectorPopover.value
  })
})

const popoverShowSideEffects = async () => {
  repositionRight(customerSelectorPopover.value, { bottom: '195px' })
  setTimeout(() => {
    const input = customerSelectorAutoComplete.value?.$el?.querySelector('.p-autocomplete-input')
    input?.focus()
  }, 100)
}

</script>

<script>
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import _intersection from 'lodash.intersection'

export default {
  name: 'userCustomerSelector',
  components: { BasicMadLibInput },
  props: {
    actingAsUser: {
      type: Number
    },
    switchImmediately: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'sm'
    },
    dropDown: {
      type: Boolean,
      default: false
    },
    bionic: {
      type: Boolean,
      default: false
    },
    popover: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      primeVueInput: null,
      filteredCustomerList: null,
      bionicDropdownReady: true
    }
  },
  mounted () {
    if (this.selectedActingAsUser) {
      this.primeVueInput = this.selectOptions.find(customer => customer.value === this.selectedActingAsUser)
    }
    // this.delayedDebugger()
  },
  watch: {
    primeVueInput: {
      handler (newVal) {
        if (newVal && newVal.hasOwnProperty('value') && newVal?.value !== this.selectedActingAsUser) {
          this.switchCustomerPv(newVal)
        }

        if (newVal === null) {
          this.resetPrimeVueInputToCurrent()
        }
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    },
    selectedActingAsUser () {
      return this.actingAsUser || this.$store.getters.actingAsUser || null
    },
    selectOptions () {
      let options = []
      if (this.user?.allowableCustomers && this.user?.allowableCustomers.length > 0) {
        options = [{ text: 'Select Customer', value: '' }, ...this.user.allowableCustomers.map(c => ({ text: c.name, value: c.customerId }))]
      }
      return options
    },
    bionicNavBtnText () {
      if (this.selectedActingAsUser) {
        const customer = this.selectOptions.find(o => o.value === this.selectedActingAsUser)

        if (customer && customer.text) {
          return customer.text.substr(0, 1)
        }
      }

      return '-'
    }
  },
  methods: {
    popoverShown () {
      setTimeout(() => {
        this.bionicDropdownReady = true
      }, 1250)
    },
    popoverHidden () {
      this.bionicDropdownReady = false
    },
    resetPrimeVueInputToCurrent () {
      const currentSelectOption = this.selectOptions.find(opt => opt.value === this.selectedActingAsUser)

      if (currentSelectOption) {
        this.primeVueInput = currentSelectOption
      }
    },
    pvAutoComplete_complete (event) {
      if (!event.query.trim().length) {
        this.filteredCustomerList = [...this.selectOptions]
      } else {
        this.filteredCustomerList = this.selectOptions.filter(c => {
          return c.text.toLowerCase().indexOf(event.query.toLowerCase()) >= 0 || c.value === ''
        })
      }
    },
    blurHandlerPv (e) {
      const intersection = _intersection(['p-autocomplete-dropdown', 'customer-switcher-reload-btn'], e?.relatedTarget?.classList)
      if (e?.relatedTarget?.className && intersection.length > 0) {
        return
      }
      setTimeout(() => {
        this.emitClosePopover()
      }, 250)
    },
    emitClosePopover () {
      setTimeout(() => {
        this.$root.$emit('bv::hide::popover', this.popoverId)
      }, 100)
    },
    // delayedDebugger () {
    //   setTimeout(function () { debugger }, 10000)
    // },
    async reloadDropdown () {
      const response = await this.$res.fetch.user()

      if (response) {
        const newUser = {
          ...this.user,
          allowableCustomers: response.allowableCustomers
        }
        this.$store.commit('user', newUser)
        this.$toast('Customer Dropdown Reloaded', 'success')
      }
    },
    switchCustomerPv (val) {
      let url = null

      // if we are drilled into a plan in Manage we want to just send back to Manage landing page
      // and some special handling to make sure we retain the `fsEnv` url param
      if (this.$route.path.startsWith('/manage/')) {
        url = '/manage/'

        const envKey = 'fsEnv'

        if (this.$route?.query?.[envKey]) {
          url = `${url}?fsEnv=${this.$route.query[envKey]}`
        }
      }

      this.switchCustomer(val.value, true, url)
    },
    async switchCustomer (val, performSwitch = false, url) {
      this.$emit('change', val)
      if (this.switchImmediately || performSwitch) {
        this.$store.commit('setActingAsUser', val)
        await this.$store.dispatch('clearCustomerSpecificLocalStore')
        if (url) {
          window.location.replace(url)
        } else {
          window.location.reload()
        }
      }
    }
  }
}
</script>

<style scoped>
.bionic-customer-switcher {
  height: 48px;
}
.bionic-customer-switcher-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #596980;
  background-color: #dee5ee;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.5rem;
}
</style>
