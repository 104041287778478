import axios from 'axios'
import _pick from 'lodash.pick'
import _capitalize from 'lodash.capitalize'
import _cloneDeep from 'lodash.clonedeep'
import { unref } from 'vue'
import { AdTemplate } from '@/plugins/api-routes/adTemplate'
import { Blueprints } from '@/plugins/api-routes/blueprints'
import { Forum } from '@/plugins/api-routes/forum'
import { Budget } from '@/plugins/api-routes/budget'
import { Reporting } from '@/plugins/api-routes/reporting'
import { Demo } from '@/plugins/api-routes/demo'
import { Suggest } from '@/plugins/api-routes/suggest'
import { Colab } from '@/plugins/api-routes/colab'
import { Customer } from '@/plugins/api-routes/customer'
import { Account } from '@/plugins/api-routes/account'
import { Audience } from '@/plugins/api-routes/audience'
import { ApiDoc } from '@/plugins/api-routes/apiDoc'
import { AuthV2 } from '@/plugins/api-routes/authV2'
import { User } from '@/plugins/api-routes/user'
import { Ai } from '@/plugins/api-routes/ai'
import { DSP } from '@/plugins/api-routes/dsp'
import { Testing } from '@/plugins/api-routes/testing'
import { LocalServiceAds } from '../api-routes/localServiceAds'
import { Tree } from '@/plugins/api-routes/tree'

const apiTimeout = 60000 // (1 min.)
const apiTimeoutLong = 120000 // (2 min.) for longer api calls like /api/launch/preview
let lastApiCall = new Date().getTime()

const fluentResource = function (nuxtApp, $axios) {
  // TODO $route might not be a thing, might have to get it from useRoute()
  const { $store, $authn, $toast, $bugsnag, $route } = nuxtApp.vueApp.config.globalProperties
  const _flujax = (calltype, method) => async function (url, data = {}, opts = {}) {
    // await $authn.loginRedirect()
    if (calltype === 'set') {
      if (($store.getters.user.roles || []).includes('read_only') || $store.getters.user.readOnly === true) {
        $toast('Your account is marked as "Read-Only" and that action is not permitted. Your changes were not saved. Please refresh the page to discard any local changes.', 'danger')
        return false
      }
    }

    const timeout = opts.timeout || apiTimeout
    const responseType = opts.responseType || 'json'
    const external = !!opts.external
    const onUploadProgress = opts.onUploadProgress || (() => {})
    const headers = opts.headers

    // TEMPORARY CATCH UNTIL FIRST FEATURE UTILIZING EXTERNAL CALLS IS RELEASED -GT
    if (external) {
      $bugsnag(new Error('Unexpected Call to 3rd Party Resource'), {
        metadata: { url, data, opts }
      })
      return { dummy: 'data' }
    }

    const wakeUp = ((new Date().getTime() - lastApiCall) > 3600000)
    if (wakeUp) {
      $authn.validateSession()
    }

    const bugsnagIt = (e = {}, customStatus) => {
      let path = url
      const i = path.indexOf('?')
      if (i >= 0) {
        path = path.substring(0, i)
      }
      try {
        if (e.config?.headers?.Authorization) {
          delete e.config.headers.Authorization // ensure we aren't bugsnagging auth tokens
        }
      } catch (err) {
      }

      const status = e.response?.status ?? customStatus ?? e?.message ?? e?.code ?? 'Unknown'

      if (!['Network Error', 'ERR_NETWORK'].includes(status)) {
        $bugsnag(new Error(`${status} When Calling ${path}`), (event) => {
          event.groupingHash = `${status} ${path}`
          event.severity = 'info'
          event.request.httpMethod = method
          // format baseURL and url concatenation to always have a / inbetween
          event.request.url = `${$axios.defaults.baseURL.replace(/\/$/, '')}/${url.replace(/^\//, '')}`
          event.request.body = data
          event.unhandled = false
          event.addMetadata('response', {
            data: e.response?.data ?? 'No data in response',
            rawError: e || 'No response',
            status
          })
          event.addMetadata('page', {
            fullPath: $route.fullPath
          })
          if (e.response?.data?.requestId) {
            event.addMetadata('requestId', { requestId: e.response.data.requestId })
          }
        })
      }
    }

    try {
      return $axios({
        method,
        responseType,
        url,
        data,
        timeout,
        onUploadProgress,
        headers
      }).then((e) => {
        lastApiCall = new Date().getTime()
        if (opts.returnFullResponse) {
          return e
        }
        return e.data
      }).catch(e => {
        if (e.response) { // check to make sure response is defined, there are 110 scenarios where we didn't have response
          if (e.response?.headers?.['x-block-id'] === 'kp6HGvEvDjhYkB') {
            const fj = _flujax(calltype, method)
            return fj(url, data, { ...opts, headers: { ...headers || {}, 'flu-88372-xfid': '7ss663kd9s8sSH3Sk778' } }).then(e => e)
          }
          if (opts.returnErrors) {
            return e.response
          }

          const bugsnagData = { request: { url: `${$axios.defaults.baseURL.replace(/\/$/, '')}/${url.replace(/^\//, '')}` } }

          switch (e.response.status) {
            case 401:
              // access token has expired
              $authn.logout()
              break
            case 403:
              if (e.response.data) {
                if (typeof e.response.data === 'string') {
                  $toast(e.response.data, 'danger', { bugsnagData })
                }
                bugsnagIt(e)
              }
              console.error(e)
              break
            default:
              bugsnagIt(e)
              console.error(e)
              if (e.response.data) {
                if (typeof e.response.data === 'string' || typeof e.response.data?.message === 'string') {
                  $toast(e.response.data, 'danger', { bugsnagData })
                } else if (responseType === 'blob') { // if the responseType of the request is Blob, the error response will also be a Blob. if so we need to use FileReader to extract the message.
                  try {
                    const reader = new FileReader()
                    reader.onload = function () {
                      $toast(reader.result, 'danger', { bugsnagData })
                    }
                    reader.readAsText(e.response.data)
                  } catch (e) {
                    console.error(e)
                  }
                } else {
                  $toast(`Internal Server Error ${e.message}`, 'danger')
                }
              }
              break
          }

          lastApiCall = new Date().getTime()
          return false
        } else { // if we don't know what the bad response was throw a generic connection error
          // TIMEOUTS
          if (e.toString().includes('timeout of') && e.toString().includes('exceeded')) {
            if (window.location.hostname.includes('backpack.')) { // only log this in production
              bugsnagIt(e, 'Timeout')
              $toast(`Operation timed out after ${Math.round(timeout / 1000)} seconds`)
            }
          } else {
            bugsnagIt(e)
            $toast('Error connecting to Fluency\'s servers')
          }

          console.error(e)
        }

        lastApiCall = new Date().getTime()
      })
    } catch (e) {
      console.error(e)
    }
  }

  const flujax = {
    get: _flujax('unclassified', 'get'),
    post: _flujax('unclassified', 'post'),
    put: _flujax('unclassified', 'put'),
    patch: _flujax('unclassified', 'patch'),
    delete: _flujax('unclassified', 'delete'),
    fetch: {
      get: _flujax('fetch', 'get'),
      post: _flujax('fetch', 'post')
    },
    set: {
      post: _flujax('set', 'post')
    }
  }

  // $res isn't defined on the global properties until after this fluentResource function returns.
  // so the getters/setters that need $res should call this function
  const get$res = () => nuxtApp.vueApp.config.globalProperties.$res

  return {
    async pojoGet (resource, queryString, filterPayload) {
      return flujax.post(`/pojo/${resource}/get?${queryString}`, filterPayload)
    },
    fetch: {
      async dspMacros ({ customerId }) {
        return flujax.get('/dsp/macros/')
      },
      async dspMacroOptions () {
        return flujax.get('/dsp/macros/all')
      },
      async availableTrafficTypes () {
        return flujax.get('/dsp/traffic_types')
      },
      async availablePagePositions () {
        return flujax.get('/dsp/page_positions')
      },
      async trafficTypesForCampaign ({ accountPlanId, campaignPlanId }) {
        return flujax.get(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/traffic_types`)
      },
      async trafficTypesForAdGroup ({ accountPlanId, adGroupPlanId }) {
        return flujax.get(`/dsp/${accountPlanId}/adgroup/${adGroupPlanId}/traffic_types`)
      },
      async pagePositionsForCampaign ({ accountPlanId, campaignPlanId }) {
        return flujax.get(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/page_positions`)
      },
      async dspBidSettingsForCampaign ({ accountPlanId, campaignPlanId }) {
        return flujax.get(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/bid_settings`)
      },
      asyncGet (payload) {
        return flujax.post('/async/get', payload)
      },
      asyncKill (payload) {
        return flujax.post('/async/kill', payload)
      },
      async asyncFetchFileResource (payload) {
        return flujax.post('/async/fetchFileResource', payload, { responseType: 'blob' })
      },
      async getDynamicTagAliasesLite (payload) {
        return flujax.post('/template/alias/get', payload)
      },
      // async indexLogFiles (payload) {
      //   return flujax.post('/account/indexLogFiles', payload)
      // },
      async readLogFile (payload) {
        return flujax.post('/account/readLogFile', payload)
      },
      async analyzeCampaign (campaignPlan) {
        return flujax.post('/campaign/analyze', campaignPlan)
      },
      async ignorableFields () {
        return flujax.post('/diff/ignorable/get')
      },
      async partnerAudit (payload) {
        if (payload.adGroupPlanId) {
          return flujax.post('/adgroup/getLiveAdgroups', payload)
        }

        return flujax.post('/campaign/getLiveCampaigns', payload)
      },
      async getTermsAndConditions () {
        return flujax.post('/customer/getTermsAndConditions')
      },
      async errorMessageSuggest (type, accountPlanId, partnerObject) {
        return flujax.post(`/error/${accountPlanId}/${type}/suggest`, partnerObject)
      },
      async errorMessageResubmit (type, accountPlanId, partnerObject) {
        return flujax.post(`/error/${accountPlanId}/${type}/resubmit`, partnerObject)
      },
      async errorMessage (type, accountPlanId, partnerObject) {
        return flujax.post(`/error/${accountPlanId}/${type}/resolve`, partnerObject)
      },
      async notificationPriority () {
        return flujax.post('/dashboard/getPriority')
      },
      async idea (payload) {
        return flujax.post('/idea/get', payload)
      },
      async googleTargeting (accountPlanId = null, campaignPlanId = null, adGroupPlanId = null) {
        if (accountPlanId && campaignPlanId && adGroupPlanId) {
          return flujax.post('/criterion/getGoogleTargeting', { accountPlanId, campaignPlanId, adGroupPlanId })
        } else if (accountPlanId && campaignPlanId) {
          return flujax.post('/criterion/getGoogleTargeting', { accountPlanId, campaignPlanId })
        }

        return false
      },
      async siteVerification (accountPlanId) {
        return flujax.post(`/google/content/site-verification/get?accountPlanId=${accountPlanId}`)
      },
      async verification (accountPlanId) {
        return flujax.post('/account/verfication/get', { accountPlanId })
      },
      async googleAudienceEligibility () {
        // mimics: https://docs.google.com/spreadsheets/u/1/d/1kE0tvC75JO4S7Skj91o8qglrVBh7t6PM1YfUrVz29TI/pubhtml?gid=0&single=true
        return flujax.post('enum/getGoogleAudienceEligibility')
      },
      async conversionDetail (payload, type, params) {
        if (payload && type && params) {
          return flujax.post(`/${type}/getConversionDetail${params}`, payload)
        }
      },
      async accountDataPreview (accountData) {
        if (accountData) {
          return flujax.post('/accountData/preview', accountData)
        }
      },
      async customerDataPreview (customerData) {
        if (customerData) {
          return flujax.post('/customerData/preview', customerData)
        }
      },
      async blueprintRevisionHistory (contentTemplateId = null, limit = 10, offset = 0) {
        if (contentTemplateId === null) {
          return false
        }
        const queryString = `limit=${limit}&offset=${offset}`
        return flujax.post(`/template/getChanges?${queryString}`, { contentTemplateId })
      },
      async userFeedback (searchString) {
        const queryString = (searchString) ? `search=${searchString}` : ''
        return flujax.post(`/user/getFeedbackItems?${queryString}`)
      },
      async notes (accountPlan) {
        return flujax.post('/note/get', accountPlan)
      },
      async blueprintTagValue (payload, query) {
        const queryParts = []

        Object.entries(query).forEach((n) => {
          const key = n[0]
          const val = n[1]
          queryParts.push(`${key}=${val}`)
        })

        const queryString = queryParts.join('&')

        return flujax.post(`/template/getTagValue?${queryString}`, payload)
      },
      async blueprintPathResolver (payload, query) {
        const queryParts = []

        Object.entries(query).forEach((n) => {
          const key = n[0]
          const val = n[1]
          queryParts.push(`${key}=${val}`)
        })

        const queryString = queryParts.join('&')

        return flujax.post(`/template/pathResolver?${queryString}`, payload)
      },
      async accountRemapping (accountPlan) {
        return flujax.post('/account/remapping/get', accountPlan)
      },
      async competitor (accountPlan, type = 'get', searchTerm = null) {
        const queryString = (searchTerm) ? `?searchTerm=${searchTerm}` : ''
        return flujax.post(`/account/competitor/${type}${queryString}`, accountPlan)
      },
      async getTasksList (segmentId) {
        let path = '/dashboard/getTasksList'
        if (typeof segmentId === 'number') {
          path = `${path}?segmentId=${segmentId}`
        }
        return flujax.post(path)
      },
      async getNotificationSection ({ accountPlanIds, section }) {
        return flujax.post(`/user/getTaskStatus?taskName=${encodeURIComponent(section)}`, accountPlanIds, { timeout: apiTimeoutLong })
      },
      async getPendingNotifications () {
        return flujax.post('/task/action/get')
      },
      async getAvailableHeaders () {
        return flujax.post('/accountData/getAvailableHeaders')
      },
      async getCustomerFromUrl (url) {
        return flujax.post('/user/validateUrl', { url })
      },
      async getAvailableTransforms () {
        return flujax.post('/accountData/getAvailableTransforms')
      },
      async monitoredMediaSettings (accountPlan) {
        return flujax.post('/sitecrawler/media/settings/get', accountPlan)
      },
      async monitoredPages (accountPlan) {
        return flujax.post('/sitecrawler/media/get', accountPlan)
      },
      async monitoredMediaRefresh (accountPlan) {
        return flujax.post('/sitecrawler/media/refresh', accountPlan)
      },
      async monitoredMediaIdeas (accountPlan) {
        return flujax.post('/sitecrawler/media/ideas', accountPlan)
      },
      async coLabLink (paramString) {
        return flujax.post(`/generate/prospectKey${paramString}`)
      },
      async googleContentConfig (accountPlanId, type) {
        return flujax.post(`/google/${type}/config?accountPlanId=${accountPlanId}`)
      },
      async googleContentSearch (accountPlanId, type) {
        return flujax.post(`/google/${type}/search?accountPlanId=${accountPlanId}`)
      },
      async googleContentLinkStatus (type) {
        return flujax.fetch.post(`/google/${type}/link-status`)
      },
      async googleStoreCode (accountPlanId) {
        return flujax.fetch.post('/account/store-code/get', { accountPlanId })
      },
      async googleVerificationPhoneNumber (accountPlanId) {
        return flujax.fetch.post('/account/merchant-verification-phone-number/get', { accountPlanId })
      },
      async googleContentCreate (accountPlanId = null) {
        if (!accountPlanId) {
          return false
        }
        return flujax.fetch.post(`/google/content/create?accountPlanId=${accountPlanId}`)
      },
      async userPreference (type, key) {
        return flujax.post(`/user/getPref?type=${type}&key=${key}`)
      },
      async channelGroups () {
        return flujax.post('/channel/getGroups')
      },
      async unlatch (payload) {
        return flujax.post('/campaign/unlatch', payload)
      },
      async imageExtensionsForCampaign (id) {
        return flujax.post(`/imageextension/getByCampaignId/${id}`)
      },
      async imageExtensionsForAdGroup (id) {
        return flujax.post(`/imageextension/getByAdGroupId/${id}`)
      },
      async generic (url, payload, options, method) {
        if (method === 'GET') {
          return flujax.get(url, undefined, options)
        }
        return flujax.post(url, payload, options)
      },
      async bulkActions () {
        return flujax.post('/bulk/getSupportedActions')
      },
      async bulkFilters () {
        return flujax.post('/bulk/getFilters')
      },
      async bulkCounts ({ payload, params }) {
        return flujax.post(`/bulk/filterCounts/get${params}`, payload)
      },
      async bulkPreviewObjects ({ payload, params }) {
        return flujax.post(`/bulk/previewObjects/get${params}`, payload)
      },
      async bulkPreview ({ payload, params }) {
        return flujax.post(`/bulk/preview${params}`, payload)
      },
      async bulkApply ({ payload, params }) {
        return flujax.post(`/bulk/apply${params}`, payload)
      },
      async bulkSchedule ({ payload, params }) {
        return flujax.post(`/bulk/schedule${params}`, payload)
      },
      async bulkSubmits () {
        return flujax.post('/bulk/submissions')
      },
      async bulkReceipt (id) {
        return flujax.post(`/bulk/submissionDetails?submissionId=${id}`)
      },
      async creativeSpecs (channel) {
        if (channel) {
          return flujax.post(`/creative/getSupportedTypes/${channel}`)
        } else {
          return flujax.post('/creative/getSupportedTypes')
        }
      },
      async phones (payload) {
        return flujax.post('/phone/get', payload)
      },
      async phoneLocations (payload) {
        return flujax.post('/phone/getDefaultLocations', payload)
      },
      async phoneReprovision (payload, subAccount) {
        /*
        console.log(payload, subAccount)
        return
         */
        if (subAccount) {
          return flujax.post(`/phone/reprovision?source=${subAccount}`, payload)
        } else {
          return flujax.post('/phone/reprovision', payload)
        }
      },
      async phoneRelease (payload, subAccount) {
        /*
        console.log(payload, subAccount)
        return
         */
        if (subAccount) {
          return flujax.post(`/phone/remove?source=${subAccount}`, payload)
        } else {
          return flujax.post('/phone/remove', payload)
        }
      },
      async listIndex (payload, params) {
        if (params) {
          return flujax.fetch.post(`/list/index${params}`, payload)
        } else {
          return flujax.fetch.post('/list/index', payload)
        }
      },
      async listingGroups ({ accountPlanId, adGroupPlanId }) {
        return flujax.post(`/criterion/listingGroup?accountPlanId=${accountPlanId}&adGroupPlanId=${adGroupPlanId}`)
      },
      async questionnaireRequest (payload) {
        return flujax.post('/change/analysis', payload)
      },
      async audienceMetrics (payload) {
        return flujax.post('/audience/getMetrics', payload)
      },
      async iabFormats () {
        return flujax.post('/asset/getIabFormats')
      },
      async trackedTagParameters (accountPlanId) {
        if (accountPlanId) {
          return flujax.post(`/tracked-tag-parameters/get?accountPlanId=${accountPlanId}`)
        }
        return false
      },
      async accountSettingsList () {
        return flujax.post('/account/setting/list')
      },
      async accountSettings (payload = {}) {
        return flujax.post('/account/setting/get', payload)
      },
      async serverResponse (httpCode = 200) {
        // messageFormat can be 'string' or 'json'
        return flujax.post(`/error/${httpCode}?messageFormat=json`)
      },
      async crawlSiteDetectors () {
        return flujax.post('/sitecrawler/crawlsitedetectors/get')
      },
      async recommendationTypes () {
        return flujax.post('/recommendation/getRecommendationTypes')
      },
      async recommendationTypes2 () {
        return flujax.post('/recommendation/getRecommendationTypes2')
      },
      async manualRecommendationTypes () {
        return flujax.post('/recommendation/getManualRecommendationTypes')
      },
      async recommendationPolicies () {
        return flujax.post('/recommendation/getRecommendationPolicies')
      },
      async pdf (url, filename) {
        // return flujax.get(url, {}, { responseType: 'blob' })
        const response = await axios.get(url,
          {
            headers: {
              Accept: 'application/pdf',
              'Content-Type': 'application/pdf'
            },
            responseType: 'blob'
          })
        const data = window.URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = data
        link.download = filename || 'download.pdf'
        link.click()
      },

      async availableRtbDealPackages ({ channelId }) {
        switch (channelId) {
          case 18:
            return flujax.post('/user-list/getDDCRTBVideoDealPackages')
          default:
            break
        }
      },

      async availableRtbRemarketingAudiences (accountPlanId) {
        return flujax.post(`/user-list/getDDCRTBRemarketing?accountPlanId=${accountPlanId}`)
      },
      async availableRtbDomainLists () {
        return flujax.post('/user-list/getDDCRTBDomainLists')
      },
      async availableRtbSegments (accountPlanId) {
        if (accountPlanId) {
          return flujax.get(`/ddcrtbaudience/accountSegments/${accountPlanId}`)
        }
        return flujax.get('/ddcrtbaudience/globalSegments')
      },
      async validateRtbAudience (payload) {
        return flujax.post('/ddcrtbaudience/validateAudienceBoolean', payload, { headers: { 'Content-Type': 'application/json' } })
      },
      async enumValueLoader (path, method = 'POST', data) {
        return method === 'POST' ? flujax.post(path, data) : flujax.get(path)
      },
      async portfolioBidStrategies (campaignPlan) {
        return flujax.post('/campaign/getPortfolioBiddingStrategies', campaignPlan)
      },
      async previewBudgetChange (accountPlan) {
        return flujax.post('/account/previewBudgetChange', accountPlan)
      },
      async seGetTags (templateId) {
        return flujax.post('/template/getTags', templateId)
      },
      async savedSegments () {
        return flujax.fetch.post('/segment/get')
      },
      async availableFieldsForSegments () {
        return flujax.fetch.post('/segment/getFields')
      },
      async displayAdBuilderIndex (accountId, draftsOnly) {
        // accountId = 1657 // ford accountId used for testing
        if (!accountId) {
          return {}
        }
        // GREG!  At some point, we'll optionally enter this UI from an AdGroup ... when we do, this will
        // Ensure there is filtered and relevant content for you to work within.

        // return flujax.get(`adbuilder/index?accountPlanId=${accountId}&adGroupPlanId=818278`)
        return flujax.get(`adbuilder/index?accountPlanId=${accountId}&draftReviewMode=${!!draftsOnly}`)
      },
      async displayAdBuilderGet (payload = {}, accountId, adGroupId) {
        // accountId = 1657 // ford accountId used for testing
        if (!accountId || !adGroupId) {
          return {}
        }
        // GREG!  At some point, we'll optionally enter this UI from an AdGroup ... when we do, this will
        // Ensure there is filtered and relevant content for you to work within.

        // return flujax.post(`adbuilder/get?accountPlanId=${accountId}&adGroupPlanId=818278`, payload)
        return flujax.post(`adbuilder/get?accountPlanId=${accountId}&adGroupPlanId=${adGroupId}`, payload)
      },
      async displayAdBuilderRender (payload) {
        return flujax.post('adbuilder/render', payload)
      },
      async unlinkedAccounts (partnerId) {
        if (!partnerId || typeof partnerId !== 'number') {
          partnerId = 1
        }
        return flujax.fetch.post(`account/getUnlinkedAccounts?forPartner=${partnerId}`)
      },
      async html5AdTemplates () {
        return flujax.fetch.post('https://s3.us-east-2.amazonaws.com/templates.fluency.inc/html5ads/160x600/template.html', {}, { external: true })
      },
      async availableLabels () {
        return flujax.fetch.post('/account/getAvailableLabels')
      },
      async timeZones () {
        return flujax.fetch.post('timezone/get')
      },
      async advertisingChannel () {
        return flujax.fetch.post('/channel/get')
      },
      async advertisingChannelPartnerMap () {
        return flujax.fetch.post('/channel/getPartnerMap')
      },
      async anomalies () {
        return flujax.fetch.post('/anomalies/get')
      },
      async conversionActionTemplates () {
        return flujax.fetch.post('/conversion-template/get')
      },
      async conversionActions (payload) {
        return flujax.fetch.post('/conversion-actions/get', payload)
      },
      async conversionActionStandardWebEvents () {
        return flujax.fetch.post('/conversion-actions/getStandardWebEvents')
      },
      async dashboardWidgets () {
        return flujax.fetch.post('/dashboard/getWidgets')
      },
      async dashboardForUser () {
        return flujax.fetch.post('/dashboard/getForUser')
      },
      async proposalLink (accountPlanId, payload) {
        return flujax.post(`/proposal/getProposalLink?accountPlanId=${accountPlanId}`, payload)
      },
      async prospectLink (payload, customize) {
        if (customize) {
          return flujax.post('/proposal/customizeProspectingLink', payload)
        } else {
          return flujax.post('/proposal/getProspectingLink', payload)
        }
      },
      async proposalPdf (accountPlanId) {
        let accountPlanParam = ''
        if (accountPlanId && accountPlanId > -1) {
          accountPlanParam = `accountPlanId=${accountPlanId}`
        }
        return flujax.fetch.get(`/proposal/getProposal?${accountPlanParam}&mode=view`, {}, { responseType: 'arraybuffer' })
      },
      async partnerPollingData (payload) {
        return flujax.fetch.post('/pending/refresh', payload)
      },
      async similarMetrics (payload) {
        return flujax.fetch.post('/metrics/getSimilar', payload)
      },
      async goalsForUser (payload) {
        return flujax.fetch.post('/manager/getGoalsForUser', payload)
      },
      async goals (payload) {
        return flujax.fetch.post('/user/getGoals', payload)
      },
      async allGoals (payload) {
        return flujax.fetch.post('/user/getAllGoals', payload)
      },
      async managerGoals (payload) {
        return flujax.fetch.post('/user/getManagerGoals', payload)
      },
      async userPerformance (params) {
        return flujax.fetch.post(`/manager/getUserPerformance${params}`)
      },
      async previewAndReplace (payload) {
        return flujax.fetch.post('/keyword/previewReplace', payload)
      },
      async columnFields (forTable) {
        return flujax.fetch.post(`/user/${forTable}/getColumnOrder`)
      },
      async performanceBounds () {
        return flujax.fetch.post('metrics/getPerformanceBounds')
      },
      async revisionHistory ({ type, id, limit }) {
        let limitAppend = ''
        if (limit) {
          limitAppend = `&limit=${limit}`
        }
        if (type && id) {
          return flujax.fetch.post(`/history/get?type=${type}&id=${id}${limitAppend}`)
        }
      },
      async accountQa (accountPlanId) {
        return flujax.fetch.post(`/account/qa?accountPlanId=${accountPlanId}`)
      },
      async backpackVersion () {
        return flujax.fetch.post('/backpack/version/get', {}, { responseType: 'text' })
      },
      async accountQaDetails ({ accountPlanId, forStrategyId, forType }) {
        let postUrl = ''
        if (forStrategyId) {
          postUrl = `/account/qaDetail?accountPlanId=${accountPlanId}&forStrategyId=${forStrategyId}&forType=${forType}`
        } else {
          postUrl = `/account/qaDetail?accountPlanId=${accountPlanId}&forType=${forType}`
        }
        return flujax.fetch.post(postUrl)
      },
      async flashBriefing () {
        return flujax.fetch.get('/dashboard/getNews')
      },
      async accountsByContentTemplate (payload, includePausedCampaigns) {
        const params = `?includePausedCampaigns=${!!includePausedCampaigns}`

        return flujax.fetch.post(`/launch/getAccountForContentTemplate${params}`, payload)
      },
      async changeLog (params) {
        let paramString = ''
        if (params) {
          paramString = '?'
          Object.keys(params).forEach(paramKey => { paramString += `&${paramKey}=${params[paramKey]}` })
          paramString = paramString.replace('?&', '?')
        }
        return flujax.fetch.get(`/changelog/get${paramString}`)
      },
      async changeLog2 (params, payload) {
        let paramString = ''
        if (params) {
          paramString = '?'
          Object.keys(params).forEach(paramKey => { paramString += `&${paramKey}=${params[paramKey]}` })
          paramString = paramString.replace('?&', '?')
        }
        return flujax.fetch.post(`/changelog/v2/get${paramString}`, payload)
      },
      async changeLogMetrics (params) {
        let paramString = ''
        if (params) {
          paramString = '?'
          Object.keys(params).forEach(paramKey => { paramString += `&${paramKey}=${params[paramKey]}` })
          paramString = paramString.replace('?&', '?')
        }
        return flujax.fetch.get(`/changelog/metrics/basic/get${paramString}`)
      },
      async vehicleAdsChecklist (payload) {
        return flujax.fetch.post('/account/vehicle-ads/checklist', payload)
      },
      async pinterestChecklist (payload) {
        return flujax.fetch.post('/account/pinterest/checklist', payload)
      },
      async fetch2Fa (filt) {
        let paramString = ''
        if (filt) {
          paramString = '?source=' + filt
        }
        return flujax.fetch.post(`/secret/getAuthenticatorCode${paramString}`)
      },
      async executeAction (path) {
        return flujax.fetch.post(path)
      },
      async generateBulk (payload) {
        return flujax.fetch.post('/launch/generateBulk', payload)
      },
      async clipboardResolve (payload) {
        return flujax.fetch.post('/clipboard/resolve', payload)
      },
      async bulkKeywordText (adGroupPlanId) {
        const payload = { adGroupPlanId }

        return flujax.fetch.post('/keyword/getBulkKeywordText', payload)
      },
      async accountExcelSheetDownload (ids = [], metrics = false, includedTypes = [], returnFile = false) {
        if (!ids || ids.length === 0) {
          return
        }
        const config = {
          headers: {
            // Accept: 'application/vnd-xls',
            // 'Content-Type': 'application/vnd-xls'
          },
          responseType: 'blob'
        }

        if ($store.getters.actingAsUser) {
          config.headers['Customer-Actor-Id'] = $store.getters.actingAsUser
        }
        if ($authn.isAuthenticated()) {
          config.headers.Authorization = `Bearer ${$authn.getAccessToken()}`
          config.headers['X-Backpack-Path'] = $route.path
        }

        const queryParts = []
        queryParts.push('format=xlsx')
        queryParts.push(`includeMetrics=${metrics}`)
        // queryParts.push(`accountPlanIds=${btoa(ids.join(','))}&e=1`)
        if (includedTypes?.length > 0) {
          queryParts.push(`includedTypes=${includedTypes.join(',')}`)
        }

        const apiUrlString = await apiUrl(nuxtApp.vueApp)
        const response = await axios.post(`${apiUrlString}/export/accounts/?${queryParts.join('&')}`, [...ids], config)
        if (returnFile) return response.data
        const data = window.URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = data
        const fileName = response.headers['x-filename']
        link.download = fileName || 'export_fluency.xls'
        link.click()
      },
      async fullNotificationsDownload () {
        const response = await flujax.fetch.get('/export/notifications/', {}, { responseType: 'blob', returnFullResponse: true })

        const data = window.URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = data
        const fileName = response.headers['x-filename']
        link.download = fileName || 'notifications_fluency.xls'
        link.click()
      },
      async accountEvents ({ segmentId, accountPlanId, limit, afterId, beforeId, field, since, before }) {
        const params = []
        if (segmentId) params.push(`segmentId=${segmentId}`)
        if (accountPlanId) params.push(`accountPlanId=${accountPlanId}`)
        if (limit) params.push(`limit=${limit}`)
        if (since) params.push(`since=${since}`)
        if (before) params.push(`before=${before}`)
        if (afterId) params.push(`afterId=${afterId}`)
        if (beforeId) params.push(`beforeId=${beforeId}`)
        if (field) params.push(`field=${field}`)

        let paramsString = ''
        if (params.length > 0) {
          paramsString = '?' + params.join('&')
        }

        return flujax.fetch.post(`/event/get${paramsString}`)
      },
      async reverseSync (payload) {
        return flujax.fetch.post('/account/reverseSync', payload)
      },
      async accountPlanBudgetMonitor (params, payload) {
        return flujax.fetch.post(`/analysis/getAccountPlanBudgetMonitor${params}`, payload)
      },
      // getBudgetPlanBudgetMonitor
      async budgetPlanBudgetMonitor (params, payload) {
        return flujax.fetch.post(`/analysis/getBudgetPlanBudgetMonitor${params}`, payload)
      },
      async automationLog (params, payload) {
        return flujax.fetch.post(`/analysis/getAutomationLog${params}`, payload)
      },
      async automationLogDetail (payload) {
        return flujax.fetch.post('/analysis/getAutomationLogDetail', payload)
      },
      async addressFromLatLng (latitude, longitude) {
        return flujax.fetch.get(`/geo/reverseGeocode?lat=${latitude}&lng=${longitude}`)
      },
      async accountData (accountPlanIdPayload) {
        return flujax.fetch.post('/accountData/get', accountPlanIdPayload)
      },
      async timeZoneByAddress (address) {
        return flujax.fetch.post(`/geo/getTimeZoneForAddress?address=${address}`, {}, { responseType: 'text' })
      },
      async keywordAnalysisSummary () {
        return flujax.fetch.post('/analysis/keywordAnalysisSummary')
      },
      async keywordAnalysis ({ recommendations, accountPlan }) {
        if (!accountPlan || !accountPlan.accountPlanId) {
          return false
        }
        return flujax.fetch.post('/analysis/keywordAnalysis', { recommendations, accountPlan })
      },
      async pojo (resource, queryString, filterPayload) {
        let query = ''
        if (queryString && queryString.length > 0) {
          query = `?${queryString}`
        }
        return flujax.fetch.post(`/pojo/${resource}/get${query}`, filterPayload)
      },
      async pojoList (resource, queryString, filterPayload) {
        let query = ''
        if (queryString && queryString.length > 0) {
          query = `?${queryString}`
        }
        return flujax.fetch.post(`/pojo/${resource}/getAll${query}`, filterPayload)
      },
      async accountDetails (params, payload) {
        // always tack on the segment ID
        // let load = payload || {}
        // if (vue.store.getters.activeSegment) {
        //   load.criteriaJson = vue.store.getters.activeSegment.criteriaJson
        // }
        return flujax.fetch.post(`/account/get${params ?? ''}`, payload)
      },
      async accountsMinimal () {
        return flujax.fetch.post('account/getMinimal')
      },
      async accountsMinimalPeers () {
        return flujax.fetch.post('account/getMinimalPeers')
      },
      async accountsSelect () {
        return flujax.fetch.post('/accounts.select')
      },
      async accountsSelectForDemoPurposes (vertical) {
        return flujax.fetch.post(`/accounts.select/${vertical}`)
      },
      async accountDemoData (account) {
        return flujax.fetch.post('/account/demoData/get', account)
      },
      async accountDemoFlags (account, selectedTemplate) {
        return flujax.fetch.post(`/account/demoFlags/get?contentTemplateId=${selectedTemplate}`, account)
      },
      async campaignList (payload) {
        return flujax.fetch.post('/campaign/getAll', payload)
      },
      async campaignById (id) {
        return flujax.fetch.post('campaign/get', { campaignPlanId: id })
      },
      async partners () {
        return flujax.fetch.post('/partner/get')
      },
      async templates () {
        return flujax.fetch.post('/template/get', { status: 'ENABLED' }, { timeout: apiTimeoutLong })
      },
      async templatesMinimal () {
        const devUrlParam = 'loadMetrics=false'
        return flujax.fetch.post(`/template/getMinimal?${devUrlParam}`, { status: 'ENABLED' }, { timeout: apiTimeoutLong })
      },
      async recentlyDeletedBlueprints () {
        return flujax.fetch.post('/template/getRemovedBlueprints')
      },
      async launchableTemplatesList () {
        return flujax.fetch.post('/template/list?excludeSnippets=true')
      },
      async blueprintListCheckPromotable (listDataGlobalId, listName) {
        const payload = { listDataGlobalId, listName }

        return flujax.set.post('/blueprintList/checkPromotable', payload)
      },
      async templatesAudit (payload) {
        return flujax.fetch.post('/template/audit/', payload)
      },
      async templatesSelect () {
        return flujax.fetch.post('/templates.select')
      },
      async template (payload, showDeleted = false) {
        if (!showDeleted) {
          payload.status = 'ENABLED'
        }

        // TODO: Make sorted call. Need Campaign Preview to do the same thing
        return flujax.fetch.post('/template/get?sorted=true', payload, { timeout: apiTimeoutLong })
      },
      async templatePreview (payload) {
        return flujax.fetch.post('/template/preview', payload)
      },
      async debugTag (payload) {
        return flujax.fetch.post('/template/debug', payload)
      },
      async getTemplatesLinkable () {
        return flujax.fetch.post('/template/getLinkableTemplates', { status: 'ENABLED' })
      },
      async skeleton (type) {
        if (typeof type === 'object') {
          return flujax.fetch.post('/object/describeAllObjects/', type)
        } else if (type.indexOf('inc.fluency') > -1) {
          return flujax.fetch.post(`/object/describeForPackage/${type}/`)
        }
        return flujax.fetch.post(`/object/describeForObject/${type}`)
      },
      async seConfig () {
        return flujax.fetch.post('/strategyEditor/config')
      },
      async googleConstants (consumer = null) {
        const queryString = (consumer) ? `?consumer=${consumer}` : ''

        return flujax.fetch.post(`/enum/getGoogleAudienceConstants${queryString}`)
      },
      async excludableAudienceTypes () {
        return flujax.fetch.post('/enum/getCampaignNegatableTypes')
      },
      async namedAudiences (id, channel = null) {
        const queryString = (channel) ? `?channel=${channel}` : ''
        return flujax.fetch.post(`/template/getNamedAudiences${queryString}`, { accountPlanId: id })
      },
      async basisInterestCategory (name) {
        const queryString = (name) ? `?category=${name}` : ''
        return flujax.fetch.post(`/enum/getBasisAudiencesByCategory${queryString}`)
      },
      async testMatchers (payload) {
        return flujax.fetch.post('/template/testMatchers', payload)
      },
      async metrics (query, params) {
        return flujax.fetch.post(`/metrics/get${params}`, query)
      },
      async getPreviousSpan (startDate, endDate, priorDateStrategy) {
        const payload = {}

        if (startDate && endDate) {
          payload.startDateTime = startDate
          payload.endDateTime = endDate
        }
        if (priorDateStrategy) {
          payload.priorDateStrategy = priorDateStrategy
        }

        return flujax.fetch.post('/metrics/getPreviousSpan', payload)
      },
      async previousMetrics (payload, params) {
        return flujax.fetch.post(`/metrics/getPreviousMetrics${params}`, payload)
      },
      async metricsByDay ({ accountPlanId, startDate, endDate }) {
        return flujax.fetch.post(`/metrics/accountPlanByDay?accountPlanId=${accountPlanId}&startDate=${startDate}&endDate=${endDate}`)
      },
      async structure (type = null, query = '', payload) {
        if (type) {
          return flujax.fetch.post(`/${type}/getStructure${query}`, payload)
        }

        return []
      },
      async plan (planType, query, params = '', opts = {}) {
        const payload = query

        const { headerPageSize } = opts

        const accountPlanId = $store.getters?.activeItem?.accountPlanId ??
          $store.getters?.settingsItem?.accountPlanId

        if (planType === 'keyword' && accountPlanId) {
          payload.accountPlanId = accountPlanId
        }

        // prevent loading of campaigns, creatives, or keywords without an account ID specified
        if (planType !== 'account' && (!accountPlanId) && !Object.keys(query).filter(q => q.includes('PlanId')).some(e => query[e])) {
          // let it go since there is a plan id
          // log this only if it is an external user
          if ($store.getters?.user?.email && !$store.getters?.user?.internalUser) { // there's a race condition where this call is invoked when the user has no creds and is in the process of being auto-logged out. only bugsnag if the user has auth
            $bugsnag(new Error('No account ID specified in get plan request.'), {
              metadata: {
                planType,
                attemptedQuery: query || 'undefined',
                params
              }
            })
            console.log('Error: No account ID specified', planType, query)
          }

          // do not load plan
          return []
        }
        const options = {}
        if (planType.toLowerCase() === 'creative') {
          options.timeout = apiTimeoutLong
        }

        // if (headerPageSize && ['campaign', 'adGroup'].includes(planType)) {
        //   options.headers = {
        //     page: 1,
        //     pageSize: 5000
        //   }
        // }

        return flujax.fetch.post(`/${planType.toLowerCase()}/get${params}`, payload, options)
      },

      async planChildren (planType, query, params = '') {
        const accountPlanId = $store.getters?.activeItem?.accountPlanId ??
          $store.getters?.settingsItem?.accountPlanId

        const items = query?.map(item => {
          // prevent loading of campaigns, creatives, or keywoards without an account ID specified
          if (
            planType !== 'account' &&
            (!accountPlanId || accountPlanId < 1) &&
            (!item.campaignPlanId && !item.adGroupPlanId && !item.keywordPlanId && !item.creativePlanId) // I dont know what this line accomplishes but I am keeping it in here for refactor so i dont break anything
          ) {
            if (!$store.getters?.user?.internalUser) {
              $bugsnag(new Error('No account ID specified in get plan request.'), {
                metadata: {
                  planType,
                  attemptedQuery: item
                }
              })
              console.log('Error: No account ID specified', planType, item)
            }
            return false
          }

          switch (planType) {
            case 'account':
              return item.accountPlanId
            case 'budget':
              return item.budgetPlanId
            case 'campaign':
              return item.campaignPlanId
            case 'adGroup':
              return item.adGroupPlanId
            case 'creative':
            case 'ad':
              return item.creativePlanId
            case 'keyword':
              return accountPlanId ? item.keywordPlanId : false
            default:
              return false
          }
        })?.filter(item => item) || []

        const idQueryString = items.join(',')

        if (idQueryString) {
          params += (params.indexOf('?') !== -1) ? `&parentIds=${idQueryString}` : `?parentIds=${idQueryString}`
        }

        return flujax.fetch.post(`/${planType.toLowerCase()}/getChildren${params}`, {})
      },
      async suggestGeosForCampaignPlan (campaignPlan, params = '') {
        return flujax.fetch.post(`/geo/suggestForCampaignPlan${params}`, { accountPlanId: campaignPlan.accountPlanId, campaignPlanId: campaignPlan.campaignPlanId })
      },
      async listData ({ accountPlanId }) {
        return flujax.fetch.get(`/list/getAll/${accountPlanId}?limitResponseSize=true`)
      },
      async listFieldIndex () {
        return flujax.fetch.get('/list/fieldIndex/get')
      },
      async listDataFeatureFlagOverrides ({ accountPlanId, customerId, primaryVertical }) {
        return flujax.fetch.post('/list/getOverrideFlags', { accountPlanId, customerId, primaryVertical })
      },
      async listDataDestinations () {
        return flujax.fetch.get('listData/selector/destinations')
      },
      async crawlerData ({ accountPlanId }) {
        return flujax.fetch.get(`/sitecrawler/suggest/${accountPlanId}?hint=`)
      },
      async crawlerDebug (payload) {
        return flujax.fetch.post('/sitecrawler/debug', payload)
      },
      async siteLinks ({ accountPlanId }) {
        return flujax.fetch.post(`/sitelink/getByAccountPlanId/${accountPlanId}`)
      },
      async sharedSets ({ accountPlanId }) {
        return flujax.fetch.post('/sharedSet/list', { accountPlanId })
      },
      async sharedSetsAttachedToCampaign ({ campaignPlanId }) {
        return flujax.fetch.post('/sharedSet/getAttachedByCampaignPlan', { campaignPlanId })
      },
      async sharedSetsForAccount ({ accountPlanId }) {
        return flujax.fetch.post('/sharedSet/getForAccountPlan', { accountPlanId })
      },
      async enumeration (type) {
        const extractEnumerationFromPackage = (val) => {
          if (val != null) {
            return val.substring(val.lastIndexOf('.') + 1)
          }
          return ''
        }

        return flujax.fetch.post(`/enum/getForType/${extractEnumerationFromPackage(type)}`)
      },
      async campaignGroupOptions (planType, { accountPlanId, campaignPlanId }) {
        // planType = campaign or budget
        return flujax.fetch.post(`/${planType}/getAvailableGroups`, { accountPlanId, campaignPlanId })
      },
      async dashboard (type, params) {
        let hrefParams = (params) || ''
        if (window.location.search.includes('saveDemo')) {
          hrefParams += '&saveDemo=1'
        }
        return flujax.fetch.post(`/dashboard/getData?dataType=${type}${hrefParams}`)
      },
      async dashboardTasks ({ accountPlanId, segmentId }) {
        const params = []
        if (accountPlanId) params.push(`accountPlanId=${accountPlanId}`)
        if (typeof segmentId !== 'undefined') params.push(`segmentId=${segmentId}`)
        let paramsString = ''
        if (params.length > 0) {
          paramsString = '?' + params.join('&')
        }
        return flujax.fetch.post(`/dashboard/getTasks${paramsString}`, {}, { timeout: apiTimeoutLong })
      },
      async preview (payload) {
        return flujax.fetch.post('/launch/preview', payload, { timeout: apiTimeoutLong })
      },
      async requestPreview (payload) {
        return flujax.fetch.post('/launch/requestPreview', payload, { timeout: apiTimeoutLong })
      },
      async previewStatus (payload) {
        return flujax.fetch.post('/launch/previewStatus', payload, { timeout: apiTimeoutLong })
      },
      async pendingLaunches (launchPlan = {}) {
        const payload = { ...launchPlan, status: 'PENDING' }
        return flujax.fetch.post('/launch/get', payload)
      },
      async broadcasts ({ accountPlanId }) {
        return flujax.fetch.post('/broadcast/getForAccountPlan', { accountPlanId })
      },
      async customerBroadcasts () {
        return flujax.fetch.post('/broadcast/getForCustomer')
      },
      async broadcastPreview (accountPlanId, forDate) {
        return flujax.fetch.post(`/broadcast/preview?forDate=${forDate}`, { accountPlanId })
      },
      async accountBroadcastDraftsForCustomer () {
        return flujax.fetch.post('/broadcast/getAccountDraftsForCustomer')
      },
      async accounts (queryString) {
        const query = queryString?.length > 0 ? `?${queryString}` : ''
        return flujax.fetch.post(`/pojo/AccountPlan/get${query}`)
      },
      async negativeKeywords (campaignPlanId) {
        return flujax.fetch.post('/keyword/negative/getByCampaignPlan', { campaignPlanId })
      },
      async negativeKeywordsByAdGroup (adGroupPlanId) {
        return flujax.fetch.post('/keyword/negative/getByAdGroupPlan', { adGroupPlanId })
      },
      async geoBrowse ({ searchPhrase, advertisingChannelId, accountPlanId, includedTypes, countryCode }) {
        return flujax.fetch.post('/geo/browseGeo', { searchPhrase, advertisingChannelId, accountPlanId, includedTypes, countryCode })
      },
      async geoByGoogleIds (geoIdsArray) {
        return flujax.fetch.post('/geo/byGoogleIds', geoIdsArray)
      },
      async geocodeByAddress (addressString) {
        return flujax.fetch.post(`geo/geocode?address=${addressString}`)
      },
      async languages () {
        return flujax.fetch.post('/enum/language/get')
      },
      async checkRole (role) {
        if (role) {
          return flujax.fetch.post(`user/authorizedFor?authority=${role}`)
        } else {
          return false
        }
      },
      async getRoles () {
        return flujax.fetch.post('user/getRoles')
      },
      async usersForCustomer () { // gets all customer scoped users
        return flujax.fetch.post('user/getForCustomer')
      },
      async userDtosForCustomer () { // gets all customer scoped users
        return flujax.fetch.post('user/getDtoForCustomer')
      },
      async user () { // gets the logged in user
        return flujax.fetch.post('user/get')
      },
      async specificUser (payload) {
        return flujax.fetch.post('/user/getUser', payload)
      },
      async overrides ({ accountPlanId }) {
        return flujax.fetch.post('/overrides/get ', { accountPlanId })
      },
      async listGetAll (accountPlanId) {
        return flujax.fetch.post(`list/getAll/${accountPlanId}`)
      },
      async getLightweightPreviewWithTemplateContent (config = {}, templateContent = {}) {
        const contentTemplateIdParam = (config.contentTemplateId) ? `&contentTemplateId=${config.contentTemplateId}` : ''
        const campaignIndexParam = (config.campaignIndex >= 0 && config.campaignIndex !== null) ? `&campaignIndex=${config.campaignIndex}` : ''
        const adGroupIndexParam = (config.adGroupIndex >= 0 && config.adGroupIndex !== null) ? `&adGroupIndex=${config.adGroupIndex}` : ''
        const creativeIndexParam = (config.creativeIndex >= 0 && config.creativeIndex !== null) ? `&creativeIndex=${config.creativeIndex}` : ''
        return flujax.fetch.post(`template/lightDynamicPreview?accountPlanId=${config.accountPlanId}${contentTemplateIdParam}${campaignIndexParam}${adGroupIndexParam}${creativeIndexParam}`, templateContent, { timeout: apiTimeoutLong })
      },
      async getLightweightPreview (config = {}, payload = {}) {
        const tidParam = (config.contentTemplateId) ? `&contentTemplateId=${config.contentTemplateId}` : ''
        const campaignIndexParam = (config.campaignIndex >= 0 && config.campaignIndex !== null) ? `&campaignIndex=${config.campaignIndex}` : ''
        const adGroupIndexParam = (config.adGroupIndex >= 0 && config.adGroupIndex !== null) ? `&adGroupIndex=${config.adGroupIndex}` : ''
        const creativeIndexParam = (config.creativeIndex >= 0 && config.creativeIndex !== null) ? `&creativeIndex=${config.creativeIndex}` : ''
        const urlWithOverrides = (Object.entries(payload).length > 0) ? 'WithOverrides' : ''

        return flujax.fetch.post(`template/lightDynamicPreview${urlWithOverrides}?accountPlanId=${config.accountPlanId}${tidParam}${campaignIndexParam}${adGroupIndexParam}${creativeIndexParam}`, payload, { timeout: apiTimeoutLong })
      },
      async dynamicPreview (payload, config = {}) {
        const tidParam = (config.contentTemplateId) ? `&templateId=${config.contentTemplateId}` : ''
        const enhanced = '&enhanced=true'
        const async = (config.asynchronous) ? '&async=true' : ''
        if (config.accountPlanId) {
          return flujax.fetch.post(`template/dynamicPreview?accountPlanId=${config.accountPlanId}${tidParam}${enhanced}${async}`, payload, { timeout: apiTimeoutLong })
        } else if (config.vertical) {
          return flujax.fetch.post(`template/dynamicPreview?vertical=${config.vertical}${tidParam}${enhanced}${async}`, payload, { timeout: apiTimeoutLong })
        }
      },
      async getAvailableFields (payload, config = {}) {
        if (payload) {
          const tidParam = (config.contentTemplateId) ? `&templateId=${config.contentTemplateId}` : ''
          const treeParam = (config.asTree === false) ? '&asTree=false' : ''

          if (config.accountPlanId) {
            return flujax.fetch.post(`template/getAvailableFields?accountPlanId=${config.accountPlanId}${tidParam}${treeParam}`, payload)
          } else if (config.vertical) {
            return flujax.fetch.post(`template/getAvailableFields?vertical=${config.vertical}${tidParam}${treeParam}`, payload)
          }
        } else {
          if (config.contentTemplateId) {
            return flujax.fetch.post(`template/getAvailableFields/${config.contentTemplateId}`)
          } else if (config.vertical) {
            return flujax.fetch.post('template/getAvailableFields/byVertical', config)
          }
        }
      },
      async searchCreatives (payload) {
        return flujax.fetch.post('creative/search/', payload)
      },
      async productPartition (adGroupPlan) {
        return flujax.fetch.post('/shopping/product-partition/getByAdGroupPlan', adGroupPlan)
      },
      async productDimensions (accountPlanId, attributeFilters = []) {
        return flujax.fetch.post(`shopping/product-dimensions/getFiltered?accountPlanId=${accountPlanId}`, attributeFilters)
      },
      async statsForProductPartitionId (productPartitionPlanId) {
        return flujax.fetch.post(`shopping/google/statsForProductPartition/getById?productPartitionPlanId=${productPartitionPlanId}`)
      },
      async productScopes (productScopePlan) {
        return flujax.fetch.post('shopping/product-scope/get', productScopePlan)
      },
      async eligibleProductScopes (payload) {
        return flujax.fetch.post('shopping/product-scope/getEligibleForCampaignPlan', payload)
      },
      async productDimensionOperators () {
        return flujax.fetch.post('/shopping/product-dimensions/getOperators')
      },
      async productDimensionCombiningOperators () {
        return flujax.fetch.post('/shopping/product-dimensions/getCombiningOperators?version=2')
      },
      async productDimensionAttributes ({ advertisingChannelId, type }) {
        if (!advertisingChannelId) {
          return []
        }
        const params = []
        params.push(`advertisingChannelId=${advertisingChannelId}`)
        if (type) {
          params.push(`productCatalogPlanType=${type}`)
        }
        return flujax.fetch.post(`/shopping/product-dimensions/getAttributes?${params.join('&')}`)
      },
      async productScopePreview (productScopePlan) {
        return flujax.fetch.post('/shopping/product-scope/previewProducts', productScopePlan)
      },
      async reverseSyncStatus (accountPlan, opts = {}) {
        const { pendingOnly } = opts
        const params = []
        if (pendingOnly) {
          params.push('pendingOnly=true')
        }
        return flujax.fetch.post(`account/getReverseSyncStatus?${params.join('&')}`, accountPlan)
      },
      async reverseSyncHistory (accountPlanId) {
        if (!accountPlanId) return
        return flujax.fetch.post(`reverse/sync/getReverseSyncs?accountPlanId=${accountPlanId}`)
      },
      async reverseSyncWidget (widgetType) {
        if (['getTopAccounts', 'getChangesByDate', 'getChangesByObject'].indexOf(widgetType) === -1) return
        return flujax.fetch.post(`reverse/sync/${widgetType}`)
      },
      async reverseSyncChanges () {
        return flujax.fetch.post('reverse/sync/getChangesByAccountAndObject')
      },
      async reverseSyncDiffs (payload) {
        return flujax.fetch.post('reverse/sync/getPendingDifferences', payload)
      },
      async criterionSearchResults (searchOptions) {
        let query = ''
        if (searchOptions) {
          query = '?' + Object.entries(searchOptions).map(opt => `${opt[0]}=${opt[1]}`).join('&')
        }

        return flujax.fetch.post(`criterion/search${query}`)
      },
      async audiencePlans (audiencePlan) {
        return flujax.fetch.post('audience/get', audiencePlan)
      },
      async criterionPlans (criterionPlan) {
        if (!criterionPlan) return
        if (criterionPlan.accountPlanId || criterionPlan.campaignPlanId || criterionPlan.adGroupPlanId) {
          return flujax.fetch.post('criterion/get', criterionPlan)
        } else {
          $bugsnag(new Error('Cannot get criterion without a plan id'), {
            metadata: {
              criterionPlan
            }
          })
          return []
        }
      },
      async adGroupTargeting ({ adGroupPlanId, accountPlanId, convertUnmanagedTargeting }) {
        if (!adGroupPlanId && !accountPlanId) return
        const query = convertUnmanagedTargeting ? '?convertUnmanagedTargeting=true' : ''
        return flujax.fetch.post(`adgroup/getTargeting${query}`, { adGroupPlanId, accountPlanId })
      },
      async unmanagedTargeting ({ adGroupPlanId, campaignPlanId, accountPlanId }) {
        if (!adGroupPlanId && !campaignPlanId && !accountPlanId) return
        return flujax.fetch.post('adgroup/getUnmanagedTargeting', { adGroupPlanId, campaignPlanId, accountPlanId })
      },
      async job (objectId, objectType, epochStart) {
        return flujax.fetch.post(`jobs/forObject?objectId=${objectId}&objectType=${objectType}&epochStart=${epochStart}`)
      },
      async dynamicAdsCreativePreview ({ creativePlanId, accountPlanId, adSpec, adGroupPlanId, creativeType }) {
        if (!creativePlanId && !adSpec) {
          return null
        }
        return flujax.fetch.post('creative/preview', { creativePlanId, accountPlanId, adSpec, adGroupPlanId, creativeType })
      },
      async dynamicAdsTemplatePreview ({ creativeTemplates, productScopeTemplate, accountPlanId }) {
        return flujax.fetch.post('creative/previewTemplate', { creativeTemplates, productScopeTemplate, accountPlanId })
      },
      async exchangeVideoIdForUrl ({ accountPlanId, videoId }) {
        return flujax.fetch.post(`creative/exchangeVideoId?accountPlanId=${accountPlanId}&videoId=${videoId}`)
      },
      async navigation (type, payload) {
        if (type === 'campaign' || type === 'adgroup') {
          const options = {}
          if (type === 'adgroup') {
            options.headers = {
              page: 1,
              pageSize: 250
            }
          }
          return flujax.fetch.post(`${type}/getNavigation`, payload, options)
        }
        return null
      },
      async structuredNavigation (accountPlanId) {
        return flujax.fetch.post('campaign/getStructuredNavigation', { accountPlanId })
      },
      async navigationSearch (filterQuery, status) {
        if (!status) {
          status = $store.getters.showPlanStatus
        }
        let statusEnum
        switch (status.toLowerCase()) {
          case 'show all but removed':
            statusEnum = 'ALL_BUT_REMOVED'
            break
          case 'show all enabled':
            statusEnum = 'ENABLED_ONLY'
            break
          case 'show all':
          default:
            statusEnum = 'ALL'
            break
        }
        return flujax.fetch.post('navigation/get', {
          filterStatus: statusEnum,
          filterMatch: filterQuery
        })
      },
      async facebookPageLinkStatus () {
        return flujax.fetch.post('page/link-status')
      },
      async facebookPageSearch ({ accountPlanId, query }) {
        if (accountPlanId) {
          return flujax.fetch.post(`page/search?accountPlanId=${accountPlanId}`)
        }
        if (query) {
          return flujax.fetch.post(`page/searchByQuery?query=${query}`)
        }
      },
      async facebookPages ({ accountPlanId, facebookPageId }) {
        return flujax.fetch.post('page/partnerPages', { accountPlanId, facebookPageId })
      },
      async facebookPagePosts ({ accountPlanId }) {
        return flujax.fetch.post('/creative/getPromotablePosts', { accountPlanId })
      },
      async promotablePostSuggestedLocations ({ accountPlanId }) {
        return flujax.fetch.post('/creative/getPromotablePostSuggestedLocations', { accountPlanId })
      },
      async subscriptions (accountPlan) {
        return flujax.fetch.post('subscription/get', accountPlan)
      },
      async facebookAccountsForCustomer () {
        return flujax.fetch.post('customer/fb-account-list')
      },
      // async timeout (timeout = 10) {
      //   return flujax.fetch.post(`timeout?grog=true&timeout=${timeout}`, {}, { timeout: 5587 })
      // },
      async eventDefinitions () {
        return flujax.fetch.post('/eventDefinition/get')
      },
      async launchStatusByAccount (accountPlanId) {
        if (!accountPlanId) {
          return
        }
        return flujax.fetch.post(`/launch/getPollingStatusForAccountPlanId?accountPlanId=${accountPlanId}`)
      },
      async launchStatus () {
        return flujax.fetch.post('/launch/getAllStatus')
      },
      async latestLaunchStatus () {
        return flujax.fetch.post('/launch/getLatestStatuses')
      },
      async launchStatusDetail (payload) {
        return flujax.fetch.post('/launch/getStatusByDetail', payload)
      },
      async launchDiff (launchPlanId) {
        return flujax.fetch.post('/launch/diff', { launchPlanId })

        // TODO: We may replace with this endpoint later that Mayhew has some accuracy benefits
        // return flujax.fetch.post('/launch/getExpectedChanges', { launchPlanId })
      },
      async listDataMapping (partnerId = null) {
        const queryString = (partnerId) ? `partnerId=${partnerId}` : ''
        return flujax.fetch.post(`/list/mapping/get?${queryString}`)
      },
      // async ftmJs ({ accountPlanId }) {
      //   let query = []
      //   if (accountPlanId) {
      //     query.push(`accountPlanId=${accountPlanId}`)
      //   }
      //
      //   let queryString = query.length === 0 ? '' : '?' + query.join('&')
      //
      //   return flujax.fetch.post(`tracking-code/getFtmJs${queryString}`)
      // },
      async partnerTrackingCodes ({ accountPlanId, partnerId }) {
        const query = []
        if (accountPlanId) {
          query.push(`accountPlanId=${accountPlanId}`)
        }
        if (partnerId) {
          query.push(`partnerId=${partnerId}`)
        }
        const queryString = query.length === 0 ? '' : '?' + query.join('&')
        return flujax.fetch.post(`tracking-code/get${queryString}`)
      },
      async blockedKeywords ({ accountPlanId }) {
        if (!accountPlanId) {
          throw Error('accountPlanId required to fetch blocked keywords')
        }
        return flujax.fetch.post('keyword/getBlocked', [{ accountPlanId }])
      },
      async productCatalogPlans (payload) {
        if (!payload.accountPlanId) {
          throw Error('accountPlanId required to fetch productCatalogPlans')
        }
        return flujax.fetch.post('product-catalog/get', payload)
      },
      async listProductCatalogs ({ vertical, advertisingChannelId }) {
        return flujax.fetch.post(`product-catalog/list?vertical=${vertical}&advertisingChannelId=${advertisingChannelId}`)
      },
      async listProductSets ({ productCatalogName, advertisingChannelId }) {
        if (!productCatalogName) {
          return []
        }
        return flujax.fetch.post(`product-scope/list?productCatalogName=${productCatalogName}&advertisingChannelId=${advertisingChannelId}`)
      },
      async productCatalogVertical ({ templateName, advertisingChannelId }) {
        if (!templateName) {
          return
        }
        const namePayload = templateName.replaceAll('[', '').replaceAll(']', '')
        return flujax.fetch.post(`product-catalog/findVertical?templateName=${namePayload}&advertisingChannelId=${advertisingChannelId}`)
      },
      async userLists ({ accountPlanId, listType, status, eligibleForSearch, eligibleForDisplay, eligibleForFacebook }) {
        if (!accountPlanId) {
          throw Error('accountPlanId required to fetch user lists')
        }
        return flujax.fetch.post('user-list/get', { accountPlanId, listType, status, eligibleForSearch, eligibleForDisplay, eligibleForFacebook })
      },
      async userListTosLinks ({ accountPlanId }) {
        if (!accountPlanId) {
          throw Error('accountPlanId required to fetch tos links')
        }
        return flujax.fetch.post('user-list/tosLinksForAccount', { accountPlanId })
      },
      async budgetUnderspendProtectionStatus ({ accountPlanId, budgetPlanId }) {
        if (!accountPlanId && !budgetPlanId) {
          throw Error('accountPlanId or budgetPlanId are required to fetch Underspend Mitigation status')
        }
        return flujax.fetch.post('budget/getUnderspendStatus?minimalStatus=true', { accountPlanId, budgetPlanId })
      },
      async gaAccountList (accountName) {
        return flujax.fetch.post(`/ga/accounts?accountName=${accountName}`)
      },
      async gaPropertyList (accountId) {
        return flujax.fetch.post(`/ga/properties?accountId=${accountId}`)
      },
      async gaListProperties (forceRefresh) {
        if (forceRefresh) {
          return flujax.fetch.post('/ga/list/properties?forceRefresh=true')
        } else {
          return flujax.fetch.post('/ga/list/properties')
        }
      },
      async gaProfileList (accountId, webPropId) {
        return flujax.fetch.post(`/ga/profiles?accountId=${accountId}&webPropId=${webPropId}`)
      },
      async gaProfileLink (accountPlanId, accountId, webPropId, profileId) {
        return flujax.fetch.post(`/ga/link?accountPlanId=${accountPlanId}&accountId=${accountId}&propertyId=${webPropId}&profileId=${profileId}`)
      },
      async gaLinkProperty (accountPlanId, selectedProperty) {
        return flujax.fetch.post(`/ga/link/property?accountPlanId=${accountPlanId}`, selectedProperty)
      },
      async triage (planType, planId) {
        if (!planType || !planId) {
          return
        }
        return flujax.fetch.post(`/triage/${planType}/${planId}`)
      },
      async triageMom ({ accountPlanId }) {
        return flujax.fetch.post('/triage/monthOverMonth', { accountPlanId })
      },
      async adGroupAdDeliveryOptions (campaignGoal, destinationType) {
        return flujax.fetch.post(`/adgroup/getOptimizationEnum?Campaign Goal=${campaignGoal || ''}&destinationType=${destinationType}`)
      },
      async adGroupPlacementOptions () {
        return flujax.fetch.post('/adgroup/getPlacementTargetingOptions')
      },
      async adGroupDestinationOptions (campaignGoal) {
        return flujax.fetch.post(`/adgroup/getDestinationTypeEnum?Campaign Goal=${campaignGoal || ''}`)
      },
      async adGroupGeoTarget ({ adGroupPlanId, campaignPlanId }) {
        if (!adGroupPlanId && !campaignPlanId) {
          return
        }
        return flujax.fetch.post('/adgroup/getGeo', { adGroupPlanId, campaignPlanId })
      },
      async dynamicForm (dynamicFormId) {
        if (!dynamicFormId) {
          return
        }
        return flujax.fetch.post('/account/getDynamicDataForm?dynamicFormId=' + dynamicFormId)
      },
      async setDynamicForm (dynamicForm) {
        if (!dynamicForm) {
          return
        }
        return flujax.fetch.post('/account/setDynamicDataForm', dynamicForm)
      },
      async getAllDynamicForms () {
        return flujax.fetch.post('/account/getDynamicDataForms')
      },
      async dynamicReport (dynamicReportId) {
        if (!dynamicReportId) {
          return
        }
        return flujax.fetch.post('/reporting/fetchDynamicReport', { dynamicReportId })
      },
      async positiveGeoTargetMethodEnum ({ campaignType, advertisingChannelId }) {
        let query = ''
        if (advertisingChannelId) {
          query = `advertisingChannelId=${advertisingChannelId}`
        } else if (campaignType) {
          query = `Campaign Type=${campaignType}`
        }
        if (!query) {
          return []
        }
        return flujax.fetch.post(`/geo/getPositiveGeoTargetMethodEnum?${query}`)
      },
      async negativeGeoTargetMethodEnums () {
        return flujax.fetch.post('/geo/getNegativeGeoTargetMethodEnums')
      },
      async sitecrawlerConfig (payload) {
        return flujax.fetch.post('/sitecrawler/config/get', payload)
      },
      async planCapabilities (planType, planDto) {
        if (planType && planDto) {
          return flujax.fetch.post(`/${planType}/getCapabilities`, planDto)
        }
      },
      async customerData () {
        return flujax.fetch.post('/customerData/getAll')
      },
      async broadcastMeta ({ accountPlanId }) {
        return flujax.fetch.post('/broadcast/meta/get', { accountPlanId })
      },
      async recommendationsForAccount (payload) {
        return flujax.fetch.post('/recommendation/account/peers/rank/get', payload)
      },
      async sharedUserLists (accountPlanId) {
        if (!accountPlanId) {
          return
        }
        return flujax.fetch.post(`/user-list/getShared?accountPlanId=${accountPlanId}`)
      },
      async campaignGoals ({ campaignType, advertisingChannelId, accountPlanId }) {
        if (!campaignType && !advertisingChannelId) {
          return []
        }
        const params = []
        if (campaignType) {
          params.push(encodeURI(`Campaign Type=${campaignType}`))
        }
        if (advertisingChannelId) {
          params.push(`advertisingChannelId=${advertisingChannelId}`)
        }
        if (accountPlanId) {
          params.push(`accountPlanId=${accountPlanId}`)
        }
        const query = params.length > 0 ? `?${params.join('&')}` : ''
        return await flujax.fetch.post(`/campaign/getGoals${query}`)
      },
      async youtubeChannel (accountPlanId) {
        return flujax.fetch.post('/youtube/get', { accountPlanId })
      },
      async refreshYoutubeChannel (accountPlanId) {
        return flujax.fetch.post(`/youtube/refresh?accountPlanId=${accountPlanId}`)
      },
      async promotions ({ accountPlanId }) {
        if (accountPlanId) {
          return await flujax.fetch.post('/promotion/get', { accountPlanId })
        }
      },
      async instantExperienceTemplates () {
        return await flujax.fetch.post('/instant-experience-templates/get')
      },
      async instantExperienceLayouts () {
        return await flujax.fetch.post('instant-experience-layouts/get')
      },
      async instantExperiences ({ accountPlanId, instantExperienceId, status, pageReferenceId }) {
        return await flujax.fetch.post('/instant-experiences/get', { accountPlanId, instantExperienceId, status, pageReferenceId })
      },
      async getListDataFormsForAccount (accountPlan) {
        if (accountPlan) {
          return flujax.fetch.post('/accountData/getForms', accountPlan)
        }
      },
      async getListDataFormsForCustomer () {
        return flujax.fetch.post('/customer/forms/get')
      },
      async setListDataFormsForCustomer (listDataForm) {
        if (!listDataForm?.listDataFormId && listDataForm?.removed === true) {
          return listDataForm
        }
        return flujax.fetch.post('/customer/forms/set', listDataForm)
      },
      async getDomainLists (accountPlanId, campaignPlanId) {
        return flujax.get(`/dsp/${accountPlanId}/${campaignPlanId}/domains`)
      },
      async getDomainListsForCustomer (customerId) {
        return flujax.fetch.post(`dsp/getDomainsForCustomer?customerId=${customerId}`)
      },
      async getDomainListForId (customerId, listDataId) {
        return flujax.fetch.post(`dsp/getDomainListForListId?customerId=${customerId}&listDataId=${listDataId}`)
      },
      async getDomainListForCustomerDataId (accountPlanId, listDataId) {
        return flujax.fetch.post(`dsp/getDomainListForCustomerDataId?accountPlanId=${accountPlanId}&customerDataId=${listDataId}`)
      },
      async getRemovedAccounts () {
        return flujax.fetch.post('/account/removed/get')
      }
    },
    set: {
      async dspMacros (dspMacros) {
        return flujax.post('/dsp/macros', dspMacros)
      },
      async trafficTypesForCampaign ({ accountPlanId, campaignPlanId, settings }) {
        return flujax.post(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/traffic_types`, { settings })
      },
      async trafficTypesForAdGroup ({ accountPlanId, adGroupPlanId, settings }) {
        return flujax.post(`/dsp/${accountPlanId}/adgroup/${adGroupPlanId}/traffic_types`, { settings })
      },
      async pagePositionsForCampaign ({ accountPlanId, campaignPlanId, settings }) {
        return flujax.post(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/page_positions`, { settings })
      },
      async dspBidSettingsForCampaign ({ accountPlanId, campaignPlanId, settings }) {
        return flujax.post(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/bid_settings`, { settings })
      },
      async initiatePasswordReset (user) {
        return flujax.set.post('/user/requestOneTimePassword', user)
      },
      async dismissRecommendations (recommendation) {
        return flujax.set.post('/recommendation/dismissRecommendations', recommendation)
      },
      async dismissAllRecommendations (recommendations) {
        return flujax.set.post('/recommendation/dismissAllRecommendations', recommendations)
      },
      async undismissRecommendations (recommendation) {
        return flujax.set.post('/recommendation/undismissRecommendations', recommendation)
      },
      async undismissAllRecommendations (recommendations) {
        return flujax.set.post('/recommendation/undismissAllRecommendations', recommendations)
      },
      async refreshRecommendationsForAccount (accountPlanId) {
        return flujax.set.post('/recommendation/refreshForAccount', { accountPlanId })
      },
      async refreshRecommendationsForCampaign (campaignPlanId) {
        return flujax.set.post('/recommendation/refreshForCampaign', { campaignPlanId })
      },
      async googleStoreCode (accountPlanId, val) {
        return flujax.post(`/account/store-code/set?storeCode=${encodeURIComponent(val)}`, { accountPlanId })
      },
      async googleVerificationPhoneNumber (accountPlanId, val) {
        return flujax.post(`/account/merchant-verification-phone-number/set?merchantVerificationPhone=${encodeURIComponent(val)}`, { accountPlanId })
      },
      async googleVerificationPhoneNumberCode (accountPlanId, val) {
        return flujax.post(`/account/merchant-verification-phone-number-code/set?code=${encodeURIComponent(val)}`, { accountPlanId })
      },
      async googleTargeting (payload) {
        return flujax.post('/criterion/setGoogleTargeting', payload)
      },
      async note (note) {
        return flujax.post('/note/set', note)
      },
      async accountNamingOverride (override) {
        return flujax.post('/account/naming/overrideName', override)
      },
      async accountBlueprintRemapping (remapping) {
        return flujax.post('/account/remapping/setByDTO', remapping)
      },
      async trackUserEvent (event = '', payload = {}) {
        const queryString = `event=${event}`

        return flujax.post(`/user/event?${queryString}`, payload, { headers: { 'Content-Type': 'application/json' } })
      },
      async competitorSetAll (payload) {
        return flujax.post('/account/competitor/setAll', payload)
      },
      async notificationsPerformAction (payload) {
        return flujax.post('/task/action/perform', payload)
      },
      async muteNotifications (timePeriod, payload) {
        return flujax.post(`/task/action/dismiss?timePeriod=${timePeriod.replace(/ /g, '')}`, payload)
      },
      async unmuteNotifications (payload) {
        return flujax.post('/task/action/unArchive', payload)
      },
      async adgroupPlanEndDateForCampaignPlan (payload) {
        return flujax.post('/campaign/setAdgroupPlanEndDateForCampaignPlan', payload)
      },
      async monitoredMediaSettings (monitoredMediaSettings) {
        return flujax.post('/sitecrawler/media/settings/set', monitoredMediaSettings)
      },
      async monitoredMediaPage (monitoredMediaPage) {
        return flujax.post('/sitecrawler/media/set', monitoredMediaPage)
      },
      async googleContentLink (merchantId, accountPlanId, type) {
        if (merchantId && accountPlanId) {
          const param = type === 'content' ? 'merchantId' : 'gmbAccountId'
          return flujax.set.post(`/google/${type}/link?accountPlanId=${accountPlanId}&${param}=${merchantId}`)
        }
        return false
      },
      async googleContentUnlink (accountPlanId, type) {
        if (accountPlanId) {
          return flujax.set.post(`/google/${type}/unlink?accountPlanId=${accountPlanId}`)
        }
        return false
      },
      async bulkSubmitCancel (id) {
        return flujax.post('/bulk/cancel', id, { headers: { 'Content-Type': 'application/json' } })
      },
      async excelImport (payload) {
        return flujax.set.post('/export/apply/', payload)
      },
      async phones (payload) {
        return flujax.set.post('phone/setAll', payload)
      },
      async imageExtensionPlans (payload) {
        return flujax.post('/imageextension/setAll', payload)
      },
      async createExperiment (blueprintId, accountPlanIds) {
        const payload = {
          sourceBlueprintId: blueprintId,
          accountPlanIds: accountPlanIds
        }

        return flujax.set.post('/template/createExperiment', payload)
      },
      async terminateExperiment (blueprintId) {
        const payload = { contentTemplateId: blueprintId }

        return flujax.set.post('/template/terminateExperiment', payload)
      },
      async promoteExperiment (blueprintId) {
        const payload = { contentTemplateId: blueprintId }

        return flujax.set.post('/template/promoteExperiment', payload)
      },
      async migrateAccounts (from, to, accountPlanIds) {
        const payload = {
          fromContentTemplateId: from,
          toContentTemplateId: to,
          accountPlanIds: accountPlanIds
        }

        return flujax.set.post('/template/migrateAccounts', payload)
      },
      async migrateAllAccounts (from, to) {
        const payload = {
          fromContentTemplateId: from,
          toContentTemplateId: to
        }
        return flujax.set.post('/template/migrateAllAccounts', payload)
      },
      async questionnaireRequest (payload) {
        return flujax.set.post('/change/apply?dryRun=false', payload)
      },
      async audienceCampaignLink (payload) {
        return flujax.set.post('/audience/link', payload)
      },
      async audienceCampaignUnlink (payload) {
        return flujax.set.post('/audience/unlink', payload)
      },
      async setTargeting (payload) {
        return flujax.post('/audience/targeting/set', payload)
      },
      async listDataExtractMedia (payload) {
        return flujax.set.post('/list/extractMedia', payload)
      },
      async accountSettings (payload) {
        return flujax.set.post('/account/setting/set', payload)
      },
      async mediaGallerySaveAsset ({ format, src }, payload) {
        if (payload) {
          return flujax.set.post(`/asset/exchangeForAsset?src=${src}`, payload)
        } else {
          return flujax.set.post(`/asset/exchangeForAsset?src=${src}&format=${format}`)
        }
      },
      async templateSetDefaultAccount (t, a) {
        return flujax.set.post(`template/setDefaultAccount?contentTemplateId=${t}&accountPlanId=${a}`)
      },
      async crawlSiteDetector (payload) {
        return flujax.set.post('sitecrawler/crawlsitedetector/set', payload)
      },
      async deleteCrawlSiteDetector (payload) {
        return flujax.set.post('sitecrawler/crawlsitedetector/delete', payload)
      },
      async recommendationPolicy (payload) {
        return flujax.set.post('recommendation/addRecommendationPolicy', payload)
      },
      async recommendationPolicies (payload) {
        return flujax.set.post('recommendation/setRecommendationPolicies', payload)
      },
      async imageUpload (payload, onUploadProgress, opts = {}) {
        const query = []
        if (opts.enhancedData) query.push('enhanced=true')
        if (opts.useShare) query.push('useShare=true')
        return flujax.set.post(`asset/up?${query.join('&')}`, payload, { onUploadProgress, timeout: apiTimeoutLong * 5 })
      },
      async excelUpload (payload, onUploadProgress) {
        return flujax.set.post('export/up', payload, { onUploadProgress, timeout: apiTimeoutLong * 5 })
      },
      async portfolioBidStrategy (payload) {
        return flujax.set.post('bidding/set', payload)
      },
      async saveAdBuilder (payload, queryString = '') {
        return flujax.set.post(`adbuilder/saveToAdGroup${queryString}`, payload)
      },
      async clearPendingAccount (payload) {
        return flujax.set.post('/account/clearPending', payload)
      },
      async muteRecommendations (payload) {
        return flujax.set.post('/recommendation/muteRecommendations', payload)
      },
      async applyRecommendations (payload) {
        return flujax.set.post('/recommendation/applyRecommendations', payload)
      },
      async blueprint (payload) {
        return flujax.set.post('/template/set', payload)
      },
      async blueprintMinimal (payload) {
        return flujax.set.post('/template/setMinimal', payload)
      },
      async blueprintListPromote (listDataGlobalId, listName) {
        const payload = { listDataGlobalId, listName }

        return flujax.set.post('/blueprintList/promote', payload)
      },
      async templateGenerate (payload) {
        return flujax.fetch.post('/template/generate', payload)
      },
      async segment (payload) {
        return flujax.set.post('/segment/set', payload)
      },
      async deleteSegment (payload) {
        return flujax.set.post('/segment/delete', payload)
      },
      async labelsForAccount (payload) {
        return flujax.set.post('/account/setLabelsForAccountPlan', payload)
      },
      async unlinkedAccounts (payload) {
        return flujax.set.post('/account/linkAccounts', payload)
      },
      async accountReconnect (payload) {
        return flujax.set.post('/account/reconnect', payload)
      },
      async anomalyResolve (key, handlerData) {
        return flujax.set.post(`/anomalies/resolve?key=${key}`, handlerData)
      },
      async anomalyResolveAll (key, handlerDatas) {
        return flujax.set.post(`/anomalies/resolveAll?key=${key}`, handlerDatas)
      },
      async conversionActionTemplates (payload) {
        return flujax.set.post('/conversion-template/set', payload)
      },
      async dashboardWidgetsForUser (usersWidgets) {
        return flujax.post('/dashboard/setForUser', usersWidgets)
      },
      async bulkStatusChange (plan, ids, status, readOnlyOverride) {
        return flujax.set.post(`/${plan.toLowerCase()}/setAllStatus?status=${status}&readOnlyOverride=${readOnlyOverride}`, ids)
      },
      async applyKeywordsByResults (payload) {
        return flujax.set.post('/keyword/applyByResults', payload)
      },
      async goals (payload) {
        return flujax.set.post('/manager/setGoals', payload)
      },
      async columnFields (forTable, payload) {
        return flujax.post(`/user/${forTable}/setColumnOrder`, payload)
      },
      async shareColumnSet ({ forTable, shareWith, columnSet }) {
        // todo validate/cleanse shareWith
        return flujax.post(`/user/${forTable}/shareColumnSet?with=${encodeURIComponent(shareWith)}`, columnSet)
      },
      async performanceBounds (payload) {
        return flujax.set.post('/metrics/setPerformanceBounds', payload)
      },
      async keywordAnalysisModify (payload) {
        return flujax.set.post('/analysis/keywordAnalysis/modify', payload)
      },
      async republishFromBlueprint (payload) {
        return flujax.set.post(`/launch/reLaunch?accountPlanId=${payload.accountPlanId}&contentTemplateId=${payload.contentTemplateId}`, payload)
      },
      async republishAllAccountsFromBlueprint (payload) {
        return flujax.set.post(`/launch/reLaunchAll?&contentTemplateId=${payload.contentTemplateId}&includePausedCampaigns=${payload.includePausedCampaigns}`, payload)
      },
      async stageBulkLaunch (payload) {
        return flujax.set.post('/launch/stageBulk', payload)
      },
      async executeBulkLaunch (payload, params) {
        return flujax.set.post(`/launch/executeBulk${params}`, payload)
      },
      async pastePlans (payload) {
        return flujax.set.post('/paste/into', payload, { timeout: apiTimeoutLong })
      },
      async previewPaste (payload) {
        return flujax.set.post('/clipboard/previewPaste', payload)
      },
      async crawlerExecute (accountPlanId) {
        return flujax.set.post(`/sitecrawler/execute?accountPlanId=${accountPlanId}`, {}, { responseType: 'text' })
      },
      async changeLogRevert (payload) {
        return flujax.set.post('/changelog/revert', payload)
      },
      async bulkKeywordsByText (adGroupPlanId, bulkKeywordRequest) {
        const payload = {
          adGroupPlanId,
          bulkKeywordRequest
        }

        return flujax.set.post('keyword/addBulkKeywordsByText', payload)
      },
      async setCIDs (partner, payload) {
        const partnerPath = _capitalize(partner)

        return flujax.set.post(`/oauth/${partner}/set${partnerPath}CIDs`, payload, { timeout: apiTimeoutLong })
      },
      async registerCustomer (partner, payload) {
        return flujax.set.post(`/oauth/${partner}/registerCustomer`, payload, { timeout: apiTimeoutLong })
      },
      async authorizationCodeExchange (partner) {
        return flujax.set.post(`/oauth/${partner}/status`, {}, { timeout: apiTimeoutLong })
      },
      async authorizationCodeExchangeMCC (partner) {
        return flujax.set.post(`/oauth/${partner}/mccStatus`, {}, { timeout: apiTimeoutLong })
      },
      async user (payload) {
        const skeleton = await get$res().fetch.skeleton('UserRegistry')
        return flujax.set.post('/user/set', _pick(payload, ...Object.keys(skeleton || {})))
      },
      async recordMode (on) {
        if (on) {
          return flujax.set.post('/user/turnOnRecording')
        } else {
          return flujax.set.post('/user/turnOffRecording')
        }
      },
      async userAccessRole (user, role) {
        const skeleton = await get$res().fetch.skeleton('UserRegistry')
        return flujax.set.post(`/user/setRoleForUser?toRole=${role}`, _pick(user, ...Object.keys(skeleton || {})))
      },
      async userPreference ({ type, key, value }) {
        return flujax.post(`/user/setPref?type=${type}&key=${key}&value=${value}`)
      },
      async userHomePage (defaultHomePage) {
        // default home page should be a valid route path
        return flujax.post('/user/setHomePage', { defaultHomePage })
      },
      async userDefaultManageTableType ({ defaultManageTableType }) {
        return flujax.post('/user/setDefaultManageTableType', { defaultManageTableType })
      },
      async uploadImageFromFiles (accountPlanId, channelName, creativeType, files) {
        return flujax.set.post(`/creative/uploadImagesFromFiles?accountPlanId=${accountPlanId}&advertisingChannel=${channelName}&creativeType=${creativeType}`, files)
      },
      async uploadImageFromUrls (accountPlanId, channelName, creativeType, urls) {
        return flujax.set.post(`/creative/uploadImagesFromUrls?accountPlanId=${accountPlanId}&advertisingChannel=${channelName}&creativeType=${creativeType}`, urls)
      },
      async cloneStrategy (payload) {
        return flujax.set.post('/template/copy', payload)
      },
      async contentTemplateIdForCampaign (campaignPlan) {
        const skeletons = $store.getters.skeletons
        let payload = {}
        payload = _pick(campaignPlan, ...Object.keys(skeletons?.campaign?.campaignPlan ?? {}))
        return flujax.set.post('/campaign/setContentTemplateId', payload)
      },
      async accountData (accountPlanIdPayload) {
        return flujax.set.post('/accountData/set', accountPlanIdPayload)
      },
      async pojo (resource, filterPayload) {
        if (Array.isArray(filterPayload)) {
          return flujax.set.post(`/pojo/${resource}/setAll`, filterPayload)
        } else {
          return flujax.set.post(`/pojo/${resource}/set`, filterPayload)
        }
      },
      async subscription (subscription) {
        return flujax.set.post('/subscription/set', subscription)
      },
      async broadcasts (broadcasts = []) {
        if (broadcasts.length > 0) {
          const skeletons = $store.getters.skeletons
          const payload = broadcasts.map(b => ({
            ..._pick(b, ...Object.keys(skeletons?.AccountBroadcast ?? {})),
            accountBroadcastMatchers: (b.accountBroadcastMatchers || []).map(m => ({
              ..._pick(m, ...Object.keys(skeletons?.AccountBroadcastMatcher ?? {}))
            }))
          }))
          if (payload.length > 0) {
            return flujax.set.post('/broadcast/setAll', payload)
          }
        }
        return []
      },
      async publishBroadcasts ({ accountPlanId }, excludedBroadcastIds = []) {
        let excludedQuery = ''
        if (excludedBroadcastIds.length > 0) {
          excludedQuery = '?excludedBroadcastIds=' + excludedBroadcastIds.join(',')
        }
        const url = `/broadcast/publishDraftsForAccountPlan${excludedQuery}`
        return flujax.set.post(url, { accountPlanId })
      },
      async publishCustomerBroadcasts () {
        return flujax.set.post('/broadcast/publishDraftsForCustomer')
      },
      async publishBroadcastsByIds (broadcastIdsToPublish = []) {
        if (broadcastIdsToPublish?.length > 0) {
          return flujax.set.post('/broadcast/publishByIds', broadcastIdsToPublish)
        }
        return []
      },
      async plan (planType, plan) {
        if (planType && plan) {
          let skeletons = $store.getters.skeletons
          let payload = {}

          if (planType === 'ad') {
            planType = 'creative'
          }

          if (planType === 'campaign') {
            if (!skeletons.campaign?.campaignPlanDTO || !skeletons.campaign?.campaignPlan || !skeletons.budget) {
              const [campaignPlanDTO,
                campaignPlan,
                budgetPlan] = await Promise.all([
                get$res().fetch.skeleton('inc.fluency.core.common.dto.campaign.CampaignPlanDTO'),
                get$res().fetch.skeleton('CampaignPlan'),
                get$res().fetch.skeleton('BudgetPlan')
              ])
              $store.commit('skeletons', {
                skeletons: [
                  { type: 'campaign', skeleton: { campaignPlan, campaignPlanDTO } },
                  { type: 'budget', skeleton: budgetPlan }
                ]
              })
              skeletons = $store.getters.skeletons
            }

            payload = _pick(plan, ...Object.keys(skeletons?.campaign?.campaignPlanDTO ?? {}))
            delete payload.metricsDTO
            payload = {
              ...payload,
              ..._pick(plan, ...Object.keys(skeletons?.campaign?.campaignPlan ?? {}))
            }
            payload.budgetPlan = _pick(plan.budgetPlan || {}, ...Object.keys(skeletons?.budget ?? {}))
          } else if (planType === 'criterion') {
            payload = _pick(plan, ['criterionPlanId', 'adGroupPlanId', 'campaignPlanId', 'accountPlanId', 'criterionType', 'criterionSpec', 'bid', 'status'])
          } else if (planType === 'creative') {
            // payload = {}
            if (!skeletons.creative) {
              const creativePlanSkeleton = await get$res().fetch.skeleton('CreativePlan')
              $store.commit('skeletons', {
                skeletons: [{ type: 'creative', skeleton: creativePlanSkeleton }]
              })
              skeletons = $store.getters.skeletons
            }
            payload = _pick(plan, ...Object.keys(skeletons?.creative ?? {}), 'formPlanId')
          } else if (planType === 'adGroup') {
            if (!skeletons.adGroupDTO || !skeletons.adGroup || !skeletons.criterionPlan) {
              const [adGroupPlanDTO,
                adGroupPlan,
                criterionPlan] = await Promise.all([
                get$res().fetch.skeleton('inc.fluency.core.common.dto.adgroup.AdGroupPlanDTO'),
                get$res().fetch.skeleton('AdGroupPlan'),
                get$res().fetch.skeleton('CriterionPlan')
              ])
              $store.commit('skeletons', {
                skeletons: [
                  { type: 'adGroupDTO', skeleton: adGroupPlanDTO },
                  { type: 'adGroup', skeleton: adGroupPlan },
                  { type: 'criterionPlan', skeleton: criterionPlan }
                ]
              })
              skeletons = $store.getters.skeletons
            }
            if (plan.dynamicProductAudiences) {
              plan.userListPlans = plan.dynamicProductAudiences
            }
            payload = _pick(plan, ...Object.keys(skeletons?.adGroupDTO ?? {}), ...Object.keys(skeletons?.adGroup ?? {}))
            delete payload.metricsDTO
            delete payload.capabilities
            if (plan.negativeKeywords) payload.negativeKeywords = plan.negativeKeywords
            if (plan.criterionPlans) {
              payload.criterionPlans = plan.criterionPlans.map(p => {
                const picked = _pick(p, Object.keys($store.getters.skeletons?.criterionPlan ?? {}), 'criteria')
                if (picked.criterionPlanId < 0) {
                  delete picked.criterionPlanId
                }
                return picked
              })
            }
          } else if (planType === 'account') { // TODO: this check is for modifying skeleton for saving budget modifications -SNG, need a better arch way to handle the account DTO
            const skeleton = _cloneDeep(skeletons[planType] || {})
            skeleton.nameOverrides = { actualTypeArguments: ['inc.fluency.core.common.data.fluent.tables.NameFormationPlan'], rawType: 'java.util.List', typeName: 'java.util.List' }
            skeleton.manualBudgetAdjustments = { actualTypeArguments: ['inc.fluency.core.service.account.AccountPlanService$BudgetPlanAdjustment'], rawType: 'java.util.List', typeName: 'java.util.List<inc.fluency.core.service.account.AccountPlanService$BudgetPlanAdjustment>' }
            skeleton.settings = 'java.util.List'
            skeleton.sharedAccountOverspendProtection = 'java.lang.Boolean' // this is an additional item that should probably be in the skeleton but its in AccountDTO right now - SNG

            payload = _pick(plan, ...Object.keys(skeleton || {}))
          } else if (skeletons[planType]) {
            payload = _pick(plan, ...Object.keys(skeletons[planType] || {}))
          } else {
            payload = plan
          }
          return flujax.set.post(`/${planType.toLowerCase()}/set`, payload)
        }
      },
      async plans (planType, plans) {
        const skeletons = $store.getters.skeletons
        if (planType === 'ad') {
          planType = 'creative'
        }

        const payloads = []
        for (let i = 0; i < plans.length; i++) {
          const plan = plans[i]
          let payload = {}

          if (planType === 'campaign') {
            payload = {
              ..._pick(plan, ...Object.keys(skeletons?.campaign?.campaignPlanDTO ?? {})),
              ..._pick(plan, ...Object.keys(skeletons?.campaign?.campaignPlan ?? {})),
              budgetPlan: _pick(plan.budgetPlan || {}, ...Object.keys(skeletons?.budget ?? {}))
            }
          } else if (planType === 'creativeAdSpec') {
            planType = 'creative'
            payload = _pick(plan, ['creativePlanId', 'accountPlanId', 'campaignPlanId', 'adGroupPlanId', 'creativeType', 'name', 'destinationUrl', 'adSpec', 'status', 'lockedFields'])
          } else if (planType === 'creative') {
            payload = _pick(plan, ...Object.keys(skeletons?.creative ?? {}), 'formPlanId')
          } else if (skeletons[planType]) {
            payload = _pick(plan, ...Object.keys(skeletons[planType] || {}))
          } else {
            payload = plan
          }
          payloads.push(payload)
        }

        return flujax.set.post(`/${planType.toLowerCase()}/setAll`, payloads)
      },
      async destinationUrlForAdGroups (payload) {
        // payload = { adGroupPlanIds: [1,2,3,...], destinationUrl: https://foo.com }
        return flujax.set.post('/adgroup/bulkSetDestinationUrl', payload)
      },
      async budget (budget) {
        if (budget) {
          let budgetSkeleton = $store.getters.skeletons?.budget
          if (!budgetSkeleton) {
            try {
              budgetSkeleton = await flujax.set.post('/object/describeForObject/BudgetPlan')
            } catch (e) {
              return null
            }
          }
          return flujax.set.post('/budget/set', _pick(budget, ...Object.keys(budgetSkeleton || {})))
        }
      },
      async budgetDTO (budgetDTO) {
        if (budgetDTO) {
          const payload = _pick(budgetDTO, ...Object.keys($store.getters.skeletons?.budget ?? {}), 'campaignGroupName', 'budgetPlanAdjustment', 'budgetManagerConfiguration', 'partnerDirectiveList', 'ancillaryUnderspendConfig')
          if (payload.partnerDirectiveList) {
            payload.partnerDirectiveList = payload.partnerDirectiveList
              .map(directive => {
                if (!directive.hasOwnProperty('budgetPlanPartnerDirectiveId')) {
                  return null
                }
                if (directive.budgetPlanPartnerDirectiveId < 0) {
                  return {
                    ...directive,
                    budgetPlanPartnerDirectiveId: null
                  }
                }
                return directive
              })
              .filter(d => !!d)
          }

          return flujax.set.post('/budget/setByDTO', payload)
        }
      },
      async budgetDTOs (budgetDTOs = []) {
        const payloads = []
        budgetDTOs.forEach(budgetDTO => {
          if (budgetDTO) {
            const payload = _cloneDeep(_pick(budgetDTO, ...Object.keys($store.getters.skeletons?.budget ?? {}), 'campaignGroupName', 'budgetPlanAdjustment', 'budgetManagerConfiguration', 'partnerDirectiveList'))

            if (payload.partnerDirectiveList) {
              payload.partnerDirectiveList = payload.partnerDirectiveList.map(directive => {
                if (!directive.hasOwnProperty('budgetPlanPartnerDirectiveId')) {
                  return null
                }
                if (directive.budgetPlanPartnerDirectiveId < 0) {
                  return {
                    ...directive,
                    budgetPlanPartnerDirectiveId: null
                  }
                }
                return directive
              }).filter(d => !!d)
            }

            payloads.push(payload)
          }
        })
        return flujax.set.post('/budget/setByDTOs', payloads)
      },
      async negativeKeywords ({ keywords, campaignPlanId }) {
        return flujax.set.post(`/keyword/negative/setByCampaignPlan/${campaignPlanId}`, keywords)
      },
      // async siteLinks (siteLinks) {
      //   const skeletons = vue.store.getters.skeletons
      //   if (skeletons['siteLink'] && siteLinks && siteLinks.length > 0) {
      //     let payload = siteLinks.map(sl => _.pick(sl, ...Object.keys(skeletons['siteLink'])))
      //     return flujax.set.post('/sitelink/setList', payload)
      //   }
      // },
      async extensions (extensionType, extensions) {
        if (!extensionType) {
          return
        }
        const skeleton = $store.getters.skeletons?.extension?.[`${extensionType}`]

        if (skeleton) {
          const payload = extensions
            .map(ep => {
              const dto = _pick(ep, Object.keys(skeleton || {}))
              if (extensionType.startsWith('priceExtension')) {
                const priceTableRowSkeleton = $store.getters.skeletons.extension.priceExtensionPriceTableRow
                dto.priceExtensionPriceTableRows = ep.priceExtensionPriceTableRows?.map(row => _pick(row, Object.keys(priceTableRowSkeleton || {})))
              }
              return dto
            })
          if (!payload || payload.length === 0) {
            return
          }

          let endpoint = extensionType.startsWith('imageExtension') || extensionType.startsWith('promotion') ? 'setAll' : 'setList'
          let pathType = extensionType.toLowerCase()
          if (pathType.endsWith('plan')) {
            pathType = pathType.slice(0, -4)
          }

          return flujax.set.post(`${pathType}/${endpoint}`, payload)
        }
      },
      async sharedSetsAttachedToCampaign ({ campaignPlanId, sharedSets = [] }) {
        if (sharedSets) {
          const skeletons = $store.getters.skeletons
          if (skeletons.sharedSet) {
            sharedSets = sharedSets.map(set => _pick(set, ...Object.keys(skeletons?.sharedSet ?? {})))
          } else {
            console.warn('SharedSetPlan skeleton does not exist in store. Unable to _.pick against skeleton. Request payload may cause API error')
          }
        }
        return flujax.set.post(`/sharedSet/setAttachedToCampaignPlanId/${campaignPlanId}`, sharedSets)
      },
      async sharedSet (sharedSet) {
        if (sharedSet) {
          const skeletons = $store.getters.skeletons
          if (skeletons.sharedSet) {
            const payload = _pick(sharedSet, ...Object.keys(skeletons?.sharedSet?.sharedSetPlan ?? {}), ...Object.keys(skeletons?.sharedSet?.sharedSetPlanDTO ?? {}))
            return flujax.set.post('/sharedSet/set', payload)
          }
        }
      },
      async list (listData) {
        return flujax.set.post('/list/set?limitResponseSize=true', listData)
      },
      async strategyList (listData) {
        return flujax.set.post('/strategyList/set', listData)
      },
      async subAccount (subAccount) {
        const sub = _pick(subAccount, 'accountPlanId', 'partnerId', 'referenceId', 'accountOverspendStrategy')
        return flujax.set.post('account/registerPartner?validateRelinkElig=true', sub)
      },
      async override (override) {
        return flujax.set.post('/overrides/set', override)
      },
      async deleteOverride (override) {
        return flujax.set.post('/overrides/delete', override)
      },
      async bulkAssignAnalyst ({ userRegistryId, accountPlanIds }) {
        return flujax.set.post(`/account/batchAssignAnalyist?userRegistryId=${userRegistryId}`, accountPlanIds)
      },
      async executeRecommendations (applyAndIgnore) {
        return flujax.set.post('/analysis/executeRecommendations', applyAndIgnore)
      },
      async campaignList (campaignPlans) {
        const skeletons = $store.getters.skeletons
        const payload = campaignPlans.map(plan => {
          const pl = {
            ..._pick(plan, ...Object.keys(skeletons?.campaign?.campaignPlanDTO ?? {})),
            ..._pick(plan, ...Object.keys(skeletons?.campaign?.campaignPlan ?? {})),
            budgetPlan: _pick(plan.budgetPlan || {}, ...Object.keys(skeletons?.budget ?? {}))
          }
          return pl
        })
        return flujax.set.post('campaign/setAll', payload, { timeout: apiTimeoutLong })
      },
      async productPartition (productPartitionPlanDTO, adGroupPlanId) {
        const skeletons = $store.getters.skeletons

        const scrubPlan = (dto) => {
          const scrubbed = _pick(dto, ...Object.keys(skeletons?.productPartition?.productPartitionPlan ?? {}), ...Object.keys(skeletons?.productPartition?.productPartitionPlanDTO ?? {}))
          if (scrubbed.childNodes && scrubbed.childNodes.length > 0) {
            scrubbed.childNodes = scrubbed.childNodes.map(node => scrubPlan(node))
          }
          return scrubbed
        }

        const payload = scrubPlan(productPartitionPlanDTO)
        return flujax.set.post(`/shopping/product-partition/setByAdGroupPlan?adGroupPlanId=${adGroupPlanId}`, payload)
      },
      async productPartitionList (productPartitionPlanDTOArray, adGroupPlanId) {
        const skeletons = $store.getters.skeletons

        const scrubPlan = (dto) => {
          const scrubbed = _pick(dto, ...Object.keys(skeletons?.productPartition?.productPartitionPlan ?? {}), ...Object.keys(skeletons?.productPartition?.productPartitionPlanDTO ?? {}))
          if (scrubbed.childNodes && scrubbed.childNodes.length > 0) {
            scrubbed.childNodes = scrubbed.childNodes.map(node => scrubPlan(node))
          }
          return scrubbed
        }

        const payload = productPartitionPlanDTOArray.map(dto => scrubPlan(dto))
        return flujax.set.post(`/shopping/product-partition/setAllByAdGroupPlan?adGroupPlanId=${adGroupPlanId}`, payload)
      },
      async launchPlan (payload) {
        return flujax.set.post('/launch/set/', payload)
      },
      async launch (payload, params) {
        return flujax.set.post(`/launch/launch/${params}`, payload)
      },
      async audiencePlan (payload) {
        return flujax.set.post('/audience/set', payload)
      },
      async criterionPlans (payload) {
        // .filter(p => p.dirty)
        const pickedPayload = payload.map(p => {
          const picked = _pick(p, [...Object.keys($store.getters.skeletons?.criterionPlan ?? {}), 'criteria'])
          if (picked.criterionPlanId < 0) {
            delete picked.criterionPlanId
          }
          return picked
        })
        return flujax.set.post('/criterion/setAll', pickedPayload)
      },
      async refreshPollingData (payload) {
        const picked = _pick(payload, Object.keys($store.getters.skeletons?.AccountData ?? {}))
        return flujax.set.post('/accountData/execute', picked)
      },
      async refreshCustomerPollingData (payload) {
        const picked = _pick(payload, Object.keys($store.getters.skeletons?.CustomerData ?? {}))
        return flujax.set.post('/customerData/execute', picked)
      },
      async youtubeLink ({ accountPlanId, youtubeChannelId }) {
        return flujax.set.post(`/account/youtube/link?accountPlanId=${accountPlanId}&youtubeChannelId=${youtubeChannelId}`)
      },
      async facebookPageLinkRequest (accountPlanIds = []) {
        return flujax.set.post('/page/link', accountPlanIds)
      },
      async facebookAuthorizedPage ({ accountPlanId, pageReferenceId, name }) {
        return flujax.set.post('/page/attachAuthorized', { accountPlanId, pageReferenceId, name })
      },
      async coachmark (payload) {
        return flujax.set.post('/coachmarks/set', payload)
      },
      async listDataMapping (listDataMapping) {
        return flujax.set.post('/list/mapping/set', listDataMapping)
      },
      async listDataDestinations () {
        return flujax.get('/listData/selector/destinations')
      },
      async removedBlockedKeywords (blockedKeywordDTOs = []) {
        if (blockedKeywordDTOs && blockedKeywordDTOs.length === 0) {
          return []
        }
        return flujax.set.post('/keyword/removeBlockedKeywords', blockedKeywordDTOs)
      },
      async productScopes (productScopes = []) {
        if (!productScopes || productScopes.length === 0) return null
        const payload = productScopes.map(scope => _pick(scope, ...Object.keys($store.getters.skeletons?.productScope ?? {}), 'productDimensionPlans'))
        return flujax.set.post('/shopping/product-scope/setAll', payload)
      },
      async productScope (productScope = {}) {
        const payload = _pick(productScope, ...Object.keys($store.getters.skeletons?.productScope ?? {}), 'productDimensionPlans')
        return flujax.set.post('/shopping/product-scope/set', payload)
      },
      async productCatalogPlan (productCatalogPlan) {
        if (!productCatalogPlan) return null
        if (!$store.getters.skeletons.productCatalog) {
          await $store.dispatch('fetchSkeleton', 'productCatalog')
        }
        const payload = _pick(productCatalogPlan, ...Object.keys($store.getters.skeletons?.productCatalog ?? {}))
        return flujax.set.post('/product-catalog/set', payload)
      },
      async connectProductCatalogPlan ({ accountPlanId, advertisingChannelId, referenceId }) {
        if (!accountPlanId || !advertisingChannelId || !referenceId) {
          return null
        }
        return flujax.set.post(`/product-catalog/connect?accountPlanId=${accountPlanId}&advertisingChannelId=${advertisingChannelId}&referenceId=${referenceId}`)
      },
      async analyzeProductCatalogPlan ({ productCatalogPlanId, accountPlanId }) {
        if (!accountPlanId || !productCatalogPlanId) {
          return null
        }
        return flujax.set.post('/product-catalog/analyze', { productCatalogPlanId, accountPlanId })
      },
      async userListPlans (userListPlanDTO) {
        if (!userListPlanDTO) return null
        const arr = Array.isArray(userListPlanDTO) ? userListPlanDTO : [userListPlanDTO]
        if (arr.length === 0) {
          return null
        } else if (arr.length === 1) {
          return flujax.set.post('/user-list/set', arr[0])
        } else {
          return flujax.set.post('/user-list/setAll', arr)
        }
      },
      async dashboardUserWidget (widget) {
        if (widget) {
          let skeleton = $store.getters.skeletons?.WidgetUserConfig
          if (!skeleton) {
            skeleton = await $store.dispatch('fetchSkeleton', 'WidgetUserConfig')
          }
          const payload = _pick(widget, ...Object.keys(skeleton || {}))
          return flujax.post('dashboard/setWidgetForUser', payload)
        }
      },
      async accountPermission (accountPlanId, partnerId) {
        if (!accountPlanId) {
          return
        }
        return flujax.set.post(`/account/syncPermissions?accountPlanId=${accountPlanId}&partnerId=${partnerId || 3}`)
      },
      async requestPinterestAccount (accountPlanId) {
        if (!accountPlanId) {
          return
        }
        return flujax.set.post(`/account/requestPinterestAccount?accountPlanId=${accountPlanId}`)
      },
      async checkPinterestStatus (accountPlanId) {
        if (!accountPlanId) {
          return
        }
        return flujax.set.post(`/account/checkPinterestAccount?accountPlanId=${accountPlanId}`)
      },
      async sitecrawlerConfig (payload) {
        return flujax.set.post('/sitecrawler/config/set', payload)
      },
      async accountsBatch (accountRequest) {
        return flujax.set.post('/account/batchSet', accountRequest)
      },
      async customerData (customerData) {
        let skeleton = $store.getters.skeletons?.CustomerData
        if (!skeleton) {
          skeleton = await $store.dispatch('fetchSkeleton', 'CustomerData')
        }
        const payload = _pick(customerData, ...Object.keys(skeleton || {}))
        return flujax.set.post('/customerData/set', payload)
      },
      async quickPromotePosts ({ accountPlanId, adGroupPlanId, promotedPosts }) {
        return flujax.set.post('/creative/promotePosts', { accountPlanId, adGroupPlanId, promotedPosts })
      },
      async youTubeChannel (youtubeVideoObjects) {
        return flujax.set.post('/youtube/setAll', youtubeVideoObjects)
      },
      async promotion (promotion) {
        if (promotion) {
          const promoDto = _pick(promotion, 'accountPromotionId', 'accountPlanId', 'title', 'validFrom', 'validTo', 'removed', 'assets')
          if (promoDto.accountPromotionId < 0) {
            delete promoDto.accountPromotionId
            if (promoDto.removed) return
          }
          if (promoDto.assets?.length > 0) {
            promoDto.assets.forEach(asset => {
              if (asset.accountPromotionAssetId < 0) {
                delete asset.accountPromotionAssetId
              }
              if (asset.accountPromotionId < 0) {
                delete asset.accountPromotionId
              }
            })
            promoDto.assets = promoDto.assets.map(asset => _pick(asset, 'accountPromotionAssetId', 'accountPromotionId', 'assetType', 'assetValue', 'removed'))
              .filter(asset => asset.accountPromotionAssetId || !asset.removed)
          }
          return await flujax.fetch.post('/promotion/set', promoDto)
        }
      },
      async trackingCode (trackingCode) {
        if (trackingCode && trackingCode.accountPlanId) {
          return await flujax.set.post('/tracking-code/set', trackingCode)
        }
      },
      async newTrackingCode ({ accountPlanId, partnerId }) {
        if (accountPlanId && partnerId) {
          return await flujax.set.post(`/tracking-code/create?accountPlanId=${accountPlanId}&partnerId=${partnerId}`)
        }
      },
      async setBidStrategy (campaignPlanDTO) {
        return flujax.set.post('/recommendation/account/peers/bid/set', campaignPlanDTO)
      },
      async addNegativeKeyword (keywords) {
        return flujax.set.post('recommendation/account/peers/adgroup/negativeKeyword/add', keywords)
      },
      async instantExperienceTemplate (instantExperienceTemplate) {
        return await flujax.set.post('/instant-experience-templates/set', instantExperienceTemplate)
      },
      async instantExperience (instantExperience) {
        return await flujax.set.post('/instant-experiences/set', instantExperience)
      },
      async setDomainLists (accountPlanId, campaignPlanId, lists) {
        return flujax.set.post(`/dsp/${accountPlanId}/${campaignPlanId}/domains`, lists)
      },
      async conversionActions (conversionActionPlanDtos = []) {
        if (conversionActionPlanDtos.length === 0) {
          return []
        }
        const payload = conversionActionPlanDtos.map(dto => ({
          ...dto,
          actionSpec: typeof dto.actionSpec !== 'string' ? JSON.stringify(dto.actionSpec) : dto.actionSpec
        }))
        payload.forEach(dto => {
          delete dto._dirty
          delete dto._showDetails
        })
        return flujax.set.post('/conversion-actions/set', payload)
      }
    },
    blueprints: Blueprints(flujax),
    adTemplate: AdTemplate(flujax),
    forum: Forum(flujax),
    budget: Budget(flujax),
    reporting: Reporting(flujax),
    demo: Demo(flujax),
    suggest: Suggest(flujax),
    colab: Colab(flujax),
    customer: Customer(flujax),
    account: Account(flujax),
    audience: Audience(flujax),
    authV2: AuthV2(flujax),
    user: User(flujax),
    apiDoc: ApiDoc(flujax),
    ai: Ai(flujax),
    dsp: DSP(flujax),
    testing: Testing(flujax),
    localServiceAds: LocalServiceAds(flujax),
    tree: Tree(flujax)
  }
}

const apiUrl = async function (vueApp) {
  const resolveLocalHost = ($store) => {
    let host = ''
    if (window.location.href.indexOf('fsEnv=ip') > -1 && window.location.href.indexOf('ip=') > -1) {
      const urlParams = new URLSearchParams(window.location.search)
      const ip = urlParams.get('ip')
      host = `//${ip}:8080/api`
    } else if (window.location.href.indexOf('fsEnv=local') > -1) {
      $store.commit('setLocalDev', true)
      host = 'http://localhost:8080/api'
    } else if (window.location.href.indexOf('fsEnv=canary') > -1) {
      host = 'https://api-canary.fluencyinc.co/api'
    } else if (window.location.href.indexOf('fsEnv=live') > -1) {
      host = 'https://api.fluencyinc.co/api'
    } else {
      if ($store.getters.isLocalDev) {
        host = 'http://localhost:8080/api'
      } else {
        // These should always be https ... shouldn't we just force it here?
        host = 'https://api-dev.fluencyinc.co/api'
      }
    }
    return host
  }

  const testLocalServer = async () => {
    let retVal = false
    try {
      const response = await axios.get('http://localhost:8080/api/backpack/localserver')
      if (response) {
        retVal = (response.status === 200)
      }
    } catch (e) {
    }
    return retVal
  }

  const hn = window.location.hostname
  const $store = vueApp.config.globalProperties.$store
  const $authn = vueApp.config.globalProperties.$authn

  if (['localhost', 'backpack-staging.fluencyinc.co', 'backpack-staging-vue3.fluencyinc.co'].includes(hn)) {
    if (!$store.getters.isLocalDev) {
      $store.commit('setLocalDev', await testLocalServer())
    }
    return resolveLocalHost($store)
  } else {
    return $authn.apiOrigin + '/api'
  }
}

export const fluentResourceNuxtInstall = async nuxtApp => {
  const $axios = axios.create({
    baseURL: await apiUrl(nuxtApp.vueApp),
    withCredentials: false
  })
  const { $store, $authn } = nuxtApp.vueApp.config.globalProperties
  const $router = useRouter()
  // nuxtApp.vueApp.config.globalProperties.$route = $route

  $axios.interceptors.request.use(
    async (config) => {
      if ($store.getters.actingAsUser) {
        config.headers['Customer-Actor-Id'] = $store.getters.actingAsUser
      }
      if ($authn.isAuthenticated()) {
        config.headers.Authorization = `Bearer ${$authn.getAccessToken()}`
        config.headers['X-Backpack-Path'] = unref($router.currentRoute).path
        config.headers['X-Backpack-FullPath'] = unref($router.currentRoute).fullPath
      }
      return config
    })

  nuxtApp.vueApp.config.globalProperties.$res = fluentResource(nuxtApp, $axios)
  nuxtApp.provide('res', nuxtApp.vueApp.config.globalProperties.$res)
}
