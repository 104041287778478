<template>
  <div>
    <span class="d-none" v-shortkey="['meta', 'shift', 'j']" @shortkey="handleShortKey()"></span>
    <b-modal v-model="modalOpen"
             id="jumper-modal"
             size="med"
             body-class="overflow-visible"
             hide-header
             hide-footer
             @hidden="userInput = ''"
             @shown="focusInput()">
      <b-form-input v-model="userInput"
                    placeholder="Jump To..."
                    :debounce="200"
                    :tabindex="600"
                    size="lg"
                    class="jumper-modal-input"
                    :class="{'displaying-content': userInput.length > 2}"
                    @keyup.enter="handleEnter()"
                    @keyup.down="handleDown()"></b-form-input>
      <jumper-content ref="jumperContent" :user-input="userInput" @navigated="closeModal()"></jumper-content>
    </b-modal>
  </div>
</template>

<script>
import JumperContent from './jumperContent.vue'
export default {
  name: 'jumper2',
  components: { JumperContent },
  data () {
    return {
      modalOpen: false,
      userInput: ''
    }
  },
  methods: {
    handleEnter () {
      this.$refs.jumperContent.goToFirstLink()
    },
    handleDown () {
      this.$refs.jumperContent.adjustFocus(1)
    },
    handleShortKey () {
      this.modalOpen = true
    },
    closeModal () {
      this.modalOpen = false
    },
    focusInput () {
      this.$nextTick(() => {
        const elem = document.querySelector('.jumper-modal-input')
        if (elem) {
          elem.focus()
        }
      })
    },
    openModal () {
      this.modalOpen = true
    }
  },
  mounted () {
    this.$eventBus.$on('openAccountJump', this.openModal)
  },
  beforeUnmount () {
    this.$eventBus.$off('openAccountJump', this.openModal)
  }
}
</script>
<style lang="scss">
#jumper-modal {
  .modal-content {
    background: transparent;
    border: none;
  }
  .jumper-modal-input.displaying-content {
    border-radius: 0.3rem 0.3rem 0 0;
  }
  .jumper-content {
    border-radius: 0 0 0.3rem 0.3rem;
    border: 1px solid $border-color;
    border-top: none;
  }
}
</style>
