<template>
  <div class="link-generator d-flex">
    <t-button-inline class="mx-2" severity="secondary" size="large"
            v-p-tooltip.top="{ value: 'Generate a CoLab Invite' }"
            @click="openModal"><fluency-icon type="envelopeClosed" size="lg"/></t-button-inline>
    <b-modal v-if="showModal" :visible="showModal" @update:visible="showModal = $event" size="sm" centered id="linkGeneratorModal">
      <template #modal-title>
        <h3>Generate a CoLab Invite</h3>
        <h5>for {{value.name}}</h5>
      </template>

      <template v-if="loading">
        <fluency-loader class="py-5" dots color />
      </template>

      <template v-else-if="askForEmailAndSpec">
        <div v-if="generatedSelfLink.specialKey">
          <p>
            Here's your personal CoLab link to this account. You own this link and pin. It is tied to your email address.

          </p>
          <p>
            <a :href="selfLinkUrl" target="_blank">{{selfLinkUrl}}</a>
          </p>
          <b-form-group label="PIN">
            <b-input-group>
              <b-form-input :value="generatedSelfLink.pin" readonly/>
              <b-input-group-append>
                <copy-to-clipboard :val="generatedSelfLink.pin"
                                   text="Copy"
                                   variant="outline-secondary"
                                   btn-style="border-bottom-left-radius: 0; border-top-left-radius: 0" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <hr />
        </div>
        <p>
          Send an email inviting others to collaborate on advertising strategy for this account. Each email recipient receives their own personalized link and pin.
        </p>
        <b-form-group label="Recipient(s)" class="colab-emails">
          <div class="mad-lib-container">
            <mad-lib-input :value="users" @input="users = $event" disable-auto-focus placeholder="Enter Emails"></mad-lib-input>
          </div>

          <small class="text-muted mt-2 d-block">The recipient(s) will be invited to CoLab, a virtual, shared working environment.
            <a v-p-tooltip.top="{ value: 'Learn More', showDelay: 10 }"
                    href="https://help.fluency.inc/"
                    target="_blank">
              Learn More <fluency-icon type="linkOut" />
            </a>
          </small>
        </b-form-group>

        <b-form-group v-if="availableSpecs.length > 1" label="CoLab Options">
          <b-form-select v-model="specLabel"
                         :options="availableSpecs.map(spec => spec.label)" />
        </b-form-group>
      </template>

      <template v-else-if="showLinkAndPin">
        <div class="text-center">
          <fluency-icon class="lg round hollow success mb-3 bg-transparent" type="apply"></fluency-icon>
        </div>
        <p>{{users.join(', ')}} {{users.length > 1 ? 'have' : 'has'}} been invited to CoLab, a virtual, shared working environment where they can collaborate on advertising intentions and ideas for {{value.name}}.</p>
        <p>Below is the link and a <b>read-only</b> PIN, giving you viewing access to their CoLab environment.</p>
        <p>
          <a :href="url" target="_blank">{{url}}</a>
        </p>
        <b-form-group label="Read-Only PIN">
          <b-input-group>
            <b-form-input :value="generatedLink.viewOnlyPin"/>
            <b-input-group-append>
              <copy-to-clipboard :val="generatedLink.viewOnlyPin"
                                 text="Copy"
                                 variant="outline-secondary"
                                 btn-style="border-bottom-left-radius: 0; border-top-left-radius: 0" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </template>

      <template #modal-footer>

        <b-button ref="closeBtn" variant="secondary" @click="showModal = false">Close</b-button>
        <b-button v-if="showLinkAndPin" @click="reset">Add More Users</b-button>
        <b-button v-if="askForEmailAndSpec"
                  variant="primary"
                  :disabled="users.length === 0"
                  @click="generateKey">Generate</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import CopyToClipboard from '@/components/common/copyToClipboard'
import MadLibInput from 'core-ui/components/inputs/madLibInput.vue'
export default {
  name: 'linkGenerator',
  components: { CopyToClipboard, FluencyLoader, MadLibInput },
  props: ['value'],
  data () {
    return {
      users: [],
      user: '',
      generatedSelfLink: {},
      generatedLink: {},
      showModal: false,
      loading: false,
      specLabel: 'Default'
    }
  },
  async mounted () {

  },
  computed: {
    url () {
      let subDomain = 'colab'
      if (window.location.hostname.includes('canary')) {
        subDomain = 'canary'
      }
      return `https://${subDomain}.ad-strategy.com?key=${this.generatedLink?.specialKey}`
    },
    selfLinkUrl () {
      let subDomain = 'colab'
      if (window.location.hostname.includes('canary')) {
        subDomain = 'canary'
      }
      if (this.generatedSelfLink?.specialKey) {
        return `https://${subDomain}.ad-strategy.com?key=${this.generatedSelfLink.specialKey}`
      }
      return ''
    },
    availableSpecs () {
      return this.$store.getters.questionnaireSpecs ?? []
    },
    askForEmailAndSpec () {
      return !this.generatedLink.specialKey
    },
    showLinkAndPin () {
      return !this.loading && this.generatedLink.specialKey
    }
  },
  methods: {
    async generateKey () {
      this.loading = true
      for (const user of this.users) {
        const response = await this.$res.colab.generateQuestionnaireKey({
          accountPlanId: this.value.accountPlanId,
          user,
          label: this.specLabel,
          sendEmail: true,
          previewSpecData: null
        })
        if (!response) {
          this.$toast('Error generating link. Please try again.')
        } else if (!this.generatedLink.specialKey) {
          this.generatedLink = response
        }
      }
      this.loading = false
      if (this.$refs.closeBtn) {
        this.$refs.closeBtn.focus()
      }
    },
    async generateSelfKey () {
      this.loading = true
      const response = await this.$res.colab.generateQuestionnaireKey({
        accountPlanId: this.value.accountPlanId,
        user: this.$store.getters.user.userName,
        label: this.specLabel,
        sendEmail: false,
        previewSpecData: null
      })
      if (response) {
        this.generatedSelfLink = response
      }
      this.loading = false
    },
    reset () {
      this.generatedLink = {}
      this.users.splice(0, this.users.length)
    },
    async openModal () {
      this.showModal = true
      if (!(this.$store.getters.questionnaireSpecs?.length > 0)) {
        await this.$store.dispatch('loadQuestionnaireSpecs')
      }
      await this.generateSelfKey()
    }
  },
  watch: {
    value () {
      this.reset()
    }
  }
}
</script>

<style lang="scss">
#linkGeneratorModal.modal {
  display: block;
}
</style>
