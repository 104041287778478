<template>
    <t-list-group>
      <t-list-group-item variant="light">
        <div class="d-flex align-items-center justify-content-between">
          <b-form-checkbox name="selectAll"
                           :checked="allSelected"
                           :indeterminate="indeterminate" @change="allSelected = $event; toggleAll($event)">
            Select all
          </b-form-checkbox>
          <h5 class="px-5 mb-0">
            <slot name="label">{{label}}</slot>
          </h5>
          <div :style="`visibility: ${selectedDrafts.length > 0 ? 'visible' : 'hidden'}`">
            <b-button variant="primary" class="my-0" @click="publish()">
              <fluency-icon type="publish"/>
              Publish Drafts
              <b-badge  variant="secondary" pill class="text-white pb-2" >{{selectedDrafts.length}}</b-badge>
            </b-button>
          </div>
        </div>
      </t-list-group-item>

      <t-list-group-item v-for="(message) in draftBroadcastsDisplay"
                         :key="message"
                         class="d-flex justify-content-between py-2">
        <div class="d-flex">
          <b-form-checkbox name="selectedDrafts" :checked="selectedDrafts" :value="message" @input="selectedDrafts = [...$event]">
          <span>{{message}}</span>
          </b-form-checkbox>
        </div>
        <div>
          <b-dropdown no-caret toggle-class="p-0" lazy>
            <template #button-content>
              <b-badge variant="secondary">DRAFT</b-badge>
            </template>
            <b-dropdown-item variant="primary" @click="publish(message)">
              <fluency-icon type="publish" /> Publish
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </t-list-group-item>
    </t-list-group>
</template>

<script>
export default {
  name: 'broadcastPublishTool',
  props: {
    draftBroadcasts: {
      type: Array
    },
    label: {
      type: String,
      default: 'Draft Broadcasts'
    }
  },
  data () {
    return {
      selectedDrafts: [],
      indeterminate: false,
      allSelected: false
    }
  },
  watch: {
    selectedDrafts (newVal, oldVal) {
      if (newVal.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newVal.length === this.draftBroadcastsDisplay.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },
  computed: {
    draftBroadcastsDisplay () {
      return this.draftBroadcasts.filter(broadcast => !broadcast.removed)
        .map(broadcast => broadcast.creativeMessage)
        .distinct()
    }
  },
  methods: {
    // getDraftsByMessage (message) {
    //   return
    // },
    // getLinkToBroadcast ()
    toggleAll (checked) {
      this.selectedDrafts = checked ? this.draftBroadcastsDisplay.slice() : []
    },
    publish (message) {
      let toPublish = []
      if (!message && !(message?.length === 0)) {
        if (this.selectedDrafts.length > 0) {
          toPublish = this.draftBroadcasts.filter(broadcast => this.selectedDrafts.includes(broadcast.creativeMessage))
        }
      } else {
        toPublish = this.draftBroadcasts.filter(broadcast => broadcast.creativeMessage === message)
      }
      if (toPublish.length > 0) {
        this.$emit('publish', toPublish)
        this.selectedDrafts = []
      }
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
