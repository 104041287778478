import moment from 'moment'
import _pick from 'lodash.pick'
import _pull from 'lodash.pull'
import _flatten from 'lodash.flatten'
import _isEqual from 'lodash.isequal'
import { replaceImagePaths } from '@/mixins/imagePathMixin'
import { DefaultPreferences } from '~/assets/js/constants'
import { deepSpread } from 'core-ui/utils/deepSpread'
import { addLocks, removeLocks } from '@/components/common/lockedFieldV3'
import { getNavigationForUser } from '../assets/js/constants/navigation'
import { useAccountsById } from '@/composables/useAccountsById'
import { useActiveItemAncestors } from '@/composables/useActiveItemAncestors'
import { usePartnerPolling } from '../composables/usePartnerPolling'
import _cloneDeep from 'lodash.clonedeep'

const bpToFsType = bp => {
  switch (bp) {
    case 'account':
      return 'AccountPlan'
    case 'budget':
    case 'budgetPlan':
      return 'BudgetPlan'
    case 'campaign':
      return 'CampaignPlan'
    case 'adGroup':
    case 'adgroup':
      return 'AdGroupPlan'
    case 'keyword':
      return 'KeywordPlan'
    case 'ad':
    case 'creative':
      return 'CreativePlan'

    case 'siteLink':
      return 'SiteLinkPlan'
    case 'structuredSnippet':
      return 'StructuredSnippetPlan'
    case 'callout':
      return 'CalloutPlan'
    case 'callExtension':
      return 'CallExtensionPlan'
    case 'priceExtension':
      return 'PriceExtensionPlan'
    case 'imageExtension':
      return 'ImageExtensionPlan'
    default:
      return bp
  }
}

const getMetricsDateParams = ({ getters }, parameters) => {
  const [startDateTime, endDateTime] = getters.timeRange
  let params = (parameters) || '?'

  if (startDateTime && endDateTime) {
    params += '&metricsStart=' + moment(startDateTime).format('YYYY-MM-DD')
    params += '&metricsEnd=' + moment(endDateTime).format('YYYY-MM-DD')
  }
  if (getters.timeRangeCompare) {
    params += '&compareWithPrevious=true'
  }

  return params
}

const fetchActiveItem = async function fetchActiveItem ({ commit, dispatch, getters }, { planType, id, settings }) {
  let plans = []
  let plan = {}
  commit('loading', {
    ...getters.loading,
    activeItem: true
  })

  const params = getMetricsDateParams({ getters }, '?') // await dispatch('getMetricsDateParams', '?')
  if (planType === 'budget') {
    let budget = {}
    budget = await this.$res.budget.getDetail({ budgetPlanId: id }, params)

    plan = {
      accountPlanId: budget?.accountPlanId,
      budgetPlan: budget,
      ...budget
    }
  } else {
    plans = await this.$res.fetch.plan(planType, { [`${planType}PlanId`]: id }, params)
    if (plans && Array.isArray(plans) && plans.length > 0) {
      plan = plans[0]
    } else if (plans) {
      plan = plans
    }
  }

  if (plan) {
    if ((!getters.activeAccountBudgets || getters.activeAccountBudgets.length === 0 || getters.activeAccountBudgets[0].accountPlanId !== plan.accountPlanId) && plan.accountPlanId) {
      // purposely not awaiting
      fetchAccountBudgets.call(this, { commit, getters }, { accountPlanId: plan.accountPlanId })
    }

    if (planType === 'account') {
      if (plan?.accountPlanId) {
        const response = await this.$res.fetch.planChildren('account', [{ accountPlanId: plan.accountPlanId }], params)

        if (response) {
          const children = response[plan.accountPlanId]
          if (children) plan.children = children
        }
        getters.accountsById?.set(plan.accountPlanId, plan)
      }
      // else {
      // matt - taking out this bugsnag for now. no one complains about this, and we think its a probably a race condition
      // this.$bugsnag(new Error('Bad Request'), {
      //   metadata: { requestData: 'No Account Plan ID for getChildren call' }
      // })
      // }
    }

    plan.planType = planType
    const item = settings ? 'settingsItem' : 'activeItem'
    if (getters[item] && getters[item].planType === planType && getters[item][`${planType}PlanId`] === plan[`${planType}PlanId`]) {
      commit(item, {
        ...getters[item],
        ...plan,
        ...(plan.metricsDTO ? plan.metricsDTO : {})
      })
    } else {
      commit(item, plan)
    }
  }
  commit('loading', {
    ...getters.loading,
    activeItem: false
  })
}

const fetchActiveItemMetrics = async function ({ getters, commit }, { planType }) {
  const id = getters.activeItem[`${planType}PlanId`]

  const metricsMap = new Map()
  metricsMap.set(id, getters.activeItem)

  // check for when budget is active and use account in that case instead of budget to get upper metrics panel
  await fetchMetrics.call(this, { getters, commit }, { plansById: metricsMap, fetchType: planType })

  const activeMetrics = metricsMap.get(id)
  delete activeMetrics.startDate
  delete activeMetrics.endDate
  delete activeMetrics.criterionPlans
  return {
    ...getters.activeItem,
    ...metricsMap.get(id),
    metricsDTO: { ...metricsMap.get(id) }
  }
}

const fetchAccountBudgets = async function fetchAccountBudgets ({ commit, getters }, { accountPlanId }) {
  if (!accountPlanId) {
    throw Error('Cannot fetch account budgets without an accountPlanId')
  }
  const params = getMetricsDateParams({ getters }, '?')

  commit('loading', {
    ...getters.loading,
    budget: true
  })

  const response = await this.$res.budget.get({ accountPlanId }, params)
  let activeBudgets = []
  if (response) {
    activeBudgets = response.map(budget => {
      const cellVariant = {}
      if (budget && budget.budgetUnderspendStatus) {
        if (budget.budgetUnderspendStatus.hasMatchKeywordsApplied) {
          cellVariant.useBroadMatchOnUnderspend = 'success'
        }
        if (budget.budgetUnderspendStatus.hasBidAdjustmentsApplied) {
          cellVariant.bidAdjustmentPercentageOnUnderspend = 'success'
        }
        if (budget.budgetUnderspendStatus.hasTargetingAdjustmentsApplied) {
          cellVariant.geoTargetExpansionAbsoluteMaxInMiles = 'success'
        }
        if (budget.budgetUnderspendStatus.hasOpenAdSchedule) {
          cellVariant.openAdSchedulesOnUnderspend = 'success'
        }
        if (budget.budgetUnderspendStatus.hasNegativeKeywordMitigationApplied) {
          cellVariant.autoRemoveBlockingKeywordOnUnderspend = 'success'
        }
      }
      return {
        ...budget,
        ...budget.metricsDTO,
        _showDetails: false,
        _cellVariants: cellVariant
      }
    })
  }

  commit('table/appliedFiltersHash', '')
  commit('setActiveBudgets', activeBudgets)
  commit('loading', {
    ...getters.loading,
    budget: false
  })
}

const fetchMetrics = async function fetchMetrics ({ getters, commit }, { plansById, fetchType, includeTotals }) {
  const [startDateTime, endDateTime] = getters.timeRange
  if (startDateTime && endDateTime && plansById && plansById.size > 0) {
    commit('loading', {
      ...getters.loading,
      [`${fetchType}Metrics`]: true
    })
    const startDateTimeISO = moment(startDateTime).format('YYYY-MM-DDT00:00:00')
    const endDateTimeISO = moment(endDateTime).format('YYYY-MM-DDT23:59:59')
    const channelGroupsFilter = getters.channelGroupsFilter || []

    let params = ''

    params += includeTotals ? 'includeTotals=true&' : ''

    if (channelGroupsFilter?.length) {
      const idList = channelGroupsFilter.join(',')
      params += `channelIds=${idList}&`
    }

    if (params) {
      params = `?${params}`
    }
    let metrics = await this.$res.fetch.metrics({
      objectType: bpToFsType(fetchType),
      objectIds: Array.from(plansById.keys()),
      startDateTime: startDateTimeISO,
      endDateTime: endDateTimeISO
    }, params)

    let previousMetrics = {}
    let previousMetricsMap = {}
    const timeRangeCompare = getters.timeRangeCompare

    if (timeRangeCompare) { // grab previous metrics
      const payload = {
        objectType: bpToFsType(fetchType),
        objectIds: Array.from(plansById.keys()),
        startDateTime: startDateTimeISO,
        endDateTime: endDateTimeISO
      }

      // OVERRIDES: compare a stored default value for prior date with the and only append if different.
      if (getters.timeRangeCompareOverride?.start || getters.timeRangeCompareOverride?.end) {
        const overrideStart = getters.timeRangeCompareOverride?.startOverride
        const overrideEnd = getters.timeRangeCompareOverride?.endOverride
        const defaultStart = getters.timeRangeCompareOverride?.start
        const defaultEnd = getters.timeRangeCompareOverride?.end
        if (overrideStart || overrideEnd) {
          payload.priorStartDateTime = moment(overrideStart || defaultStart).format('YYYY-MM-DDT00:00:00')
          payload.priorEndDateTime = moment(overrideEnd || defaultEnd).format('YYYY-MM-DDT00:00:00')
        }
      }

      previousMetrics = await this.$res.fetch.previousMetrics(payload, params)
      if (previousMetrics) {
        previousMetricsMap = previousMetrics.toMap(metric => metric.objectId)
      }
    }
    if (includeTotals) {
      if (metrics && metrics.Totals) {
        const { total, channel, partner, previousTotals } = getters['table/accountTotals'] || {}
        const newTotals = {
          channel: deepSpread(channel, metrics.ChannelTotals),
          partner: deepSpread(partner, metrics.PartnerTotals),
          total: {
            ...total,
            ...metrics.Totals
          },
          previousTotals: deepSpread(previousTotals, metrics.PreviousTotals)
        }
        commit('table/accountTotals', newTotals)
      }
      if (metrics && metrics.Metrics) {
        metrics = metrics.Metrics
      }
    }
    if (metrics) {
      try {
        metrics.forEach((item, count) => {
          // idk if this is the right solution but this is colliding with campaign.startDate
          // which can be different, and we don't actually use these values outside vuex.timeRange
          delete item.startDate
          delete item.endDate

          if (timeRangeCompare) {
            plansById.set(item.objectId, {
              ...plansById.get(item.objectId),
              ...item,
              previousMetricsDTO: previousMetricsMap[item.objectId]
            })
          } else {
            plansById.set(item.objectId, {
              ...plansById.get(item.objectId),
              ...item,
              _showDetails: false,
              children: undefined
            })
          }
        })
      } catch (e) {
        this.$bugsnag(e)
      }
    }

    commit('loading', {
      ...getters.loading,
      [`${fetchType}Metrics`]: false
    })
  }
}

// returns only the ancestors of active item
// looks at the current ancestors to determine what needs to be fetched from the API
const getAncestorsOfActiveItem = async function getAncestorsOfActiveItem ({ getters, commit }) {
  const activeItem = getters.activeItem
  if (!activeItem || !Object.keys(activeItem).length) {
    return
  }

  const { activeCampaign, activeAdGroup } = useActiveItemAncestors()

  const planType = activeItem.planType
  if (planType === 'account') {
    // do nothing
  } else if (planType === 'budget' || planType === 'form') {
    // do nothing
  } else if (planType === 'campaign' && (!activeItem.account || activeItem.account.accountPlanId !== activeItem.accountPlanId)) {
    return {
      budgetPlan: activeItem.budgetPlan
    }
  } else if (planType === 'adGroup' && (!activeItem.campaign || activeItem.campaign.campaignPlanId !== activeItem.campaignPlanId)) {
    const campaignPlan = activeCampaign.value?.campaignPlanId === activeItem.campaignPlanId
      ? [activeCampaign.value]
      : await this.$res.fetch.plan('campaign', { campaignPlanId: activeItem.campaignPlanId })
    if (campaignPlan && campaignPlan[0]) {
      return {
        budgetPlan: campaignPlan[0].budgetPlan,
        campaignPlan: campaignPlan[0]
      }
    }
  } else if (!activeItem.adGroup || activeItem.adGroup.adGroupPlanId !== activeItem.adGroupPlanId) {
    const results = await Promise.all([
      activeCampaign.value?.campaignPlanId === activeItem.campaignPlanId ? [activeCampaign.value] : this.$res.fetch.plan('campaign', { campaignPlanId: activeItem.campaignPlanId }),
      activeAdGroup.value?.adGroupPlanId === activeItem.adGroupPlanId ? [activeAdGroup.value] : this.$res.fetch.plan('adGroup', { adGroupPlanId: activeItem.adGroupPlanId })
    ])

    return {
      budgetPlan: results[0] ? results[0][0].budgetPlan : null,
      campaignPlan: results[0] ? results[0][0] : null,
      adGroupPlan: results[1] ? results[1][0] : null
    }
  }
  return {}
}

const loadAccountDetails = async function loadAccountDetails ({ getters, commit }, { forceReload, skipMetrics, overwrite }) {
  const { accountsByIdRef } = useAccountsById()
  const noAccounts = !accountsByIdRef.value || accountsByIdRef.value.size === 0
  const noMetrics = !skipMetrics && (!accountsByIdRef || Array.from(accountsByIdRef.value.values()).some(e => !e.hasOwnProperty('impressions') && (!e.hasOwnProperty('metricsDTO') || !e.metricsDTO.hasOwnProperty('impressions'))))
  const channelGroupsFilter = getters.channelGroupsFilter || []
  if (forceReload || noAccounts || noMetrics) {
    commit('loading', { ...getters.loading, accountDetails: true })

    let params = !skipMetrics ? getMetricsDateParams({ getters }, '?') : ''

    if (typeof getters.activeSegment?.savedSegmentId !== 'undefined') {
      if (params.length > 3) {
        params += '&'
      } else {
        params = '?'
      }
      params += `segmentId=${getters.activeSegment.savedSegmentId}`
    }

    params += `${params.includes('?') ? '&' : '?'}includeTotals=true`

    if (channelGroupsFilter?.length) {
      const idList = channelGroupsFilter.join(',')
      params += `${params.includes('?') ? '&' : '?'}channelIds=${idList}`
    }

    const payload = {}
    if (getters.activeSegment?.criteriaJson) {
      payload.criteriaJson = getters.activeSegment.criteriaJson
    }
    const response = await this.$res.fetch.accountDetails(params, payload)
    if (response && response.AccountPlans) {
      const planKeys = Object.keys(getters.skeletons?.account ?? {})
      const pickKeys = [...planKeys, 'accountPlanId', 'name', 'status', 'campaignCount', 'soundBytes', 'soundBytesMonthOverMonth', 'readOnlyMode',
        'primaryDomain', 'facebookPageId', 'pendingWork', 'partnerId', 'partners', 'subAccounts', 'ownerUuid', 'servingStatus',
        'budget', 'accountHold', 'primaryUserRegistryId', 'assignedUserName', 'healthScore', 'approvedAccountBudget', 'accountBudgetAdjustment',
        'nextApprovedAccountBudget', 'currencyCode', 'conservativeBudgetIncrease', 'spendYesterday', 'pacing', 'lifetimeBudget',
        'lifetimeCost', 'totalLifetimeCost', 'labels', 'subAccounts', 'grade', 'metricsDTO', 'previousMetricsDTO', 'sharedApprovedAccountBudget'].distinct()
      const accountsById = new Map(response.AccountPlans
        .map(a => _pick(a, pickKeys))
        .map(a => [a.accountPlanId, {
          ...a,
          ...(a.metricsDTO?.accountPlanId ? a.metricsDTO : null)
        }]))
      commit('setAccountsById', accountsById)
    }

    if (response && response.Totals) {
      commit('table/accountTotals', {
        channel: response.ChannelTotals,
        partner: response.PartnerTotals,
        total: response.Totals,
        previousTotals: response?.PreviousTotals
      })
    }
  }
  commit('loading', {
    ...getters.loading,
    accountDetails: false
  })
}

export default {
  // initial population of store, until we turn of --spa we can't use `nuxtServerInit`
  async init ({ commit, dispatch, getters }) {
    if (getters.partners.length === 0 || getters.verticals.length === 0 || getters.advertisingChannels.length === 0 || !(getters.customerState?.customerId) || !(getters.customer?.customerId)) {
      await Promise.all([
        dispatch('fetchPartners'),
        dispatch('fetchVerticals'), // this is a special call for Eric, we like to call this a lot, we should put this in twice.
        dispatch('fetchAdvertisingChannels'),
        dispatch('customerState'),
        dispatch('customer'),
        dispatch('fetchUserSavedSegments', { forceFetch: true })
      ])
    }
    dispatch('loadDefaultUserPrefs')

    commit('init', true)
  },

  async loadDefaultUserPrefs ({ dispatch }) {
    dispatch('loadUserPref_MetricsBar')
  },
  async loadUserPref_MetricsBar ({ commit, dispatch, getters }) {
    const m = getters.metricsBarItems

    if (m === null) {
      const result = await this.$res.fetch.userPreference('METRICS_BAR', 'items')
      const defaultSet = DefaultPreferences.metricsBarItems
      let items = []

      if (result && result?.preferenceValue) {
        items = result?.preferenceValue.split(',')
      } else {
        items = [...defaultSet]
      }

      dispatch('setMetricsBarFull', { payload: items, write: false })
    }
  },

  async initManage ({ commit, dispatch, getters }, { route, previousRoute, params, query }) {
    let planType = null
    let currentLoading = {
      ...getters.loading,
      campaign: true,
      adGroup: true,
      keyword: true,
      creative: true,
      budget: (route.query.table === 'budget' || params.manageType === 'budget'),
      extension: true,
      accountDetails: false,
      activeItem: false,
      criterion: true
    }
    commit('loading', { ...currentLoading })

    if (!getters.reportDefinitions || getters.reportDefinitions.length === 0) {
      dispatch('fetchReportDefinitions')
    }

    // load all accountsById with metrics if not already loaded
    loadAccountDetails.call(this, { getters, commit }, {}) // don't wait on account details to finish before loading active item

    if (route.name === 'manage-manageType') {
      planType = params.manageType
      if (params.manageType === 'adgroup') {
        planType = 'adGroup'
      } else if (params.manageType === 'ad') {
        planType = 'creative'
      }

      // if the plan type has not already been loaded, fetch the active item for the plan type
      if (!getters.activeItem || // no active item
        getters.activeItem[`${planType}PlanId`] !== parseInt(query.id) || // active item id does not match url param
        ![planType, params.manageType].includes(getters.activeItem.planType)) { // active item and path are different plan types
        await fetchActiveItem.call(this, { commit, dispatch, getters }, { planType, id: query.id })
      }

      if (getters.activeItem) {
        // no need to await this result to continue
        getAncestorsOfActiveItem.call(this, { getters, commit })
          .then(res => {
            const { activeBudget, activeCampaign, activeAdGroup } = useActiveItemAncestors()
            // activeAccount.value = res.accountPlan || null
            activeBudget.value = res.budgetPlan || null
            activeCampaign.value = res.campaignPlan || null
            activeAdGroup.value = res.adGroupPlan || null
          })

        // xlat table
        const defaultTableTypeByManageType = {
          account: getters.user?.defaultManageTableType || 'budget',
          siteLink: 'extension',
          structuredSnippet: 'extension',
          callout: 'extension',
          callExtension: 'extension',
          priceExtension: 'extension',
          ad: 'creative',
          adgroup: 'adGroup'
        }

        const tableType = query.table || defaultTableTypeByManageType[planType] || planType

        const hasEmptyChildItems = !getters.activeChildItems(tableType) || Array.from(getters.activeChildItems(tableType).values()).length === 0
        const hasNonConformingChildItems = hasEmptyChildItems ||
          !Array.from(getters.activeChildItems(tableType).values())
            .distinct(item => item[`${tableType}PlanId`], item => item[`${tableType}PlanId`])
            .includes(getters.activeItem[`${tableType}PlanId`])

        if (hasNonConformingChildItems || previousRoute?.name === 'manage-create') {
          await dispatch('fetchPlansWithMetrics', { fetchType: tableType })
        }
      }
    }
    currentLoading = {
      campaign: false,
      adGroup: false,
      keyword: false,
      creative: false,
      budget: false,
      extension: false,
      criterion: false
    }
    if (getters.activeItem && route.query.id) {
      const titleLead = getters.activeItem.creativePlanId ? 'creative' : getters.activeItem.name
      this.$pageTitle([titleLead, 'Manage'], true)
    }
    commit('loading', {
      ...getters.loading,
      ...currentLoading
    })

    // do this last
    if (route.name === 'manage-manageType' && !getters.activeAccountNotifications) {
      dispatch('fetchAccountNotifications') // do not await. this can run in background
    }
  },

  async fetchManageSkeletons ({ commit, dispatch, getters, state }) {
    if (Object.keys(getters.skeletons || {}).length === 0 || Object.keys(getters.enumerations || {}).length === 0) {
      const response = await this.$res.fetch.skeleton([
        'AccountPlan',
        'inc.fluency.core.common.dto.account.AccountPlanDTO',
        'CampaignPlan',
        'inc.fluency.core.common.dto.campaign.CampaignPlanDTO',
        'inc.fluency.core.common.dto.MetricsDTO',
        'inc.fluency.core.common.dto.adgroup.AdGroupPlanDTO',
        'AdGroupPlan',
        'KeywordPlan',
        'CreativePlan',
        'SiteLinkPlan',
        'StructuredSnippetPlan',
        'CalloutPlan',
        'CallExtensionPlan',
        'PriceExtensionPlan',
        'PriceExtensionPriceTableRow',
        'PromotionExtensionPlan',
        'ImageExtensionPlan',
        'SharedSetPlan',
        'inc.fluency.core.common.dto.sharedSet.SharedSetPlanDTO',
        'BudgetPlan',
        'AccountBroadcast',
        'AccountBroadcastMatcher',
        'CriterionPlan',
        'FormPlan',
        'ProductCatalogPlan',
        'ProductScopePlan',
        'AccountData'])
      let skeletons = {}
      if (response) {
        skeletons = response
      } else {
        this.$toast('Something went wrong.  We\'re having trouble getting scaffolding information we need from the Fluency servers.', 'danger')
        return false
      }

      dispatch('fetchEnumeration', 'AccountBroadcastFieldMapping')
      dispatch('fetchEnumeration', 'AccountBroadcastMatcherMatchLevel')
      dispatch('fetchEnumeration', 'AccountBroadcastMatcherMatchOperator')
      dispatch('fetchEnumeration', 'CampaignPlanCampaignPriority')

      commit('skeletons', {
        skeletons: [
          {
            type: 'account',
            skeleton: skeletons.AccountPlan
          }, {
            type: 'campaign',
            skeleton: {
              campaignPlan: skeletons.CampaignPlan,
              campaignPlanDTO: skeletons['inc.fluency.core.common.dto.campaign.CampaignPlanDTO']
            }
          }, {
            type: 'adGroup',
            skeleton: skeletons.AdGroupPlan
          }, {
            type: 'adGroupDTO',
            skeleton: skeletons['inc.fluency.core.common.dto.adgroup.AdGroupPlanDTO']
          }, {
            type: 'keyword',
            skeleton: skeletons.KeywordPlan
          }, {
            type: 'creative',
            skeleton: skeletons.CreativePlan
          }, {
            type: 'extension',
            skeleton: {
              siteLinkPlan: skeletons.SiteLinkPlan,
              structuredSnippetPlan: skeletons.StructuredSnippetPlan,
              calloutPlan: skeletons.CalloutPlan,
              callExtensionPlan: skeletons.CallExtensionPlan,
              priceExtensionPlan: skeletons.PriceExtensionPlan,
              priceExtensionPriceTableRow: skeletons.PriceExtensionPriceTableRow,
              imageExtensionPlan: skeletons.ImageExtensionPlan,
              promotionExtensionPlan: skeletons.PromotionExtensionPlan
            }
          }, {
            type: 'sharedSet',
            skeleton: {
              sharedSetPlan: skeletons.SharedSetPlan,
              sharedSetPlanDTO: skeletons['inc.fluency.core.common.dto.sharedSet.SharedSetPlanDTO']
            }
          }, {
            type: 'budget',
            skeleton: skeletons.BudgetPlan
          }, {
            type: 'AccountBroadcast',
            skeleton: skeletons.AccountBroadcast
          }, {
            type: 'AccountBroadcastMatcher',
            skeleton: skeletons.AccountBroadcastMatcher
          }, {
            type: 'criterionPlan',
            skeleton: skeletons.CriterionPlan
          }, {
            type: 'form',
            skeleton: skeletons.FormPlan
          }, {
            type: 'productCatalog',
            skeleton: skeletons.ProductCatalogPlan
          }, {
            type: 'productScope',
            skeleton: skeletons.ProductScopePlan
          }, {
            type: 'metrics',
            skeleton: skeletons['inc.fluency.core.common.dto.MetricsDTO']
          }, {
            type: 'AccountData',
            skeleton: skeletons.AccountData
          }
        ]
      })
    }
  },
  async customer (context) {
    const response = await this.$res.customer.get()

    context.commit('customer', response)
    return response
  },
  async customerState (context) {
    const response = await this.$res.customer.state()

    context.commit('customerState', response)
    return response
  },
  async fetchHistory ({ commit, redirect, dispatch, getters }, payload) {
    return this.$res.fetch.revisionHistory(payload)
  },
  async fetchMinBlueprints (context) {
    if (!context.getters.blueprints || Object.keys(context.getters.blueprints).length === 0) { // if blueprints aren't already loaded, fire off a request for them
      const response = await this.$res.fetch.getTemplatesLinkable()
      if (response && response.toMap) { // check to make sure response came back
        context.commit('blueprints', response.toMap(c => c.contentTemplateId))
      }
    }
    return context.getters.blueprints
  },
  async fetchActiveItem ({ commit, dispatch, getters }, { planType, id, settings }) {
    await fetchActiveItem.call(this, { commit, dispatch, getters }, { planType, id, settings })
  },

  async fetchActiveItemMetrics ({ getters, commit }, { planType }) {
    const activeItemWithMetrics = await fetchActiveItemMetrics.call(this, { getters, commit }, { planType })

    commit('activeItem', {
      ...getters.activeItem,
      ...activeItemWithMetrics
    })
  },
  async fetchPlansWithMetrics ({ commit, dispatch, getters }, { fetchType, isBudgetScoped, forceBudgetReload }) {
    if (!fetchType) return

    const plan = getters.activeItem
    if (!plan) return // edge case: load page directly into createFlow. we refresh metrics after saving in create flow to keep the data up to date, but in this case, there's no active item

    const fetchQuery = {}

    if (isBudgetScoped !== false) {
      isBudgetScoped = true
    }

    let params = getMetricsDateParams({ getters }, '?')

    commit('loading', {
      ...getters.loading,
      [fetchType]: true
    })

    // NOTE: we want fallthrough here. Ignore ESLint error
    switch (fetchType?.toLowerCase()) {
      case 'keyword':
      case 'creative':
      case 'criterion':
      case 'extension':
        fetchQuery.adGroupPlanId = plan.adGroupPlanId
      // eslint-disable-next-line no-fallthrough
      case 'adgroup':
        fetchQuery.campaignPlanId = plan.campaignPlanId
      // eslint-disable-next-line no-fallthrough
      case 'campaign':
      case 'budget':
      case 'form':
        fetchQuery.accountPlanId = plan.accountPlanId
      // eslint-disable-next-line no-fallthrough
      case 'account':
        break
    }
    if (isBudgetScoped && plan.budgetPlan) {
      if (fetchType.toLowerCase() === 'campaign') {
        fetchQuery.budgetPlanId = plan.budgetPlan.budgetPlanId
      } else {
        params += `&budgetPlanId=${plan.budgetPlan.budgetPlanId}`
      }
    }

    let plans = []
    if (fetchType.toLowerCase() === 'budget') {
      if (
        (forceBudgetReload || !getters.activeAccountBudgets || getters.activeAccountBudgets.length === 0 || getters.activeAccountBudgets[0].accountPlanId !== fetchQuery.accountPlanId) &&
        fetchQuery.accountPlanId
      ) {
        await fetchAccountBudgets.call(this, { commit, getters }, fetchQuery)
      }
    } else {
      plans = (await this.$res.fetch.plan(fetchType, fetchQuery, params, { headerPageSize: true }))
    }

    if (plans?.length > 0) {
      plans.forEach(plan => {
        if (plan.metricsDTO) {
          delete plan.metricsDTO.startDate
          delete plan.metricsDTO.endDate
        }
      })
      if (fetchType === 'campaign') {
        const planKeys = Object.keys(getters.skeletons.campaign?.campaignPlan ?? {})
        const pickKeys = [
          ...planKeys,
          'namePlaceholder', 'productCatalogPreview', 'shownBasedOnImpressions',
          'partnerId', 'pendingWork', 'partnerObjects',
          'campaignLatchHold', 'latches', 'budgetManagerPaused',
          'servingStatus',
          'optimizationScore',
          'syntheticBudgetType', 'budgetType', 'budgetPlan',
          'contentTemplateName',
          'budget', 'currencyCode', 'approvedAccountBudget',
          'campaignGroupName',
          'campaignBidStrategyDTO',
          'attachedFeeds',
          'labels',
          'metricsDTO',
          'previousMetricsDTO',
          'validWhen'
        ]
        plans = plans
          .map(e => _pick(e, pickKeys))
          .map(e => ({
            ...e,
            ...e.metricsDTO
          }))
      } else if (fetchType === 'creative' || fetchType === 'ad') {
        plans = plans.map(e => ({
          ...e,
          ...e.metricsDTO
        }))
      } else if (fetchType === 'adGroup' || fetchType === 'keyword') {
        plans = plans.map(e => ({
          ...e,
          ...e.metricsDTO
        }))
      } else if (fetchType === 'extension') {
        const extensionsByType = plans.groupBy(p => p.extensionType)

        const getExtensionMetrics = async (typeKey) => {
          const typedMetricsMap = new Map(extensionsByType[typeKey].map(plan => [
            plan.extensionPlanId,
            plan
          ]))

          await fetchMetrics.call(this, { getters, commit }, { plansById: typedMetricsMap, fetchType: typeKey })

          extensionsByType[typeKey] = typedMetricsMap
        }

        const metricsPromises = Object.keys(extensionsByType).map(key => getExtensionMetrics(key))
        await Promise.all(metricsPromises)

        commit('activeChildItems', {
          type: 'extension',
          items: new Map(Object.values(extensionsByType).flatMap(ext => [...ext].map(entry =>
            [`${entry[0]}_${entry[1].extensionType}`, entry[1]]
          )))
        })
        commit('loading', {
          ...getters.loading,
          [fetchType]: false
        })
        return
      }
      const plansById = new Map((plans || []).map(p => [p[`${fetchType}PlanId`], p]))
      commit('table/appliedFiltersHash', '')
      commit('activeChildItems', { type: fetchType, items: plansById })
    }
    commit('loading', {
      ...getters.loading,
      [fetchType]: false
    })
  },

  async fetchMetrics ({ getters, commit }, { plansById, fetchType, includeTotals }) {
    await fetchMetrics.call(this, { getters, commit }, { plansById, fetchType, includeTotals })
  },

  async fetchActiveAccountMetrics (context) {
    let account = context.getters.settingsItem
    if (!account) return
    const metricsMap = new Map([[account.accountPlanId, account]])
    await fetchMetrics.call(this, context, { plansById: metricsMap, fetchType: 'account' })
    account = {
      ...context.getters.settingsItem,
      ...metricsMap.get(account.accountPlanId),
      metricsDTO: {
        ...metricsMap.get(account.accountPlanId)
      }
    }
    context.commit('settingsItem', account)
  },
  async fetchAccountBudgets ({ commit, getters }, { accountPlanId }) {
    await fetchAccountBudgets.call(this, { commit, getters }, { accountPlanId })
  },
  async fetchAccountNotifications ({ commit, getters }) {
    let loading = {
      ...getters.loading,
      notifications: true
    }
    commit('loading', loading)
    const sectionsResp = await this.$res.fetch.getTasksList(getters.activeSegment?.savedSegmentId)
    if (sectionsResp) {
      const { taskSections } = sectionsResp
      const accountPlanId = getters.activeItem.accountPlanId
      const resp = await Promise.allSettled(taskSections.map(section => this.$res.fetch.getNotificationSection({ section, accountPlanIds: [accountPlanId] })))
      const notifications = resp.filter(r => r.status === 'fulfilled' && r.value?.dashboardTaskList?.length > 0).map(r => r.value)
      commit('setActiveAccountNotifications', notifications || [])
    }
    loading = {
      ...getters.loading,
      notifications: false
    }
    commit('loading', loading)
  },
  userModeToggle (context, val) {
    context.commit('updateUserMode', val)
  },
  setBackpackVersion (context, val) {
    context.commit('setBackpackVersion', val)
  },
  setTimeRangeCompare (context, val) {
    context.commit('setTimeRangeCompare', val)
  },
  setTimeRangeCompareOverride ({ commit, getters }, val) {
    const existing = getters.timeRangeCompareOverride
    commit('setTimeRangeCompareOverride', { ...existing, ...val })
  },
  setScrolledPastHeader (context, val) {
    context.commit('setScrolledPastHeader', val)
  },
  setHeaderHeight (context, val) {
    context.commit('setHeaderHeight', val)
  },
  setBreadcrumbHeight (context, val) {
    context.commit('setBreadcrumbHeight', val)
  },
  setAppUnsavedTools (context, val) {
    context.commit('setAppUnsavedTools', val)
  },
  setActiveItem (context, val) {
    context.commit('activeItem', val)
  },
  setActiveCampaign (context, val) {
    context.commit('setActiveCampaign', val)
  },
  setActiveAdGroup (context, val) {
    context.commit('setActiveAdGroup', val)
  },
  setActiveAdGroups (context, val) {
    context.commit('setActiveAdGroups', val)
  },
  setActiveAd (context, val) {
    context.commit('setActiveAd', val)
  },
  setActiveAds (context, val) {
    context.commit('setActiveAds', val)
  },
  setActiveKeyword (context, keyword) {
    context.commit('setActiveKeyword', keyword)
  },
  setActiveKeywords (context, keywords) {
    context.commit('setActiveKeywords', keywords)
  },
  setApiUri (context, uri) {
    context.commit('setApiUri', uri)
  },
  setClipboard (context, clipboard) {
    context.commit('setClipboard', clipboard)
  },
  setOverrides (context, overrides) {
    context.commit('setOverides', overrides)
  },
  setActiveBudgets (context, budgets) {
    context.commit('setActiveBudgets', budgets)
  },

  setGoogleConfigReportFields (context, fields) {
    context.commit('setGoogleConfigReportFields', fields)
  },
  async fetchReportConfigurations (context) {
    context.commit('setReportConfigurations',
      await this.$res.reporting.googleConfigReports())
  },
  async fetchReportDefinitions (context) {
    const unsaved = context.state.reportDefinitions?.filter(def => def.googleReportDefinitionId === null) || []
    context.commit('setReportDefinitions', [
      ...unsaved,
      ...((await this.$res.reporting.reportDefinitions()) || [])
    ])
  },
  async fetchReportDownload (context, reportDefinition) {
    if (reportDefinition && reportDefinition?.selectedFields?.length > 0) {
      context.commit('setReportDownload', null)
      context.commit('setIsReportDownloading', true)
      const download = await this.$res.reporting.googleReportByDefinition(reportDefinition)
      context.commit('setReportDownload', download)
      context.commit('setIsReportDownloading', false)
    } else {
      context.commit('setReportDownload', [])
    }
  },
  async fetchAdvertisingChannels (context) {
    const [advertistingChannels,
      partnerMap
    ] = await Promise.all([
      this.$res.fetch.advertisingChannel(),
      this.$res.fetch.advertisingChannelPartnerMap()
    ])

    if (advertistingChannels && partnerMap) {
      const combined = advertistingChannels.map(channel => ({
        ...channel,
        partnerIds: partnerMap.filter(map => map.advertisingChannelId === channel.advertisingChannelId).map(p => p.partnerId),
        defaultAllocation: partnerMap.filter(map => map.advertisingChannelId === channel.advertisingChannelId).toMap(p => p.partnerId, p => p.defaultAllocation)
      }))
      context.commit(
        'advertisingChannels',
        combined
      )
    }
  },
  async fetchPartners (context) {
    context.commit(
      'partners',
      await this.$res.fetch.partners()
    )
  },
  async fetchVerticals (context) {
    context.commit(
      'verticals',
      await this.$res.customer.verticals()
    )
  },
  async fetchGoogleConfigReportFields (context, reportType) {
    if (reportType) {
      context.commit(
        'setGoogleConfigReportFields',
        (await this.$res.reporting.googleConfigReportFields(reportType)).availableFields
      )
    } else {
      context.commit('setGoogleConfigReportFields', [])
    }
  },
  async fetchTemplates (context) {
    context.commit(
      'templates',
      await this.$res.fetch.templates()
    )
  },
  async fetchTemplatesMinimal (context) {
    context.commit(
      'templates',
      await this.$res.fetch.templatesMinimal()
    )
  },
  async fetchSkeleton (context, type) {
    let skeletonType = type
    switch (type) {
      case 'account':
        skeletonType = 'AccountPlan'
        break
      case 'productCatalog':
        skeletonType = 'ProductCatalogPlan'
        break
    }
    if (!context.getters.skeletons[type] || context.getters.nodeEnv === 'development') {
      try {
        const skeleton = await this.$res.fetch.skeleton(skeletonType)

        context.commit('skeleton', { type, skeleton })
      } catch (e) {
        console.error(e)
      }
    }
    return context.getters.skeletons[type]
  },
  async seConfig (context) {
    try {
      const config = await this.$res.fetch.seConfig()

      // enhance blueprint config with user capabilities
      Object.keys(config).forEach(key => {
        Object.keys(config[key]).forEach(subKey => {
          if (typeof config[key][subKey] === 'string' && config[key][subKey].includes('capabilities.')) {
            const capability = config[key][subKey].split('.')[1]
            config[key][subKey] = !!context.getters.user?.capabilities?.[capability]
          }
        })
      })

      return context.commit('seConfig', config)
    } catch (e) {
      console.error(e)
    }
  },
  async seRecipes (context) {
    const recipes = await this.$res.pojoGet('Formula', '')

    context.commit('seRecipes', recipes)
  },
  async fetchEnumeration (context, type) {
    if (!context.getters.enumerations[type]) {
      try {
        const enumeration = await this.$res.fetch.enumeration(type)

        context.commit('enumeration', { type, enumeration })
      } catch (e) {
        console.error(e)
        this.$bugsnag(e)
      }
    }
  },
  async fetchSharedSets (context, { accountPlanId }) {
    try {
      let sharedSets = await this.$res.fetch.sharedSets({ accountPlanId })
      sharedSets = sharedSets.filter(sharedSet => sharedSet.status === 'ENABLED')
      const accounts = new Map(context.getters.accountsById || new Map())
      accounts.get(accountPlanId).sharedSets = sharedSets
      context.commit('setAccountsById', accounts)
    } catch (e) {
      this.$bugsnag(e)
    }
  },
  async fetchSharedSetsIntoActiveItem (context) {
    const item = { ...context.getters.settingsItem }
    if (item.campaignPlanId) {
      item.sharedSets = await this.$res.fetch.sharedSetsAttachedToCampaign(item)
    } else if (item.accountPlanId) {
      item.sharedSets = await this.$res.fetch.sharedSetsForAccount({ accountPlanId: item.accountPlanId })
    }
    item.sharedSets = item.sharedSets.filter(sharedSet => sharedSet.status === 'ENABLED')
    context.commit('settingsItem', item)
  },
  async fetchUsersForCustomer (context) {
    context.commit('usersForCustomer', (await this.$res.fetch.usersForCustomer() || []))
  },
  copyBin (context, item) {
    context.commit('copyBin', item)
  },
  async fetchBroadcasts (context, payload) {
    const accountPlanId = payload?.accountPlanId
    const fetchAccountDraftsForCustomer = payload?.fetchAccountDraftsForCustomer // { accountPlanId, fetchAccountDraftsForCustomer }
    const [accountBroadcasts,
      customerBroadcasts,
      accountDraftsForCustomer
    ] = await Promise.all([
      accountPlanId ? this.$res.fetch.broadcasts({ accountPlanId }) : null,
      this.$res.fetch.customerBroadcasts(),
      fetchAccountDraftsForCustomer ? this.$res.fetch.accountBroadcastDraftsForCustomer() : null
    ])

    if (accountBroadcasts) {
      context.commit('broadcasts', accountBroadcasts)
    }
    if (customerBroadcasts) {
      context.commit('customerBroadcasts', customerBroadcasts)
    }
    if (accountDraftsForCustomer) {
      context.commit('accountBroadcastDraftsForCustomer', accountDraftsForCustomer)
    }
  },
  async fetchAdGroupTargeting ({ getters, commit }, { useSettingsItem, inputAdGroupPlanId, inputAccountPlanId }) {
    const adGroupPlanId = useSettingsItem ? getters.settingsItem.adGroupPlanId : inputAdGroupPlanId
    const accountPlanId = useSettingsItem ? getters.settingsItem.accountPlanId : inputAccountPlanId
    if (adGroupPlanId) {
      try {
        const targeting = await this.$res.fetch.adGroupTargeting({ adGroupPlanId, accountPlanId })
        if (targeting) {
          if (useSettingsItem) {
            commit('settingsItem', {
              ...getters.settingsItem,
              criterionPlans: targeting.criterionPlanDtos,
              unmanagedTargeting: targeting.adGroupPlanUnmanagedTargeting,
              dynamicProductAudiences: targeting.dynamicProductAudiences,
              geoTargetDTO: targeting.geoTargetDTO
            })
          }
          return targeting
        }
      } catch (e) {
        this.$bugsnag(e)
      }
    }
  },
  async fetchAdGroupCriterionPlans ({ getters, commit }) {
    const item = getters.settingsItem
    if (item?.adGroupPlanId) {
      try {
        const criterionPlanPromise = this.$res.fetch.criterionPlans({
          accountPlanId: item.accountPlanId,
          adGroupPlanId: item.adGroupPlanId
        })
        const unmanagedTargetingPromise = this.$res.fetch.unmanagedTargeting({
          adGroupPlanId: item.adGroupPlanId
        })
        const [criterionPlans, unmanagedTargeting] = await Promise.all([criterionPlanPromise, unmanagedTargetingPromise])

        if (criterionPlans || unmanagedTargeting) {
          commit('settingsItem', {
            ...item,
            criterionPlans: criterionPlans || [],
            unmanagedTargeting: unmanagedTargeting?.length > 0 ? unmanagedTargeting[0] : null
          })
        }
      } catch (e) {
        this.$bugsnag(e)
      }
    }
  },
  async fetchAccountPartnerTrackingCodes (context, { accountPlanId }) {
    if (accountPlanId) {
      if (!context.getters.accountPartnerTrackingCodes ||
        context.getters.accountPartnerTrackingCodes.length === 0 ||
        context.getters.accountPartnerTrackingCodes.some(tc => tc.accountPlanId !== accountPlanId)) {
        try {
          const trackingCodes = await this.$res.fetch.partnerTrackingCodes({ accountPlanId })
          if (trackingCodes) {
            context.commit('accountPartnerTrackingCodes', trackingCodes)
          }
        } catch (e) {
          this.$bugsnag(e)
        }
      }
    }
  },

  async backpackVersion () {

  },

  async saveActiveAdGroup (context) {
    // this is meant to be used from /manage/adgroup
    const adGroup = { ...context.getters.settingsItem }

    const criterionPlans = context.getters.settingsItem.criterionPlans
      ?.filter(p => p.dirty)
      .map(p => {
        const plan = { ...p }
        if (plan.criterionPlanId < 0) {
          delete plan.criterionPlanId
        }
        return plan
      })
    adGroup.criterionPlans = criterionPlans?.length > 0 ? criterionPlans : null
    if (typeof context.getters.settingsItem.dynamicProductAudiences !== 'undefined' && context.getters.settingsItem.dynamicProductAudiences !== null) {
      adGroup.userListPlans = context.getters.settingsItem.dynamicProductAudiences.filter(dpa => dpa.userListPlanId > 0)
    }

    const response = await this.$res.set.plan('adGroup', adGroup)
    if (!response || response.hasFailures) {
      this.$toast('Error saving the adGroup')
      return
    }

    // note: sometimes response.response.requests returns a list of requests because of rapid content buildout creation using the '{' -SNG
    const dto = response.response.requests

    usePartnerPolling().addPendingWork(dto[0].pendingWork)

    const savedAdGroup = []
    for (let i = 0; i < dto.length; i++) {
      savedAdGroup[i] = {
        ...adGroup,
        ...dto[i],
        dirty: false
      }
    }

    context.commit('settingsItem', savedAdGroup[0])
    context.commit('settingsItemInTable', savedAdGroup[0])
    context.dispatch('fetchAdGroupTargeting', { useSettingsItem: true })
  },
  async pollForReverseSync (context, { accountPlanId }) {
    const vue = this
    const checkStatus = async function (accountPlanId) {
      // MOCKED OUT API CALLS
      // const target = 10
      // const randInt = Math.floor(Math.random() * target) + 1
      // console.log('random number:', randInt)
      // const fakeApiSuccess = randInt === target
      // context.commit('activeReverseSync', { accountPlanId, running: !fakeApiSuccess })
      // if (!fakeApiSuccess) {
      //   setTimeout(() => {
      //     checkStatus(accountPlanId)
      //   }, 1000)
      // } else {
      //   console.log('success: we can stop this loop')
      // }

      // REAL
      const status = await vue.$res.fetch.reverseSyncStatus({ accountPlanId }, { pendingOnly: true })
      context.commit('activeReverseSync', { accountPlanId, running: status?.complete === false })

      if (status?.complete === false) {
        setTimeout(() => {
          checkStatus(accountPlanId)
        }, 5000)
      }
    }

    await checkStatus(accountPlanId)
  },
  async pollForJob (context, { objectId, objectType }) {
    const startEpochString = moment.utc(new Date()).subtract(10, 'seconds').format()

    const doPoll = function (objectId, objectType, startEpoch) {
      const vue = this
      const poll = async function () {
        const job = await vue.$res.fetch.job(objectId, objectType, startEpoch)
        context.commit('jobStatus', { objectId, objectType, job })
        if (job.completedJobId || job.failedJobId) {
          clearTimeout(pollingId)
        } else {
          pollingId = setTimeout(poll, 1000)
        }
      }

      let pollingId = setTimeout(poll, 0)
    }.bind(this)
    doPoll(objectId, objectType, startEpochString)
  },
  setLaunchPlans (context, launchPlans) {
    context.commit('launchPlans', launchPlans)
  },
  async fetchFacebookPageLinkStatus (context) {
    context.commit('facebookPageLinkStatus', await this.$res.fetch.facebookPageLinkStatus())
  },
  async fetchGoogleContentLinkStatus (context) {
    context.commit('googleContentLinkStatus', await this.$res.fetch.googleContentLinkStatus('content'))
  },
  async fetchGoogleMyBusinessLinkStatus (context) {
    context.commit('googleMyBusinessLinkStatus', await this.$res.fetch.googleContentLinkStatus('my-business'))
  },
  async bulkFacebookPageLink (context, statuses = []) {
    const pageLinkStatus = context.getters.facebookPageLinkStatus
    if (pageLinkStatus) {
      const accountPlanIds = Object.keys(pageLinkStatus)
        .filter(key => statuses.includes(key))
        .flatMap(key =>
          pageLinkStatus[key]
            .map(obj => typeof obj === 'number' ? obj : obj.accountPlanId))

      if (accountPlanIds.length > 0) {
        try {
          await this.$res.set.facebookPageLinkRequest(accountPlanIds)
          context.dispatch('fetchFacebookPageLinkStatus')
          this.$toast(`${accountPlanIds.length} Request${accountPlanIds.length > 1 ? 's' : ''} Sent!`, 'success')
        } catch (e) {
          this.$bugsnag(e instanceof Error ? e : new Error(e))
        }
      } else {
        this.$toast('No requests sent!', 'warning')
      }
    }
  },
  async saveAccountPlan (context, account) {
    const response = await this.$res.set.plan('account', account)
    if (response && !response.hasErrors) {
      const savedAccount = {
        ...account,
        dirty: false,
        dirtyFields: {},
        ...response.response.requests[0]
      }
      this.$toast(`Saved ${savedAccount.name || 'Account'} successfully!`, 'success')

      let oldAccountsById = context.getters.accountsById || new Map()
      const accountsById = new Map(oldAccountsById)
      accountsById.set(savedAccount.accountPlanId, {
        ...accountsById.get(savedAccount.accountPlanId),
        ...savedAccount
      })
      context.commit('setAccountsById', accountsById)
      if (oldAccountsById) {
        oldAccountsById.clear()
        oldAccountsById = null
      }

      if (!context.getters.settingsItem || context.getters.settingsItem.accountPlanId !== account.accountPlanId) {
        // user is flying and has already moved to a different account
        return response
      }
      context.commit('settingsItem', savedAccount)
      await fetchAccountBudgets.call(this, context, savedAccount)
      context.dispatch('fetchActiveAccountMetrics')
      context.dispatch('fetchPlansWithMetrics', { fetchType: 'campaign' })
    } else {
      this.$bugsnag(new Error('Account Plan Save Failed'), {
        metadata: {
          action: 'saveAccountPlan',
          payload: account,
          response
        }
      })
      this.$toast('Account Save Failed', 'danger')
    }
    return response
  },
  async saveSubAccounts (context, { subAccounts }) {
    if (subAccounts) {
      const savePromises = subAccounts
        .filter(sa => sa.dirty)
        .map(sa => this.$res.set.subAccount(sa))

      const responses = await Promise.all(savePromises)
      return responses
    }
  },
  async saveAccountSharedSets (context, accountPlan) {
    const { sharedSets } = accountPlan
    if (sharedSets && sharedSets.length > 0) {
      const payload = sharedSets
        .filter(set => set['backpack-unsaved'] && (set.sharedSetPlanId || set.status === 'ENABLED'))
        .map(set => this.$res.set.sharedSet(set))

      const responses = await Promise.all(payload)
      if (responses.every(response => response !== false)) { // if all the responses are not errors
        await context.dispatch('fetchSharedSetsIntoActiveItem')
      }
      return responses
    }
  },
  async savePhones (context, phones) {
    const response = await this.$res.set.phones(phones)
    return response
  },
  async saveBroadcasts (context, broadcasts) {
    let response = false
    if (!broadcasts) {
      broadcasts = context.getters.broadcasts
    }
    try {
      const payload = broadcasts.filter(b => b.dirty)
        .map(b => ({
          ...b,
          accountBroadcastId: b.accountBroadcastId < 0 ? null : b.accountBroadcastId
        }))
      if (payload.length > 0) {
        response = await this.$res.set.broadcasts(payload)
        if (response) {
          await context.dispatch('fetchBroadcasts', this.$router.currentRoute.name === 'customer-broadcast' ? { accountPlanId: null } : context.getters.settingsItem)
          this.$toast('Broadcasts saved!', 'success')
          return response
        } else {
          throw new Error('Error setting broadcasts')
        }
      }
    } catch (e) {
      this.$bugsnag(e)
      this.$toast('Something went wrong and broadcasts may not be saved!', 'warning')
    }
    return response
  },
  async saveImageExtensionParentLockedFields (context, { imageExtensionPlan, parentPlan, lockValue }) {
    // image extensions are locked as a collection under their parent campaign or ad group
    let localParentPlan = parentPlan || imageExtensionPlan.adGroupPlan || imageExtensionPlan.campaignPlan
    const parentType = imageExtensionPlan.adGroupPlanId ? 'adGroup' : 'campaign'
    if (!localParentPlan) {
      // fetch parent if it isn't attached to the extension plan
      const parent = await this.$res.fetch.plan(parentType, { [`${parentType}PlanId`]: imageExtensionPlan[`${parentType}PlanId`] })
      localParentPlan = parent[0]
    } else {
      localParentPlan = _cloneDeep(localParentPlan)
    }
    if (!localParentPlan || !localParentPlan.contentTemplateId) {
      // no need to do anything if there isn't an associated blueprint or we can't find the parent plan
      return imageExtensionPlan
    }
    // ensure locks are up to date
    if (lockValue === true && !localParentPlan.lockedFields?.includes('imageExtensions')) {
      localParentPlan.lockedFields = addLocks(localParentPlan, 'imageExtensions')
    } else if (lockValue === false && localParentPlan.lockedFields?.includes('imageExtensions')) {
      localParentPlan.lockedFields = removeLocks(localParentPlan, 'imageExtensions')
    }

    const response = await this.$res.set.plan(parentType, localParentPlan)
    if (response) {
      this.$toast(`Successfully ${localParentPlan.lockedFields.includes('imageExtension') ? 'locked' : 'unlocked'} image extensions for ${localParentPlan.name}`, 'success')
      return { ...imageExtensionPlan, [`${parentType}Plan`]: response.response.requests[0] }
    } else {
      this.$toast(`There was a problem ${localParentPlan.lockedFields.includes('imageExtension') ? 'locking' : 'unlocking'} image extensions for ${localParentPlan.name}`, 'danger')
      return false
    }
  },
  async fetchAccountListData (context, accountPlan) {
    context.commit('loading', {
      ...context.getters.loading,
      listData: true
    })
    if (!accountPlan) {
      accountPlan = context.getters.settingsItem
    }
    if (!accountPlan) {
      return
    }
    let [
      listData,
      overrides,
      crawlerData
    ] = await Promise.all([
      this.$res.fetch.listData(accountPlan),
      this.$res.fetch.overrides(accountPlan),
      this.$res.fetch.crawlerData(accountPlan)
    ])

    if (listData) {
      listData = listData?.map(ld => {
        if (!ld.content || ld.content.length === 0) {
          ld.content = ''
          return ld
        }
        if (ld.source === 'json' && ((ld.content.indexOf('{') !== -1 && ld.content.indexOf('}') !== -1) || (ld.content.indexOf('[') !== -1 && ld.content.indexOf(']') !== -1))) {
          try {
            ld.content = JSON.stringify(JSON.parse(ld.content), null, 4)
            if (ld.enhancedContent) {
              ld.enhancedContent = JSON.stringify(JSON.parse(ld.enhancedContent), null, 4)
            }
          } catch (e) {
            // if you can't parse it, it could be text with a tag inside
          }
        }
        return ld
      }) ?? []
      context.commit('settingsItem', {
        ...accountPlan,
        listData,
        overrides,
        crawlerData
      })
      context.commit('loading', {
        ...context.getters.loading,
        listData: false
      })
    }
  },
  async saveAccountListData (context, { listData, overrides }) {
    let updated = false
    let responses = []
    if (listData) {
      const payload = listData
        .filter(b => b.dirty)
        .map(ld => {
          let content = ld.content
          if (typeof ld.content === 'string' && ((ld.content.indexOf('{') !== -1 && ld.content.indexOf('}') !== -1) || (ld.content.indexOf('[') !== -1 && ld.content.indexOf(']') !== -1))) {
            try {
              content = JSON.stringify(JSON.parse(ld.content))
            } catch (e) {
              // could just be tags in the account data
            }
          }
          return {
            ...ld,
            content
          }
        })
      payload.forEach(ld => {
        delete ld.dirty
        if (ld.listDataId <= 0) {
          delete ld.listDataId
        }
      })
      if (payload.length > 0) {
        const promises = payload.map(p => this.$res.set.list(p))
        responses = await Promise.all(promises)
        updated = true
      }
    }
    if (overrides) {
      const payload = overrides.filter(o => o.dirty)
      payload.forEach(o => {
        delete o.dirty
      })
      if (payload.length > 0) {
        const promises = payload.map(p => this.$res.set.override(p))
        responses = await Promise.all(promises)
        updated = true
      }
    }
    if (updated) {
      // await context.dispatch('fetchAccountListData')
      const item = context.getters.settingsItem
      if (item) {
        // remove any list items that don't have an ID and new items that were just saved, then push the new list item with the ID on
        const listData = item.listData.filter(list => list.listDataId && !list.dirty)

        responses.forEach(response => { // replace any existing lists with the responses that came back
          if (response) { // if the list successfully saved then update it
            const listIndex = listData.findIndex(l => l.listDataId === response.listDataId)
            if (response.source === 'json') {
              response.content = JSON.stringify(JSON.parse(response.content), null, 4)
            }
            if (listIndex !== -1) { // if it is an existing list, replace it
              listData[listIndex] = response
            } else { // if it is a new list that was just created, push on the response, we filtered out the unsaved data above
              listData.push(response)
            }
            this.$toast(`Saved list: ${response.listName}`, 'success')
          }
        })
        context.commit('settingsItem', {
          ...item,
          listData
        })
      }
      await context.dispatch('fetchAccountListData') // putting this in for now so we can get the filter data
    }
    return responses
  },
  async fetchFacebookAccountList (context) {
    context.commit('facebookAccountList', await this.$res.fetch.facebookAccountsForCustomer())
  },
  async saveExtensionPlan (context, extensionPlan) {
    if (!extensionPlan.planType && !extensionPlan.extensionType) {
      return null
    }
    const planType = extensionPlan.planType || extensionPlan.extensionType.slice(0, -4)
    const skeleton = context.getters.skeletons.extension[`${planType}Plan`]
    if (skeleton) {
      const payload = _pick(extensionPlan, Object.keys(skeleton))

      if (planType.startsWith('priceExtension')) {
        const priceTableRowSkeleton = context.getters.skeletons.extension.priceExtensionPriceTableRow
        payload.priceExtensionPriceTableRows = (extensionPlan.priceExtensionPriceTableRows || [])
          .map(row => _pick(row, Object.keys(priceTableRowSkeleton)))
      }
      const response = await this.$res.set.plan(planType, payload)
      if (response && !response.hasFailures) {
        if (extensionPlan.imageExtensionPlanId) {
          // perform locking
          await context.dispatch('saveImageExtensionParentLockedFields', { imageExtensionPlan: extensionPlan, lockValue: true })
        }
        return response.response?.requests?.[0] || response
      } else {
        this.$bugsnag(new Error('Some problem saving extensions'), {
          metadata: response
        })
      }
    }
  },
  copyPlans (context, val) {
    const parentMap = {
      campaign: 'account',
      adGroup: 'campaign',
      keyword: 'adGroup',
      creative: 'adGroup'
    }
    try {
      localStorage.setItem('copiedPlans', JSON.stringify({
        plans: val.plans,
        parent: parentMap[val.type],
        type: val.type
      }))
      this.$toast(`${val.plans.length} ${val.type}${(val.plans.length > 1) ? 's' : ''} copied to clipboard`, 'success')
    } catch (e) {
      this.$toast('Local storage unavailable for copy', 'danger')
    }
  },
  getCopiedPlans (context, val) {
    try {
      return JSON.parse(localStorage.getItem('copiedPlans'))
    } catch (e) {
      this.$toast('Local storage unavailable', 'danger')
    }
  },
  async fetchListDataFields () {
    const fields = await this.$res.fetch.listFieldIndex()
    if (fields) {
      return fields.groupBy(f => f.ownerList)
    }
    return {}
  },
  getMetricsDateParams ({ getters }, parameters) { // eventually this action will go away yea?
    return getMetricsDateParams({ getters }, parameters)
  },
  async loadAccountDetails (context, opts = { }) {
    const defaultOpts = { forceReload: false, skipMetrics: false, overwrite: false }

    return loadAccountDetails.call(this, context, { ...defaultOpts, ...opts })
  },
  async reloadAccounts (context) {
    return loadAccountDetails.call(this, context, { forceReload: true, overwrite: true })
  },
  async clearAllFilters ({ getters, commit, dispatch }) {
    const segment = getters.activeSegment
    if (segment && typeof segment === 'object' && Object.keys(segment).length > 0) {
      const emptyFilter = {
        savedSegmentId: 0,
        name: 'All Accounts'
      }
      commit('setActiveSegment', emptyFilter)
      await dispatch('reloadAccounts')
    }
  },
  async fetchCustomerSettings (context) {
    const settings = await this.$res.customer.getSettings()
    context.commit('customerSettings', settings || [])
  },
  async fetchTimeZones (context) {
    const timeZones = await this.$res.fetch.timeZones()
    context.commit('timeZones', timeZones || [])
  },
  async setUserDefaultManageTableType (context, { defaultManageTableType }) {
    context.commit('userDefaultManageTableType', defaultManageTableType || null)
    await this.$res.set.userDefaultManageTableType({ defaultManageTableType })
  },
  async channelGroups (context) {
    const response = await this.$res.fetch.channelGroups()
    context.commit('channelGroups', response)
  },
  clearChannelGroupsFilter ({ commit, dispatch, getters }) {
    if (getters.channelGroupsFilter?.length > 0) {
      commit('setting', { key: 'channelGroupsFilter', value: [] })
      dispatch('reloadAccounts')
    }
  },
  applyChannelGroupFilter ({ commit, dispatch }, filterByChannels) {
    commit('setting', { key: 'channelGroupsFilter', value: filterByChannels })
    dispatch('reloadAccounts')
  },
  async setMetricsBarItem ({ getters, dispatch }, item) {
    const metricsBarItems = [...getters.metricsBarItems]

    if (metricsBarItems.includes(item)) {
      _pull(metricsBarItems, item)
    } else {
      metricsBarItems.push(item)
    }

    dispatch('setMetricsBar', { payload: metricsBarItems })
  },
  async setMetricsBarFull ({ dispatch }, { payload, write = true }) {
    dispatch('setMetricsBar', { payload, write })
  },
  async clearMetricsBarItems ({ dispatch }) {
    dispatch('setMetricsBar', { payload: DefaultPreferences.metricsBarItems })
  },
  async setMetricsBar ({ commit }, { payload, write = true }) {
    commit('setting', { key: 'metricsBarItems', value: payload })

    if (write) {
      await this.$res.set.userPreference({
        type: 'METRICS_BAR',
        key: 'items',
        value: encodeURI(payload)
      })
    }
  },
  async applySegment ({ getters, commit, dispatch }, segment) {
    const activeSegment = getters.activeSegment

    const newActiveSegment = (activeSegment.savedSegmentId || activeSegment.savedSegmentId === 0) || activeSegment.criteriaJson ? { ...activeSegment } : {}
    if (Object.keys(segment).length > 0) {
      newActiveSegment.criteriaJson = { ...newActiveSegment.criteriaJson, ...segment }
    }
    commit('setActiveSegment', newActiveSegment)
    await dispatch('reloadAccounts')
  },
  removeSegmentCriteria ({ getters, commit, dispatch }, type) {
    const activeSegment = { ...getters.activeSegment }
    const criteria = { ...activeSegment.criteriaJson }
    delete criteria[type]
    activeSegment.criteriaJson = criteria
    if (Object.keys(criteria).length > 0) {
      commit('setActiveSegment', activeSegment)
    } else {
      commit('setActiveSegment', {})
    }
    dispatch('reloadAccounts')
  },
  async getCreativeSpecs ({ getters, commit }) {
    if (getters.creativeSpecs) {
      return getters.creativeSpecs
    }
    const specs = await this.$res.fetch.creativeSpecs()
    commit('creativeSpecs', specs)
    return specs
  },
  async getProductScopePlans ({ getters, commit }, { accountPlanId, advertisingChannelId }) {
    if (!accountPlanId) {
      return []
    }
    const cachedScoped = getters.productScopePlans(advertisingChannelId) || []
    if (cachedScoped.length > 0 && !cachedScoped.some(p => p.accountPlanId && p.accountPlanId !== accountPlanId)) {
      return cachedScoped
    }
    const scopes = await this.$res.fetch.eligibleProductScopes({ accountPlanId, advertisingChannelId })
    commit('productScopePlans', { scopes, advertisingChannelId })
    return scopes
  },
  async getAccountPollingData ({ getters, commit }, accountPlanId) {
    if (!accountPlanId) return []
    const accountPollingData = getters.accountPollingData
    if (accountPollingData?.every(d => d.accountPlanId === accountPlanId)) {
      return accountPollingData
    } else {
      const response = await this.$res.fetch.accountData({ accountPlanId })
      if (response) {
        const allButRemoved = response.filter(x => x && !x.removed)
        allButRemoved.sort((a, b) => {
          if (!a.customerDataId && !b.customerDataId) {
            return a.accountDataId - b.accountDataId
          } else {
            return (a.customerDataId || 0) - (b.customerDataId || 0)
          }
        })
        commit('accountPollingData', allButRemoved)
        return allButRemoved
      }
      return []
    }
  },
  async fetchAccountQaStatus ({ getters, commit }, { accountPlanId, forceFetch = false }) {
    if (!accountPlanId) {
      return null
    }
    if (!forceFetch && getters.accountQaStatus[accountPlanId]) {
      return getters.accountQaStatus[accountPlanId]
    }

    const accountQaStatus = await this.$res.fetch.accountQa(accountPlanId)
    commit('accountQaStatus', { accountPlanId, accountQaStatus })

    return accountQaStatus
  },
  async fetchUserSavedSegments ({ getters, commit }, { forceFetch = false }) {
    if (!forceFetch && getters.userSavedSegments?.length > 0) {
      return getters.userSavedSegments
    }
    const segments = await this.$res.fetch.savedSegments()
    if (segments) {
      commit('userSavedSegments', segments)
      return segments
    }
    return []
  },
  async fetchSavedSegmentFields ({ getters, commit }) {
    if (getters.savedSegmentFields?.length > 0) {
      return getters.savedSegmentFields
    }
    const availFields = await this.$res.fetch.availableFieldsForSegments()
    commit('savedSegmentFields', availFields)
  },
  activelyRepublishingBlueprints ({ getters, commit }, payload) {
    const flat = _flatten(getters.activelyRepublishingBlueprints)

    if (!_isEqual(payload, flat)) {
      commit('activelyRepublishingBlueprints', payload)
    }
  },
  async fetchPartnerPages ({ getters, commit }, { accountPlanId, facebookPageId }) {
    if (accountPlanId && !facebookPageId) {
      const pages = await this.$res.fetch.facebookPages({ accountPlanId })
      if (pages?.length > 0) {
        const pagesByRefIdMap = pages.filter(p => p.referenceId).toMap(p => p.referenceId)
        commit('partnerPages', pagesByRefIdMap)
        return pagesByRefIdMap
      }
    }

    if (getters.partnerPages[facebookPageId]) {
      return getters.partnerPages
    }
    commit('partnerPages', {
      ...getters.partnerPages,
      [facebookPageId]: 'LOADING'
    })
    const pages = await this.$res.fetch.facebookPages({ accountPlanId, facebookPageId })
    if (pages?.length > 0) {
      const pagesByRefIdMap = pages.filter(p => p.referenceId).toMap(p => p.referenceId)
      commit('partnerPages', {
        ...getters.partnerPages,
        ...pagesByRefIdMap
      })
    }
    return getters.partnerPages
  },
  async fetchAccountVideos ({ getters, commit }, payload) {
    const videos = await this.$res.account.getVideos(payload) || []
    commit('accountVideos', videos)
  },
  manageDrawerWidth ({ getters, commit }, newVal) {
    commit('manageDrawerWidth', newVal)
  },
  manageDrawerBootstrapSize ({ commit }, newVal) {
    if (!isNaN(newVal)) {
      commit('manageDrawerBootstrapSize', newVal)
    }
  },
  async clearCustomerSpecificLocalStore ({ commit, getters }) {
    const retainableCacheKeys = [
      'accessToken', 'refreshToken', 'expiry', 'backpack.app.settings',
      'timeZones', 'backpack.user', 'actingAsUser']
    let retainedCache = {}
    if (retainableCacheKeys?.length > 0) {
      retainedCache = retainableCacheKeys.toMap(key => key, key => localStorage.getItem(key))
    }

    // reset active segment
    const settings = JSON.parse(retainedCache['backpack.app.settings'])
    if (settings && typeof settings === 'object') {
      settings.activeSegment = {}
      retainedCache['backpack.app.settings'] = JSON.stringify(settings)
    }

    // set capabilities for acting as user
    const actingAsUser = await this.$res.fetch.user()
    if (actingAsUser && actingAsUser.capabilities) {
      const user = JSON.parse(retainedCache['backpack.user'])
      if (user) {
        user.capabilities = actingAsUser.capabilities
        retainedCache['backpack.user'] = JSON.stringify(user)
        commit('user', {
          ...getters.user,
          capabilities: actingAsUser.capabilities
        })
      }
    }

    localStorage.clear()
    Object.keys(retainedCache).forEach(key => {
      localStorage.setItem(key, retainedCache[key])
    })
  },
  setShowPlanStatus ({ getters, commit }, planStatus) {
    commit('setShowPlanStatus', planStatus)
    if (planStatus === 'Show All Enabled') {
      commit('setTableToggleFilters', {
        table: 'budget',
        toggle: 'showActiveOnly',
        value: true
      })
    } else {
      commit('setTableToggleFilters', {
        table: 'budget',
        toggle: 'showActiveOnly',
        value: false
      })
    }
  },
  setBlueprintCurrentEditingObject ({ commit }, val) {
    commit('setBlueprintCurrentEditingObject', val)
  },
  async loadQuestionnaireSpecs ({ commit, getters }) {
    if (getters.questionnaireSpecs) {
      return getters.questionnaireSpecs
    }
    const response = await this.$res.fetch.pojo('QuestionnaireSpec', '', { customerId: getters.user.actingAsUser, status: 'ENABLED' })
    const commitPayload = (response || [])
    commit('questionnaireSpecs', commitPayload)
    return getters.questionnaireSpecs
  },
  setBlueprintEditorNavFilter ({ commit }, val) {
    commit('setBlueprintEditorNavFilter', val)
  },
  blueprintEditorCurrentDynamicTags ({ commit }, val) {
    commit('blueprintEditorCurrentDynamicTags', val)
  },
  blueprintEditorCurrentDynamicTagAliases ({ commit }, val) {
    commit('blueprintEditorCurrentDynamicTagAliases', val)
  },
  async fetchAccountPageInstantExperiences ({ commit, getters }, { accountPlanId, pageId }) {
    if (getters.accountPageInstantExperiences?.accountPlanId === accountPlanId &&
        getters.accountPageInstantExperiences.pageId === pageId &&
        getters.accountPageInstantExperiences.instantExperiences?.length > 0) {
      return getters.accountPageInstantExperiences.instantExperiences
    }

    if (getters.accountPageInstantExperiences.loading) {
      return []
    }

    commit('accountPageInstantExperiences', {
      accountPlanId,
      pageId,
      instantExperiences: [],
      loading: true
    })

    const result = await this.$res.fetch.instantExperiences({ accountPlanId, status: 'ENABLED', pageReferenceId: pageId })
    commit('accountPageInstantExperiences', {
      accountPlanId,
      pageId,
      instantExperiences: result,
      loading: false
    })
    return result
  },
  async fetchInstantExperienceLayouts ({ commit, getters }) {
    if (getters.instantExperienceLayouts?.length > 0) {
      return getters.instantExperienceLayouts
    }
    const result = await this.$res.fetch.instantExperienceLayouts()
    commit('instantExperienceLayouts', result)
    return result
  },
  async fetchInstantExperienceTemplates ({ commit, getters }) {
    if (getters.instantExperienceTemplates?.length > 0) {
      return getters.instantExperienceTemplates
    }
    const result = await this.$res.fetch.instantExperienceTemplates()
    commit('instantExperienceTemplates', result)
    return result
  },
  trimNavForUser ({ commit, getters }) {
    commit('navigationForUser', getNavigationForUser(getters.user, getters.advertisingChannels, { bionic: getters?.appForksBionicLayout, internalUser: getters?.user?.internalUser }))
  },
  toggleForkedUiElement ({ getters, commit }, { val, key, refresh }) {
    commit('toggleForkedUiElement', { val, key })

    if (refresh) {
      window.location.reload()
    }
  },
  blueprintEditorShowAdvancedSettings ({ commit, getters }) {
    const newVal = !getters.blueprintEditorShowAdvancedSettings
    commit('setting', { key: 'blueprintEditorShowAdvancedSettings', value: newVal })
  }
}
