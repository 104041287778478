<template>
  <drawer :show="show"
          @update:show="show = $event"
          :right="true"
          :drawer-width="drawerOpeningWidth"
          no-scrim
          title="Broadcast Preview"
          content-title="Preview Changes to Ads made by Account and Global Broadcasts"
          content-description="Fluency displays differences between ad copy that is currently serving versus what will serve on the selected date. Only click Publish after you verify the ad copy is correct."
  >
    <div class="d-flex justify-content-center align-items-start">
      <b-form-group v-if="isCustomerContext"
                    class="mr-5"
                    style="flex: 0 0 20rem;"
                    label="Select a Preview Account"
                    :description="'Preview how broadcast settings affect a particular account.\nGlobal broadcasts will still publish to all accounts regardless of what you preview'"
                    :state="previewAccountPlanId !== null"
                    invalid-feedback="Account is required for preview">
        <b-form-select v-model="previewAccountPlanId"
                       :state="previewAccountPlanId !== null">
          <template #first>
          <option disabled :value="null">-- Select an account to preview global broadcast --</option>
          </template>
          <option v-for="accountPlan of accountPlanList" :key="accountPlan.accountPlanId" :value="accountPlan.accountPlanId">{{accountPlan.name}}</option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Enter Preview Date"
                    :description="'Preview how broadcast settings affect ad copy as of this date'"
                    invalid-feedback="Date is required for preview"
                    :state="previewDate !== null"
                    class="mr-5"
                    style="flex: 0 0 15rem;">
        <single-date-picker v-model="previewDate" hide-clear-button open="right"></single-date-picker>
      </b-form-group>

      <div v-if="hasDrafts">
        <b-dropdown
          class="pt-5"
          text="Ready to Publish?"
          :variant="!broadcastPreviewData ? 'outline-danger' : 'outline-primary'">
          <b-dropdown-item-button @click="publish" :disabled="!broadcastPreviewData" style="cursor: pointer">
            <span class="text-primary"><fluency-icon type="rss" class="pr-3"></fluency-icon>Publish</span>
          </b-dropdown-item-button>
        </b-dropdown>
        <small v-if="!broadcastPreviewData" class="text-danger d-block">Preview your broadcasts before publishing</small>
      </div>
      <t-alert v-else severity="info" class="ml-2">There are no drafts to publish. No big deal. You can still change
        the preview date to see how your broadcasts will affect published ads.
      </t-alert>

    </div>
    <new-table id="broadcast-preview-table"
               class="overflow-auto"
               :items="pagedItems"
               :fields="previewFields">
      <template v-slot:empty>
        <fluency-loader v-if="fetchingPreviewData">Fetching Preview</fluency-loader>
        <p class="text-center py-2" v-else>
          There are no changes to published ads on the date {{isCustomerContext ? 'and account' : ''}} you selected.
          <b-button variant="link" class="py-0" v-b-toggle.collapse="'no-data-learn-more'">Learn More</b-button>
        </p>
        <b-collapse id="no-data-learn-more">
          <ol class="ml-5" style="font-size:.85rem;">
            <li class="py-4">
              <b>Is your broadcast in <em>DRAFT</em>?</b> If so, double check your <b>Match Rules</b> and date ranges.
            </li>
            <li class="py-4">
              <b>Is your broadcast <em>PUBLISHED?</em></b> If so, the ad copy currently serving is no different than the
              {{isCustomerContext ? ' preview account\'s' : ''}} ad copy that will serve on <b>{{previewDate ? $moment(previewDate).format('ll') : 'the selected preview date'}}</b>
              See what happens when you change the <b v-if="isCustomerContext">Preview Account</b> {{isCustomerContext ? 'or the' : ''}} <b>Preview Date</b> to be after the Broadcast expires.
            </li>
            <li class="py-4">
              <b>Will your broadcast start in the future?</b> If so, change the <b>Preview Date</b> so that you preview
              what will happen the day your Broadcast is supposed to take effect, i.e. (set the <b>Preview Date</b>
              equal to the Broadcast's <b>Valid From</b> date).
            </li>
            <li class="py-4">
              <b>Has your broadcast expired?</b> If so, Fluency has already removed the Broadcast message from the ad
              copy and republished its prior state. There is nothing to preview.
            </li>
            <li class="py-4">
              <b>Is your <em>Preview Date</em> after the broadcast expires?</b> If so, the ad copy currently serving is
              no different than the ad copy that will serve on the <b>Preview Date</b> after the Broadcast expires.
            </li>
            <li class="py-4">
              <b>Still stuck?</b> Contact Fluency if you are unable to see a preview.
            </li>
          </ol>
        </b-collapse>
      </template>

      <template v-slot:head(originalAd)>
        Published (Ad Copy as of Now)
      </template>

      <template v-slot:head(finalAd)>
        Broadcast Delta (Ad Copy as of {{previewDate ? $moment(previewDate).format('ll') : ''}})
      </template>

      <template v-slot:cell(originalAd)="row">
        <ad-preview :creative-plan="row.value"
                    :show-preview-label="true"
                    :delta="row.item.finalAd"
                    :channel-id="row.value.advertisingChannelId || 1"
                    delta-variant="info"
                    detailed
                    class="mt-0 mb-0"></ad-preview>
      </template>

      <template v-slot:cell(finalAd)="row">
        <ad-preview :creative-plan="row.value"
                    :delta="row.item.originalAd"
                    @delta="row.item.delta = $event"
                    :channel-id="row.value.advertisingChannelId || 1"
                    :show-preview-label="true"
                    detailed
                    class="mt-0 mb-0"></ad-preview>
      </template>

      <template v-slot:cell(delta)="row">
        {{row.value ? row.value.length : 0}}
      </template>
    </new-table>
    <p-paginator v-if="!fetchingPreviewData && items.length > perPage"
                :rows="perPage"
                :totalRecords="items.length"
                aria-controls="broadcast-preview-table"
                class="m-0"
                v-model:first="currentPageFirstIndex"/>
  </drawer>
</template>

<script>
import AdPreview from 'core-ui/components/adPreviews/adPreview'
import Drawer from 'core-ui/components/common/drawer.vue'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import SingleDatePicker from '~/components/common/dateSelector/components/singleDatePicker.vue'
import NewTable from 'core-ui/components/common/newTable.vue'
import { useAccountsMinimal } from '@/composables/useAccountsMinimal'

export default {
  name: 'broadcastPreview',
  components: { NewTable, SingleDatePicker, FluencyLoader, Drawer, AdPreview },
  props: {
    showPreview: {
      type: Boolean
    },
    broadcasts: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const { accountsMinimalRef } = useAccountsMinimal()
    return {
      accountsMinimalRef
    }
  },
  data () {
    return {
      previewDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      previewAccountPlanId: null,
      fetchingPreviewData: false,
      broadcastPreviewData: null,
      previewFields: [
        { key: 'campaign', sortable: true },
        { key: 'adGroup', sortable: true },
        { key: 'originalAd' },
        { key: 'finalAd' }
      ],
      currentPageFirstIndex: 0,
      perPage: 100
    }
  },
  watch: {
    previewDate: {
      handler: function (val) {
        if (val) {
          this.getPreview()
        } else {
          this.broadcastPreviewData = null
        }
      }
    },
    previewAccountPlanId: {
      handler: function (val) {
        if (val) {
          this.getPreview()
        } else {
          this.broadcastPreviewData = null
        }
      }
    },
    show: {
      handler: function (val) {
        if (val) {
          this.getPreview()
        } else {
          this.broadcastPreviewData = null
        }
      },
      immediate: true
    }
  },
  computed: {
    isCustomerContext () {
      return this.$route.name.includes('customer')
    },
    accountPlanList () {
      if (this.isCustomerContext) {
        const sortedPlans = [...this.accountsMinimalRef]
        sortedPlans.sort((a, b) => (a.name || String('')).localeCompare(b.name || ''))
        return sortedPlans
      }
      return []
    },
    isAccountContext () {
      return this.$route.name.includes('manage')
    },
    items () {
      if (this.broadcastPreviewData) {
        return this.broadcastPreviewData.map(d => (
          {
            campaign: d.campaignName,
            adGroup: d.adGroupName,
            originalAd: {
              ...d.originalAd
            },
            finalAd: {
              ...d.finalAd
            },
            delta: []
          }
        ))
      }
      return []
    },
    pagedItems () {
      return this.items.slice(this.currentPageFirstIndex, this.currentPageFirstIndex + this.perPage)
    },
    hasDrafts () {
      const drafts = this.broadcasts.filter(b => b.status === 'DRAFT')
      return drafts.length > 0
    },
    show: {
      get () {
        return this.showPreview
      },
      set (val) {
        this.$emit('update:showPreview', val)
      }
    },
    drawerOpeningWidth () {
      if (this.$route.path === '/manage/account/') {
        return '100%'
      }
      return '90%'
    }
  },
  methods: {
    async getPreview () {
      if (this.previewDate && this.broadcasts && this.broadcasts.length > 0 && (this.isAccountContext || this.previewAccountPlanId)) {
        this.fetchingPreviewData = true
        this.broadcastPreviewData = null
        const response = this.isAccountContext
          ? await this.$res.fetch.broadcastPreview(this.broadcasts[0].accountPlanId, this.previewDate)
          : await this.$res.fetch.broadcastPreview(this.previewAccountPlanId, this.previewDate)

        this.currentPageFirstIndex = 0
        this.broadcastPreviewData = response
      }
      this.fetchingPreviewData = false
    },
    async publish () {
      this.$emit('publish')
      this.$nextTick(() => {
        this.previewAccountPlanId = null
        this.broadcastPreviewData = null
        this.show = false
      })
    }
  }
}
</script>
