<template>
  <div class="d-inline-block text-center partner-icons-container white-space-no-wrap">
    <span>
      <a v-for="partnerDataItem in partnerData"
         :key="partnerDataItem.partnerId"
         :class="[`px-${spacing}`, { 'btn btn-ghost-light': btns, 'pointer-events-none': readOnly, 'cursor-pointer':  partnerDataItem.partnerId !== 17, 'cursor-disabled': partnerDataItem.partnerId === 17 }, 'position-relative']"
         target="_blank"
         :href="(!partnerDataItem.lastError && partnerDataItem.partnerId !== 17) ? partnerDataItem.href || '#' : undefined"
         :id="uid(partnerDataItem)"
         v-p-tooltip.top="{ value: partnerDataItem.toolTipMessage, showDelay: 1000 }"
         @click="handlePartnerDataClick($event, partnerDataItem)"
      >
        <!--PartnerUpdatesProcessing-->
        <template v-if="(partnerDataItem.status === 'REMOVED' && !showRemoved) || !(pendingWorkMap[partnerDataItem.partnerId]?.pendingOperations?.length > 0)"></template>
        <span v-else class="position-relative">
          <fluency-icon class="position-absolute rotating p-0 m-0"
                        style="font-size:200%;z-index:1;top:-0.2em;left:-0.1em;"
                        type="loop" />
        </span>
        <!--PartnerImage-->
        <template v-if="partnerDataItem.status === 'REMOVED' && !showRemoved"></template>
        <img v-else :class="[{ grayscale: partnerDataItem.missingRefId }, 'partner-image']"
             :alt="PartnerName(partnerDataItem.partnerId)"
               :src="partnerImages[partnerDataItem.assetName]" />
        <!--PartnerLabel-->
        <span v-if="partnersLabel" class="mr-2">{{PartnerName(partnerDataItem.partnerId)}}</span>
        <!--PartnerError-->
        <div v-if="!(pendingWorkMap[partnerDataItem.partnerId]?.pendingOperations?.length > 0) &&
                    (pendingWorkMap[partnerDataItem.partnerId] ? pendingWorkMap[partnerDataItem.partnerId].lastError : partnerDataItem.lastError)"
              class="round danger xs position-absolute font-weight-bold"
              style="top:-4px;right:-2px;font-size:10px">
          !
<!--          <partner-error-popover  v-if="partnerDataItem.lastError"  v-bind="partnerDataItem"  />-->
        </div>
      </a>
      <partner-error-popover ref="partnerErrorPopoverRef" v-bind="lastErrorPartnerDataItem || {}" />
    </span>

  </div>
</template>

<script setup>
import { PartnerName } from '@/assets/js/constants'
import useAssets from '@/composables/useAssets'
import { usePartnerPolling } from '../../composables/usePartnerPolling'
import PartnerErrorPopover from './partnerErrorPopover.vue'
import { computed, ref, watch } from 'vue'

const nuxtApp = useNuxtApp()
const props = defineProps({
  primaryType: String,
  partners: Array,
  filterPartnerIds: {
    // only these partner ids will be displayed
    type: Array,
    default: () => []
  },
  spacing: {
    type: Number,
    default: 1
  },
  partnersLabel: {
    type: Boolean,
    default: false
  },
  btns: {
    type: Boolean,
    default: false
  },
  pendingWork: {
    type: Array,
    default: () => []
  },
  lazyLoadTooltips: {
    type: Boolean, // set lazy load tooltips to true to use the loadTooltips prop to control when partnerIcons will mount the tooltips (usually after first hover state)
    default: false
  },
  loadTooltips: {
    type: Boolean, // when lazyLoadTooltips is true, use this prop to control when the tooltips are mounted
    default: false
  },
  accountPlanId: {
    type: Number
  },
  stopPropagation: {
    type: Boolean
  },
  readOnly: {
    type: Boolean
  }
})

const { getPendingWork } = usePartnerPolling()
const removedStatuses = ['REMOVED', 'DELETED', 'ARCHIVED']
const pendingWorkMap = computed(() => props.pendingWork.map(pendW => getPendingWork(pendW)).toMap(pendW => pendW.partnerId))
const showRemoved = computed(() => nuxtApp.$store.getters.showPlanStatus === 'Show All')
const partnerIdsFromProps = computed(() => (props.partners || []).map(partner => partner.partnerId).distinct())
const partnerObjects = computed(() => props.partners.length <= 1
  ? props.partners
  : props.partners.filter(p => removedStatuses.indexOf(p.status) < 0))
const uniqueId = Math.random().toString(36).slice(2, 11)

const enabledPartners = computed(() => {
  const enabledAdvertisingChannels = nuxtApp.$store.getters.advertisingChannels.filter(c => c.enabled)
  if (nuxtApp.$store.getters.partners) {
    const enabledPartnerIds = enabledAdvertisingChannels.flatMap(c => c.partnerIds).distinct()
    return nuxtApp.$store.getters.partners
      .filter(partner => enabledPartnerIds.includes(partner.partnerId) &&
        (props.filterPartnerIds.length === 0 || props.filterPartnerIds.includes(partner.partnerId)))
      .filter(partner => partnerIdsFromProps.value.includes(partner.partnerId))
  }
  return []
})

const partnerImages = ref({})
watch(() => enabledPartners.value, (newVal) => {
  if (newVal) {
    newVal.forEach(partner => {
      if (!partnerImages.value[partner.asset]) {
        partnerImages.value[partner.asset] = ref('') // ? not sure whether this is creating a proxy
        useAssets(`/assets/img/${partner.asset}`)
          .then(img => {
            partnerImages.value[partner.asset] = img
          })
      }
    })
  }
}, { immediate: true })

const partnerData = computed(() => {
  return enabledPartners.value.map(partner => {
    const dataItem = {
      lastError: partnerObjects.value.find(p => !!p.lastError && p.partnerId === partner.partnerId)?.lastError || '',
      partnerId: partner.partnerId,
      accountPlanId: props.accountPlanId,
      type: props.primaryType,
      missingRefId: false,
      toolTipMessage: partner.name,
      assetName: partner.asset,
      href: partner.hrefTemplate,
      status: null,
      partnerInstance: partnerObjects.value.find(p => p.partnerId === partner.partnerId)
    }
    if (!dataItem.partnerInstance) {
      dataItem.missingRefId = true
      return dataItem
    }
    dataItem.status = dataItem.partnerInstance.status
    if (!dataItem.partnerInstance.referenceId && props.primaryType !== 'budget') {
      dataItem.missingRefId = true
      if (dataItem.lastError.length === 0) {
        dataItem.lastError = null
        dataItem.toolTipMessage = `Missing ref-id for ${partner.name} ${props.primaryType}`
      } else {
        dataItem.toolTipMessage = 'Click For Error'
      }
      return dataItem
    }

    if (dataItem?.lastError) {
      dataItem.toolTipMessage = 'Click For Error'
    }

    if ((dataItem.status !== 'REMOVED' || showRemoved) && pendingWorkMap[partner.partnerId]?.pendingOperations?.length > 0) {
      dataItem.toolTipMessage = 'Updates Processing'
    }

    if (!partner.queryparams) {
      return dataItem
    }

    const queryString = partner.queryparams.split('||')
      .map(param => mapQueryParam(param, dataItem))
      .filter(p => !!p)
      .join('&')

    if (queryString.includes('undefined') || !dataItem.href) {
      dataItem.href = null
    } else {
      dataItem.href = dataItem.href.replace('{{QUERY_PARAMS}}', queryString)
    }

    return dataItem
  })
})

const mapQueryParam = (param, dataItem) => {
  if (!dataItem?.partnerInstance?.partnerId) {
    return null
  }
  switch (dataItem.partnerInstance.partnerId) {
    case 2:
    case '2':
      return mapQueryParamForPartner2(param, dataItem)
    case 3:
    case '3':
      return mapQueryParamForPartner3(param, dataItem)
    case 5:
    case '5':
      dataItem.href = dataItem.href.replace(`{{${param}}}`, dataItem.partnerInstance.referenceId?.substring(2) ?? '')
      return `${param}=${dataItem.partnerInstance.referenceId}`
    default:
      dataItem.href = dataItem.href.replace(`{{${param}}}`, dataItem.partnerInstance.referenceId)
      return `${param}=${dataItem.partnerInstance.referenceId}`
  }
}

const mapQueryParamForPartner2 = (param, dataItem) => {
  if (param === 'aid') {
    if (props.primaryType === 'account') {
      return `${param}=${dataItem.partnerInstance.referenceId}`
    } else {
      // attempt to get the account ref from active item
      const refId = getAccountReferenceIdFromActiveItem({ partnerId: dataItem.partnerInstance.partnerId })
      if (refId?.length > 0) {
        dataItem.href = dataItem.href.replace(`{{${param}}}`, refId)
        return `${param}=${refId}`
      }
    }
  } else if (param === 'cid') {
    const partnerCustomerId = nuxtApp.$store.getters.customerState?.partnerCustomerIds?.[dataItem.partnerInstance.partnerId]
    if (!!partnerCustomerId && partnerCustomerId !== '0') {
      dataItem.href = dataItem.href.replace(`{{${param}}}`, partnerCustomerId)
      return `${param}=${partnerCustomerId}`
    }
  }
  return null
}

const mapQueryParamForPartner3 = (param, dataItem) => {
  const type = props.primaryType
  let partnerType = type
  if (type?.toLowerCase() === 'adgroup') {
    partnerType = 'adset'
  } else if (type?.toLowerCase() === 'userlist') {
    partnerType = 'audience'
    if (dataItem.href.indexOf('/manage') >= 0) {
      dataItem.href = dataItem.href.replace('/manage', '')
    }
  }
  if (dataItem.href.indexOf('{{primaryType}}') >= 0) {
    dataItem.href = dataItem.href.replace('{{primaryType}}', `${partnerType}s`)
  }
  if (param === 'act') {
    if (type === 'account') {
      return `${param}=${dataItem.partnerInstance.referenceId}`
    } else {
      const refId = getAccountReferenceIdFromActiveItem({ partnerId: dataItem.partnerInstance.partnerId })
      if (refId?.length > 0) {
        return `${param}=${refId}`
      }
    }
  }

  if (param === 'business_id') {
    const partnerCustomerId = nuxtApp.$store.getters.customerState?.partnerCustomerIds?.[dataItem.partnerInstance.partnerId]
    if (!!partnerCustomerId && partnerCustomerId !== '0') {
      return `${param}=${partnerCustomerId}`
    }
  }
  switch (`${type}_${param}`) {
    case 'campaign_selected_campaign_ids':
      return `${param}=${dataItem.partnerInstance.referenceId}`
    case 'adGroup_selected_adset_ids':
      return `${param}=${dataItem.partnerInstance.referenceId}`
    case 'ad_selected_ad_ids':
      return `${param}=${dataItem.partnerInstance.referenceId}`
  }
}

const getAccountReferenceIdFromActiveItem = ({ partnerId }) => {
  const subAccounts = nuxtApp.$store.getters.activeItem?.planType === 'account'
    ? nuxtApp.$store.getters.activeItem?.subAccounts
    : nuxtApp.$store.getters.activeItem?.accountPlan?.subAccounts ?? []
  if (subAccounts?.length > 0) {
    const subAccount = subAccounts.find(sub => sub.partnerId === partnerId)
    if (subAccount) {
      return subAccount.referenceId
    }
  }
  return null
}

const uid = (partnerDataItem) => {
  return `${uniqueId}${partnerDataItem.partnerInstance?.referenceId || ''}${partnerDataItem.partnerInstance?.accountId || ''}${partnerDataItem.partnerInstance?.campaignId || ''}${props.partnerInstance?.insertionOrderId || ''}${props.partnerInstance?.lineItemId || ''}${partnerDataItem.partnerInstance?.adGroupId || ''}${partnerDataItem.partnerInstance?.creativeId || ''}${(partnerDataItem.partnerInstance?.name || '')
    .replace(/\W/g, '')}`
    .replace(/\s+/g, '')
}

const partnerErrorPopoverRef = ref(null)
const lastErrorPartnerDataItem = ref(null)
const handlePartnerDataClick = ($event, partnerDataItem) => {
  if (props.stopPropagation) {
    $event.stopPropagation()
  }
  if (partnerDataItem.lastError) {
    lastErrorPartnerDataItem.value = partnerDataItem
    partnerErrorPopoverRef.value.show($event)
  }
}

</script>
