<template>
  <div v-if="isAllowed" class="fluent-ai-chat" ref="componentRoot">
    <!-- bubble style -->
    <div v-if="bubbleStyle">
      <div class="mb-2"><fluency-icon type="muse" size="sm"/> Muse</div>
      <div class="d-inline-block p-4 text-bubble muse bg-white">
        {{ computedTitle }}
      </div>

      <div v-for="(prompt, index) in history" :key="'response' + index">
        <div v-if="prompt.userPrompt" class="text-right">
          <div class="mt-4 mb-2">You</div>
          <div class="d-inline-block p-4 text-bubble you bg-info text-white">
            {{prompt.userPrompt}}
          </div>
        </div>
        <div class="position-relative mb-2">
          <div>
            <div v-if="prompt.userPrompt" class="mt-4 mb-2"><fluency-icon type="muse" size="sm"/> Muse</div>
            <div class="d-inline-block p-4 text-bubble muse bg-white">
              <div v-if="prompt.modelResponse" class="prompt-html" v-markdown="prompt.modelResponse"></div>
              <fluency-loader class="prompt-loader" v-if="index === history.length - 1 && loading" inline dots color></fluency-loader>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showBubbleSuggestions" class="suggestions">
        <div v-for="(suggestion, index) in suggestions" :key="`suggestion-${index}`" class="d-inline-block mt-2 mr-2 px-3 py-1 border text-bubble suggestion hover" role="button" @click="userPrompt = suggestion.subText || suggestion; chatPrompt()">
          {{ suggestion.subText || suggestion }}
        </div>
      </div>
    </div>

    <!-- panel style -->
    <div v-else class="fluent-ai-chat container d-flex flex-column justify-content-end" ref="componentRoot" :style="`width: ${width}px;`">
      <div>
        <div v-if="history.length === 0" class="d-flex-center-center flex-column mt-5">
          <h2 class="my-5">{{computedTitle}}</h2>
          <div v-for="(row, rIndex) in suggestionRows" :key="rIndex" class="d-flex align-self-stretch">
            <div v-for="(suggestion, sIndex) in row" :key="suggestion.title" class="w-50 mb-4">
              <div class="cursor-pointer glow-hover border rounded p-4 h-100" :class="{ 'ml-4': sIndex === 1}" @click="addSuggestion(suggestion)">
                <h4>{{suggestion.title}}</h4>
                <div class="text-muted">{{suggestion.subText}}...</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="my-5" :style="`min-height: ${minHeight}px; font-size: 14px;`">
          <div v-for="(prompt, index) in history" :key="'response' + index">
            <div class="position-relative mb-5">
              <div class="d-flex-center-center flex-shrink-0 user-icon bg-info text-white">
                <span>{{userInitials}}</span>
              </div>
              <div>
                <div class="font-weight-bold" style="line-height: 40px;">You</div>
                <div style="white-space: pre-line;">{{prompt.userPrompt}}</div>
              </div>
            </div>
            <div class="position-relative mb-5">
              <div class="d-flex-center-center flex-shrink-0 user-icon bg-muse-purple stroke-white">
                <fluency-icon type="muse" />
              </div>
              <div>
                <div class="font-weight-bold" style="line-height: 40px;">Muse</div>
                <div v-markdown="prompt.modelResponse"></div>
                <div v-if="prompt.actions && prompt.actions.broadcast"><broadcast-suggestions :provided-account-broadcasts="prompt.actions.broadcast"></broadcast-suggestions></div>
                <div v-if="hasSessionStorage && prompt.datasetDirectives" class="d-flex-center-end my-4">
                  <b-btn v-for="(dataset, datasetKey) in prompt.datasetDirectives"
                         :key="datasetKey"
                         variant="info"
                         class="ml-3"
                         @click="loadDataset(prompt.datasetDirectives, datasetKey)">View "{{formatObjectType(dataset.objectType)}}" data</b-btn>
                </div>
                <fluency-loader v-if="index === history.length - 1 && loading" inline dots color class="mt-4"></fluency-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fluent-ai-input pb-5 w-100">
        <div class="d-flex align-items-end">
          <p-textarea id="muse-chat-textarea"
                      class="flex-grow-1"
                      :placeholder="placeholder"
                      v-model="userPrompt"
                      autoResize rows="1"
                      size="large"
                      @keyup.enter="chatPrompt()"></p-textarea>
          <b-button variant="info" class="d-flex-center-center p-4 ml-4" @click="chatPrompt()">
            <fluency-icon type="arrowUp"></fluency-icon>
          </b-button>
        </div>
        <div class="my-4 d-flex-center flex-wrap">
          <b-button variant="link" class="text-info p-0 white-space-no-wrap mr-5" @click="clearHistory()" :disabled="history.length === 0 || loading">Clear Chat History</b-button>
          <small class="text-muted flex-1" style="min-width: 100px;">*All AI tools can make mistakes. It's a good idea to check important information.</small>
        </div>
      </div>
      <template v-if="showSinglePlanPrompt && history.length === 0">
        <div class="d-flex-center mb-4">
          <div>OR</div>
          <div class="flex-grow-1 border-bottom ml-4"></div>
        </div>
        <div  class="align-self-stretch cursor-pointer glow-hover border rounded p-4 mb-4" @click="promptAboutPlan()">
          <h4>Analyze {{$filters.convertFromCamelCase(planType)}}</h4>
          <div class="text-muted">{{planPrompt}}</div>
        </div>
      </template>
    </div>
    <async-fetcher v-if="enable"
                   hide-loader
                   :timeout="300"
                   ref="asyncFetcher"
                   @start="loading = true"
                   @end="loading = false"
                   @kill="loading = false"
                   @status="handleStatus($event)"
                   @success="handleSuccess($event)"></async-fetcher>
  </div>
</template>
<script>
import AsyncFetcher from 'core-ui/components/common/asyncFetcher'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import BroadcastSuggestions from 'core-ui/components/common/broadcastSuggestions.vue'
export default {
  name: 'fluentGpt',
  components: { AsyncFetcher, FluencyLoader, BroadcastSuggestions },
  props: {
    width: {
      type: Number,
      default: 800
    },
    minHeight: {
      type: Number,
      default: 500
    },
    suggestions: {
      type: Array,
      default: () => [
        {
          title: 'Summarize',
          subText: 'Summarize the following document'
        },
        {
          title: 'Code Help',
          subText: 'Write a MySQL query that will'
        }
      ]
    },
    placeholder: {
      type: String,
      default: 'Message Muse'
    },
    title: {
      type: String,
      default: 'How Can We Help You Today?'
    },
    csvData: {
      type: [String, Function]
    },
    jsonData: {
      type: String
    },
    immediatePrompt: {
      type: Boolean
    },
    immediatePromptText: {
      type: String
    },
    planType: {
      type: String
    },
    planTypeId: {
      type: Object
    },
    enable: {
      type: Boolean,
      default: true
    },
    bubbleStyle: {
      type: Boolean,
      default: false
    },
    inputPrompt: {
      type: String
    },
    forceAllow: {
      type: Boolean,
      default: false
    },
    showCredits: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    inputPrompt: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.userPrompt = newVal
          this.chatPrompt()
        }
      }
    },
    enable: {
      immediate: true,
      handler: function (newVal) {
        if (this.immediatePrompt && newVal) {
          if (!this.userPrompt) {
            if (this.immediatePromptText) {
              this.userPrompt = this.immediatePromptText
            } else {
              this.userPrompt = this.planPrompt
            }
          }
          this.$nextTick(() => {
            this.chatPrompt()
          })
        }
        if (newVal === false) { // if
          this.loading = false
        }
      }
    }
  },
  data () {
    return {
      history: [],
      userPrompt: '',
      loading: false,
      scrollParent: undefined,
      isSinglePlanPrompt: false
    }
  },
  computed: {
    user () {
      return this.$store.getters?.user
    },
    isAllowed () {
      return this.user?.capabilities?.AIChat || this.forceAllow
    },
    userInitials () {
      return `${this.user?.firstName?.charAt(0) || ''}${this.user?.lastName?.charAt(0) || ''}`
    },
    suggestionRows () {
      const numRows = Math.ceil(this.suggestions.length / 2)
      const suggestionRows = []
      for (let i = 0; i < numRows; i++) {
        suggestionRows.push([this.suggestions[i * 2], this.suggestions[i * 2 + 1]])
      }
      return suggestionRows
    },
    showSinglePlanPrompt () {
      return this.planType && this.planTypeId
    },
    showBubbleSuggestions () {
      if (this.suggestions.length <= 0 || this.loading) {
        return false
      }
      // has to be finished loading, and theres no user prompt
      return !this.loading && !this.history[this.history.length - 1]?.userPrompt
    },
    computedTitle () {
      if (this.isSinglePlanPrompt) {
        return `What Can We Tell You About Your ${this.$filters.convertFromCamelCase(this.planType)}?`
      }
      return this.title
    },
    hasSessionStorage () {
      return !!sessionStorage
    },
    planPrompt () {
      return `Please analyze the following advertising ${this.$filters.convertFromCamelCase(this.planType)}, highlight strong performance if present, identify poor performing content, and recommend adjustments to be made to improve performance`
    }
  },
  methods: {
    async remainingBalance () {
      if (typeof this.$res?.ai?.balance !== 'function') { // need this here for colab instance where we dont have a balance endpoint
        return
      }
      const response = await this.$res?.ai?.balance()
      if (response) {
        this.$emit('newBalance', response)
      }
    },
    promptAboutPlan () {
      this.userPrompt = this.planPrompt
      this.isSinglePlanPrompt = true
      this.$nextTick(() => {
        this.chatPrompt()
      })
    },
    addSuggestion (suggestion) {
      this.userPrompt = suggestion.value || suggestion.subText
      const el = document.getElementById('muse-chat-textarea')
      if (el) {
        el.focus()
      }
    },
    clearHistory () {
      this.history = []
    },
    chatPrompt () {
      if (!this.loading && this.userPrompt) {
        const userPrompt = this.userPrompt
        this.userPrompt = ''
        const historyWithUserPrompts = this.history.filter(h => h.userPrompt)
        const history = (historyWithUserPrompts.length > 20 ? historyWithUserPrompts.slice(-20) : historyWithUserPrompts)
        const promptWithHistory = {
          history,
          current: {
            userPrompt,
            csvData: typeof this.csvData === 'function' ? this.csvData() : this.csvData
          }
        }
        if (this.jsonData) {
          promptWithHistory.current.jsonData = this.jsonData
        }
        if (this.isSinglePlanPrompt && promptWithHistory.history.length === 0) {
          this.$refs.asyncFetcher.asyncFetch(this.$res.ai.chatPromptPlanType, [this.planType, this.planTypeId])
        } else {
          this.$refs.asyncFetcher.asyncFetch(this.$res.ai.chatPrompt, [promptWithHistory])
        }
        this.history.push({
          userPrompt,
          modelResponse: ''
        })
        this.scrollToBottom(true)
      }
    },
    handleSuccess (success) {
      const finished = success
      const lastIndex = this.history.length - 1
      this.history[lastIndex] = finished
      if (this.bubbleStyle) {
        this.promptUserAgain()
      }
      this.remainingBalance()
      this.scrollToBottom()
    },
    handleStatus (status) {
      const lastIndex = this.history.length - 1
      const userPrompt = this.history[lastIndex]?.userPrompt
      if (!userPrompt) return
      this.history[lastIndex] = { userPrompt, modelResponse: status.content.join('') }
      this.$setCompat(this.history, lastIndex, { userPrompt, modelResponse: status.content.join('') })
      this.scrollToBottom()
    },
    scrollToBottom (forceScroll) {
      const bottom = (this.scrollParent.scrollHeight - this.scrollParent.offsetHeight)
      const scrolled = this.scrollParent.scrollTop
      const scrolledToBottom = scrolled >= (bottom - 2) && scrolled <= (bottom + 2)
      if (forceScroll || scrolledToBottom) {
        this.$nextTick(() => {
          this.scrollParent.scrollTo(0, this.scrollParent.scrollHeight || document.body.scrollHeight)
        })
      }
    },
    findScrollParent (node) {
      const isScrolling = (node) => {
        const overflow = getComputedStyle(node, null).getPropertyValue('overflow')
        return overflow.indexOf('scroll') > -1 || overflow.indexOf('auto') > -1
      }
      if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
        return undefined
      }
      let current = node.parentNode
      while (current?.parentNode) {
        if (isScrolling(current)) {
          return current
        }
        current = current.parentNode
      }
      return window
    },
    promptUserAgain () {
      setTimeout(() => {
        this.history.push({})
        this.loading = true
        setTimeout(() => {
          this.history[this.history.length - 1] = {
            modelResponse: 'Anything else I can help you with?'
          }
          this.loading = false
        }, 1000)
      }, 500)
    },
    formatObjectType (str) {
      let returnStr = str
      returnStr = this.$filters.convertFromCapsUnderscore(returnStr)
      returnStr = returnStr.replaceAll('api', 'API')
      return returnStr
    },
    loadDataset (datasets, datasetIndex) {
      if (this.hasSessionStorage) {
        sessionStorage.removeItem('fluentGptDataset')
        sessionStorage.setItem('fluentGptDataset', JSON.stringify(datasets))
        this.$eventBus.$emit('closeMuseChatDrawer')
        this.$router.push({
          path: '/reporting/data-explorer/',
          query: {
            sessionDatasetId: datasetIndex,
            sessionDatasetCache: new Date().getTime()
          }
        })
      }
    }
  },
  mounted () {
    this.scrollParent = this.findScrollParent(this.$refs.componentRoot)
  }
}
</script>
<style lang="scss">
.fluent-ai-chat {
  .fluent-ai-input {
    .form-control:focus {
      box-shadow: none;
      border-color: $fluency-blue;
    }
  }
  .user-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -48px;
  }
  .bg-muse-purple {
    background: $muse-light-purple;
  }
  .stroke-white {
    svg path {
      stroke: #fff;
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}
.text-bubble {
  border-radius: 20px;
  transition: all 0.3s ease;
  &.you {
    border-bottom-right-radius: 5px;
  }
  &.muse {
    border-top-left-radius: 5px;
  }
  &.suggestion:hover {
    background: #555;
    color: #fff;
  }
}
.prompt-html + .prompt-loader {
  margin-top: 1rem;
}
</style>
