import PrimeVueConfig from 'primevue/config'
import { FluencyTheme, FluencyPassThrough } from '../assets/js/primeVueTheme'
import Button from 'primevue/button'
import AutoComplete from 'primevue/autocomplete'
import InputText from 'primevue/inputtext'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanels from 'primevue/tabpanels'
import TabPanel from 'primevue/tabpanel'
import Dialog from 'primevue/dialog'
import Popover from 'primevue/popover'
import Tooltip from 'primevue/tooltip'
import ProgressBar from 'primevue/progressbar'
import Paginator from 'primevue/paginator'
import Toast from 'primevue/toast'
import Password from 'primevue/password'
import Carousel from 'primevue/carousel'
import Message from 'primevue/message'
import DatePicker from 'primevue/datepicker'
import Panel from 'primevue/panel'
import Accordion from 'primevue/accordion'
import AccordionPanel from 'primevue/accordionpanel'
import AccordionHeader from 'primevue/accordionheader'
import AccordionContent from 'primevue/accordioncontent'
import Checkbox from 'primevue/checkbox'
import Menu from 'primevue/menu'
import Textarea from 'primevue/textarea'

import { vue3Only } from 'core-ui/assets/js/vueCompatFlags'

// set compatConfig to vue3Only for prime vue components and all its child components before registering
const componentsWithChildComponents = [
  Paginator,
  Button,
  Password,
  Carousel,
  DatePicker,
  Message,
  Popover,
  Toast,
  Panel,
  Checkbox,
  AutoComplete,
  Menu
]

componentsWithChildComponents.forEach((parentComp) => {
  parentComp.compatConfig = vue3Only()
  Object.values(parentComp.components).forEach(childComp => {
    childComp.compatConfig = vue3Only()
  })
})
ProgressBar.compatConfig = vue3Only()

export const primeVueInstall = (nuxtApp) => {
  const Vue = nuxtApp.vueApp

  Vue.use(PrimeVueConfig, {
    zIndex: {
      overlay: 1986 // this isn't working
    },
    theme: {
      preset: FluencyTheme,
      options: {
        darkModeSelector: 'none'
      }
    },
    pt: FluencyPassThrough
  })

  InputText.compatConfig = vue3Only()
  AutoComplete.compatConfig = vue3Only()
  Tab.compatConfig = vue3Only()
  Tabs.compatConfig = vue3Only()
  TabList.compatConfig = vue3Only()
  TabPanel.compatConfig = vue3Only()
  TabPanels.compatConfig = vue3Only()
  Dialog.compatConfig = vue3Only()
  Popover.compatConfig = vue3Only()
  Panel.compatConfig = vue3Only()
  Accordion.compatConfig = vue3Only()
  AccordionPanel.compatConfig = vue3Only()
  AccordionHeader.compatConfig = vue3Only()
  AccordionContent.compatConfig = vue3Only()
  Checkbox.compatConfig = vue3Only()
  Textarea.compatConfig = vue3Only()
  Vue.component('p-button', Button)
  Vue.component('p-input-text', InputText)
  Vue.component('p-input-group', InputGroup)
  Vue.component('p-input-group-addon', InputGroupAddon)
  Vue.component('p-tab', Tab)
  Vue.component('p-tabs', Tabs)
  Vue.component('p-tab-list', TabList)
  Vue.component('p-tab-panel', TabPanel)
  Vue.component('p-tab-panels', TabPanels)
  Vue.component('p-modal', Dialog)
  Vue.component('p-paginator', Paginator)
  Vue.component('p-password', Password)
  Vue.component('p-carousel', Carousel)
  Vue.component('p-message', Message)
  Vue.component('p-progress-bar', ProgressBar)
  Vue.component('p-toast', Toast)
  Vue.component('p-date-picker', DatePicker)
  Vue.component('p-popover', Popover)
  Vue.component('p-auto-complete', AutoComplete)
  Vue.component('p-panel', Panel)
  Vue.component('p-accordion', Accordion)
  Vue.component('p-accordion-panel', AccordionPanel)
  Vue.component('p-accordion-header', AccordionHeader)
  Vue.component('p-accordion-content', AccordionContent)
  Vue.component('p-menu', Menu)
  Vue.component('p-menu-item', Menu.components.PVMenuitem)
  Vue.component('p-checkbox', Checkbox)
  Vue.directive('p-tooltip', Tooltip)
  Vue.component('p-textarea', Textarea)
}
