<script setup>
</script>

<template>
  <p-accordion>
    <template #collapseicon>
      <fluency-icon type="chevronUp"></fluency-icon>
    </template>
    <template #expandicon>
      <fluency-icon type="chevronDown"></fluency-icon>
    </template>
    <slot name="default"></slot>
  </p-accordion>
</template>
