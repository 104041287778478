<template>
  <div id="backpackHeaderNav" class="backpack-header header-navigation">
    <header class="navbar navbar-expand-sm fixed-top d-flex justify-content-between align-items-center" :class="{'header-local': runningLocal, 'header-record': recording }">

      <!-- Just an image -->
      <nuxt-link class="navbar-brand" to="/">
        <img v-if='logo' :src="headerLogoSrc" :height="logo.height" :style="logo.style"/>
      </nuxt-link>

      <div id="defaultHomePageButton"
           class="text-primary"
           role="button"
           tabindex="0"
           aria-label="default landing page"
           v-p-tooltip.bottom="{ value: getDefaultLandingPageTooltipText(), pt: { root: { class: 'tooltip-header-nav-z-index' } } }"
           @click="setDefaultLandingPageToCurrent"
           @keydown.space.prevent="setDefaultLandingPageToCurrent"
           @keydown.enter.prevent="setDefaultLandingPageToCurrent">
        <fluency-icon :type="isDefaultLandingPage ? 'star' : 'emptyStar'" :class="{'active':isDefaultLandingPage, 'landing-page-control':true}"/>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul v-if="navigationForUser.navigation" class="navbar-nav mr-auto">
          <template v-for="navItem in navigationForUser.navigation" :key="navItem.text">
            <li v-if="!navItem.dropDown"
                :class="'nav-item '+navItem.classes">
              <nuxt-link v-if="navItem.shortkey"
                         v-bind:class="navItem.classes"
                         v-shortkey="navItem.shortkey"
                         :to="navItem.link"
                         class="nav-link highlight"
                         @shortkey="shortkeyPressed(navItem)">
                {{navItem.text }}
                <div v-if="showShortkeys" class="position-absolute small" style="margin-top:-0.25em;"><shortkey-view :value="navItem.shortkey"/></div>
              </nuxt-link>
              <nuxt-link v-else
                         v-bind:class="navItem.classes"
                         :to="navItem.link"
                         class="nav-link highlight">
                {{navItem.shortkey}}
                {{navItem.text }}
              </nuxt-link>
            </li>
          </template>
          <li class="nav-item position-relative">
            <a :class="['nav-link', 'highlight', 'dropdown-toggle', {'active-child-link': !!activeReportingPage}]"
               href="#"
               id="navbarDropdown"
               role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Insights</a>
            <header-navigation-dropdown v-if="navigationForUser.reporting" :definition="navigationForUser.reporting" :active-page="activeReportingPage" />
          </li>
        </ul>
        <div title="Server calls routed to: http://localhost:8080" v-if="runningLocal" class="mr-3 local-dev pl-1 pr-1 ">LOCALSERVER</div>

        <template v-if="user && user.capabilities && user.capabilities.RecordMode">
          <user-recording />
        </template>

        <template v-if="user && user.capabilities && user.capabilities.CustomerSwitcher">
          <user-customer-selector class="mr-3"/>
        </template>

        <!--Right Nav-->
        <ul class="nav navbar-nav navbar-right" style="flex-wrap: nowrap;">
          <li>
            <b-dropdown variant="link-info"
                        class="settings"
                        toggle-class="border-0 p-0 no-focus"
                        lazy
                        right
                        no-caret>
              <template #button-content>
                <header-icon-notifications icon-type="settings"
                                           v-p-tooltip.bottom="{ value: 'Settings & Tools', pt: { root: { class: 'tooltip-header-nav-z-index' } } }"
                                           caret/>
              </template>

              <header-navigation-dropdown v-if=navigationForUser.settings :definition="navigationForUser.settings" :active-page="activeSettingsPage" right nestedDropdown />
            </b-dropdown>
          </li>
          <li>
            <nuxt-link v-if="coLabUser" to="/alerts/?types=REQUESTED_CHANGE_TASK">
              <header-icon-notifications :notifications="user.countOfCollaboratePending"
                                         class="colabHeaderLink"
                                         icon-type="envelopeClosed"
                                         v-p-tooltip.bottom="{ value: 'Review Requested CoLab Changes', pt: { root: { class: 'tooltip-header-nav-z-index' } } }" />
            </nuxt-link>
          </li>
          <template v-if="$route.name !== 'manage-manageType'">
            <li>
              <header-icon-notifications icon-type="search"
                                         title="Manage Account"
                                         class="jump-to-manage"
                                         v-p-tooltip.bottom="{ value: 'Jump to a Page <br /> (cmd + shift + j)', escape: false, pt: { root: { class: 'tooltip-header-nav-z-index' } } }"
                                         @click="$eventBus.$emit('openAccountJump')" />
            </li>
            <li v-if="whatFixEnabled">
              <fluency-support />
            </li>
          </template>

          <user-persona-configuration :value="showModalUserPersonaSettings"
                                      @hide-modal="userPersonalModal(false)" />

          <b-dropdown v-if="user && user.email"
                      class="user"
                      variant="link-info"
                      toggle-class="border-0 p-0 no-focus"
                      v-p-tooltip.bottom="'User Preferences'"
                      lazy
                      right
                      :toggle-attrs="{'data-qa': 'button-user-menu'}"
                      no-caret >
            <template #button-content>
              <header-icon-notifications icon-type="userCircle"
                                         :warning="userEmailUnsub"
                                         warningIcon="envelopeClosed"
                                         :show-internal-new-build-available="internalShowNeedsRefresh"
                                         caret />
            </template>

            <ul class="list-unstyled">
              <li v-if="user.family_name || user.given_name">
                <strong class="username">
                  {{user.given_name}} {{user.family_name}} ({{user.userName}})
                  <em v-if="readOnly" class="text-muted">(read-only)</em>
                </strong>
              </li>
              <li v-if="oktaUserId" class="font-weight-normal">
                <t-button-inline @click="mfaEnrollOpen = true">Manage MFA Login for Backpack</t-button-inline>
              </li>
              <li v-if="userEmailUnsub">
                <hr />
              </li>
              <li v-if="userEmailUnsub" class="font-weight-normal mt-2">
                <div class="d-flex-center">
                  <b-badge variant="warning" class="badge-full d-flex-center">
                    <fluency-icon type="envelopeClosed" class="mr-2" />
                    Unsubscribed from Emails
                  </b-badge>
                  <div class="flex-grow-1 text-right">
                    <t-button-inline @click="userEmailResubscribe()">Resubscribe</t-button-inline>
                  </div>
                </div>
              </li>
              <li>
                <hr />
              </li>
              <li @click.stop="stopProp">
                <user-mode-switcher />
              </li>
              <li @click.stop="stopProp">
                <guardrail-mode-switcher />
              </li>
              <li @click.stop="stopProp">
                <user-can-email-switcher />
              </li>
              <li @click.stop="stopProp">
                <account-two-factor />
              </li>
              <li>
                <hr />
              </li>
              <li :class="classes.welcomeItems">
                <nuxt-link to="/welcome/"
                           :class="classes.welcomeLinks">
                  <fluency-icon type="fluency" size="md" :class="classes.welcomeIcons" />
                  Welcome to Backpack
                </nuxt-link>
              </li>
              <li :class="classes.welcomeItems" @click.stop="stopProp()">
                <a href="#"
                   :class="classes.welcomeLinks"
                   @click.prevent="userPersonalModal()">
                  <fluency-icon type="preview" size="md" :class="classes.welcomeIcons" />
                  Visibility Preferences
                </a>
              </li>
              <li :class="classes.welcomeItems">
                <a href="#"
                   :class="classes.welcomeLinks"
                   @click.prevent="doUpdate(update)">
                  <fluency-icon type="reload" size="md" :class="classes.welcomeIcons" />
                  Reload Backpack
                </a>
              </li>
              <li v-if="isInternalUser" :class="classes.welcomeItems">
                <a href="#"
                   :class="classes.welcomeLinks"
                   @click.prevent="toggleExternalUser()">
                  <fluency-icon type="logout" size="md" :class="classes.welcomeIcons" />
                  Move to External User
                </a>
              </li>
<!--              <template v-if="eligibleForBionicSwitcher">-->
<!--                <hr />-->
<!--                <li :class="classes.welcomeItems">-->
<!--                  <a href="#"-->
<!--                     :class="classes.welcomeLinks"-->
<!--                     @click.prevent="toggleBionicLayout()">-->
<!--                    <fluency-icon type="fork" size="md" :class="classes.welcomeIcons" />-->
<!--                    Try Bionic Layout-->
<!--                  </a>-->
<!--                </li>-->
<!--              </template>-->
<!--              <template v-if="eligibleForSettingsSwitcher">-->
<!--                <hr />-->
<!--                <li :class="classes.welcomeItems">-->
<!--                  <a href="#"-->
<!--                     :class="classes.welcomeLinks"-->
<!--                     @click.prevent="toggleSettingsFork()">-->
<!--                    <fluency-icon type="fork" size="md" :class="classes.welcomeIcons" />-->
<!--                    <template v-if="appForkSettings">Back to Old Settings Layout</template>-->
<!--                    <template v-else>Try New Settings Layout</template>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </template>-->
              <template v-if="isInternalUser && isFluencyDemoAccount">
                <hr>
                <li :class="classes.welcomeItems">
                  <a href="#"
                     :class="classes.welcomeLinks"
                     @click.prevent="resetDemoData()">
                    <fluency-icon type="loop" size="md" :class="classes.welcomeIcons" />
                    Reset Demo Data
                  </a>
                </li>
              </template>
              <li>
                <hr />
              </li>
              <li :class="classes.welcomeItems">
                <a href="#"
                   :class="classes.welcomeLinks"
                   @click.prevent="logout">
                  <fluency-icon type="logout" size="md" :class="classes.welcomeIcons" />
                  Sign Out
                </a>
              </li>
            </ul>
          </b-dropdown>
        </ul>
      </div>
      <url-modal></url-modal>
      <jumper2></jumper2>
      <mfa-enroll v-model="mfaEnrollOpen"></mfa-enroll>
    </header>
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
import useAssets from '@/composables/useAssets.js'
import { useServiceWorker } from '../../composables/useServiceWorker.js'
const { $store } = useNuxtApp()
const { isWaiting } = useServiceWorker()

const headerLogoPath = ref('')
const headerLogoSrc = ref('')
const logo = computed(() => {
  return $store.getters.whiteLabel.headerLogo
})

watch(logo, async (newLogo) => {
  headerLogoPath.value = newLogo.path
  const img = await useAssets(`/assets/img/${newLogo.path}.png`)
  headerLogoSrc.value = img
}, { immediate: true })

const internalShowNeedsRefresh = computed(() => {
  return isWaiting.value && $store.getters?.user?.internalUser
})

const doUpdate = async (updateCallback) => {
  await useServiceWorker().deleteRuntimeCache()
  await updateCallback()
}
</script>

<script>
import UserModeSwitcher from '@/components/common/userModeSwitcher'
import GuardrailModeSwitcher from './guardrailModeSwitcher'
import ShortkeyView from '@/components/common/shortkeyView'
import HeaderIconNotifications from '@/components/common/headerIconNotifications'
import UserCustomerSelector from '@/components/common/userCustomerSelector'
import HeaderNavigationDropdown from '@/components/common/headerNavigationDropdown'
import UrlModal from '@/components/common/urlModal'
import UserCanEmailSwitcher from '@/components/common/userCanEmailSwitcher'
import AccountTwoFactor from '@/components/common/accountTwoFactor'
import UserRecording from '~/components/common/userRecording.vue'
import UserPersonaConfiguration from './userPersonaConfiguration.vue'
import FluencySupport from '@/components/common/fluencySupport'
import MfaEnroll from '@/components/common/mfaEnroll.vue'
import Jumper2 from '~/components/common/jumper2.vue'

export default {
  name: 'HeaderNavigation',
  components: {
    Jumper2,
    MfaEnroll,
    FluencySupport,
    UserRecording,
    UserPersonaConfiguration,
    AccountTwoFactor,
    UserCanEmailSwitcher,
    UrlModal,
    HeaderNavigationDropdown,
    UserCustomerSelector,
    HeaderIconNotifications,
    ShortkeyView,
    GuardrailModeSwitcher,
    UserModeSwitcher
  },
  data () {
    return {
      showUpdateBtn: false,
      showShortkeys: false,
      activeSettingsPage: undefined,
      activeReportingPage: undefined,
      runningLocal: false,
      showModalUserPersonaSettings: false,
      mfaEnrollOpen: false,
      classes: {
        welcomeLinks: 'd-flex-center-star',
        welcomeItems: 'text-right font-weight-normal my-4 mr-4',
        welcomeIcons: 'mx-3'
      }
    }
  },
  computed: {

    navigationForUser () {
      return this.$store.getters.navigationForUser
    },
    version () {
      return process.env.backpackVersion
    },
    recording () {
      return this.user?.recordMode
    },
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    },
    oktaUserId () {
      return this.$store.getters.oktaUserId
    },
    isInternalUser () {
      return this.$store.getters?.user?.internalUser
    },
    // eligibleForBionicSwitcher () {
    //   const whitelist = [
    //     'adam@fluency.inc',
    //     'adam@fluencyinc.co',
    //     'al@fluency.inc',
    //     'ali@fluencyinc.co',
    //     'amy@fluency.inc',
    //     'briana@fluency.inc',
    //     'claire@fluency.inc',
    //     'dan.sonneborn@fluency.inc',
    //     'eric@fluency.inc',
    //     'erin@fluency.inc',
    //     'gina@fluency.inc',
    //     'gina@fluencyinc.co',
    //     'greg@fluencyinc.co',
    //     'harleen@fluencyinc.co',
    //     'jarred@fluency.inc',
    //     'jeff@fluency.inc',
    //     'jeffrey@fluencyinc.co',
    //     'kris@fluency.inc',
    //     'kris@fluencyinc.co',
    //     'matt@fluencyinc.co',
    //     'michelle@fluencyinc.co',
    //     'peggy@fluency.inc',
    //     'peggy@fluencyinc.co',
    //     'scott+testinguser@fluencyinc.co',
    //     'scott@fluencyinc.co',
    //     'taylor@fluency.inc'
    //   ]
    //   const externalWhitelist = [
    //     'abhishek.pradhan@whatfix.com'
    //   ]
    //   // return (this.isInternalUser && whitelist.includes(this.user?.email)) || externalWhitelist.includes(this.user?.email)
    //   return this.isInternalUser || externalWhitelist.includes(this.user?.email)
    // },
    // eligibleForSettingsSwitcher () {
    //   const whitelist = ['greg@fluencyinc.co', 'matt@fluencyinc.co', 'jeff@fluency.inc', 'amy@fluency.inc', 'jeffrey@fluencyinc.co', 'adam@fluency.inc', 'michelle@fluencyinc.co', 'claire@fluency.inc', 'dan.sonneborn@fluency.inc', 'eric@fluency.inc', 'scott@fluencyinc.co', 'ali@fluencyinc.co', 'scott+testinguser@fluencyinc.co', 'kris@fluencyinc.co']
    //   return this.isInternalUser && whitelist.includes(this.user?.email)
    // },
    defaultHomePage () {
      return this.user.defaultHomePage || '/welcome/'
    },
    readOnly () {
      return (this.user.roles || []).includes('read_only')
    },
    coLabUser () {
      return this.user?.capabilities?.CoLab
    },
    whatFixEnabled () {
      return this.user?.capabilities?.WhatFix
    },
    isFluencyDemoAccount () {
      return this.$store.getters?.customerState?.customerId === 10000000001
    },
    userEmailUnsub () {
      return this?.user && this?.user?.emailUnsub
    },
    isDefaultLandingPage () {
      const homePageRoute = this.$router.resolve(this.defaultHomePage)
      if (homePageRoute?.name?.length > 0) {
        return this.$route.fullPath.startsWith(this.defaultHomePage)
      }
      return false
    },
    userAndChannels () {
      return {
        user: this.user,
        channels: this.$store.getters.advertisingChannels
      }
    }
  },
  watch: {
    $route: {
      handler (val) {
        const activeSettingsPage = this.activeDropDown(this.navigationForUser.settings, val)
        const activeReportingPage = this.activeDropDown(this.navigationForUser.reporting, val)
        if (activeSettingsPage !== this.activeSettingsPage) {
          this.activeSettingsPage = activeSettingsPage
        }
        if (activeReportingPage !== this.activeReportingPage) {
          this.activeReportingPage = activeReportingPage
        }
      },
      immediate: true,
      deep: true
    },
    userAndChannels: {
      immediate: true,
      deep: true,
      handler () {
        this.$store.dispatch('trimNavForUser')
      }
    }
  },
  created () {
    if (this.$store.getters.isFluencyUser) {
      this.checkVersion()
    }
    this.setupShortkeys()
    if (this.$store.getters.isLocalDev) {
      this.$setCompat(this, 'runningLocal', true)
    }
  },
  methods: {
    toggleExternalUser () {
      const newUser = {
        ...this.user,
        internalUser: false
      }

      this.$store.commit('user', newUser)
    },
    toggleBionicLayout () {
      this.$store.dispatch('toggleForkedUiElement', { val: 'bionic', key: 'nav' })

      // TODO: remove localStorage thing before eventual go-live
      localStorage.setItem('fluencyLayoutVariant', 'bionic')
    },
    async checkVersion () {
      let dbVersion = await this.$res.fetch.backpackVersion()
      dbVersion = dbVersion?.version
      let jsVersion = this.$store.getters.backpackVersion
      if (!dbVersion || typeof dbVersion !== 'string' || dbVersion.indexOf('.') === -1) return // validate db version
      dbVersion = dbVersion.replace('v', '').split('.')
      if (!jsVersion || jsVersion.length < 1) {
        this.$store.dispatch('setBackpackVersion', dbVersion)
        jsVersion = dbVersion
      }

      // console.log(dbVersion, jsVersion)
      // if a minor version has been released
      if ((parseFloat(dbVersion[1]) > parseFloat(jsVersion[1]))) {
        const dialogResponse = await this.$confirm({
          text: 'A new version of Backpack is available. Let\'s get you set up with the most current version, it will only take a second. The page will refresh so make sure you\'ve saved any recent work. If you need to finish up what you\'re doing, hit \'Dismiss for now\' and simply click \'Reload Backpack\' in your user dropdown menu above as soon as you get a chance.',
          'ok-title': 'Let\'s Do This!',
          'cancel-title': 'Dismiss For Now',
          title: 'Let\'s Get On The Same Page'
        })
        // console.log('dialog response', dialogResponse)
        if (dialogResponse) {
          this.$store.dispatch('setBackpackVersion', dbVersion)
          this.update()
        }
      }
    },
    activeDropDown (dropdown, route) {
      if (!dropdown) return undefined
      let on
      for (const topLevelItem of dropdown) {
        for (const section of topLevelItem.sections) {
          for (const link of section) {
            if (route.path === link.link ||
              route.fullPath === link.link) {
              on = link.text
            }
          }
        }
      }
      return on
    },
    setupShortkeys () {
      const v = this
      document.addEventListener('keydown', function (e) {
        if (e.metaKey && e.shiftKey && e.ctrlKey) {
          v.showShortkeys = true
        }
      })
      document.addEventListener('keyup', function (e) {
        v.showShortkeys = false
      })
    },
    shortkeyPressed (navItem) {
      if (navItem.link) {
        this.$router.push({ path: navItem.link })
      }
    },
    stopProp () {
      return false
    },
    async logout () {
      if (this.$authn.isAuthenticated()) {
        await this.$authn.logout()
      }
    },
    async resetDemoData () {
      const response = await this.$res.demo.resetDemoEnvironment()

      if (response) {
        this.$toast('Demo environment reset', 'success')
        return
      }

      this.$toast('Failed to reset demo environment', 'danger')
    },
    async update () {
      const response = await this.$res.fetch.user()

      if (response) {
        const newUser = {
          ...this.user,
          capabilities: response.capabilities
        }

        await Promise.all([
          this.$store.commit('user', newUser)
        ])
      }

      ['verticals', 'skeletons', 'userSavedSegments', 'savedSegmentFields', 'enumerations', 'reportDefinitions', 'partners', 'advertisingChannels'].forEach(cacheKey => {
        localStorage.removeItem(cacheKey)
      })

      window.location.reload(true) // this non-standard param is for Firefox
    },
    getDefaultLandingPageTooltipText () {
      if (this.isDefaultLandingPage) {
        return 'This is your home page. To change it, navigate to your desired home page and click this button.'
      } else {
        return 'Click to set this page as your default home page'
      }
    },
    setDefaultLandingPageToCurrent () {
      this.$res.set.userHomePage(this.$route.path)
      this.$store.commit('userDefaultHomePage', this.$route.path)
      this.$toast(`Default Home Page set to ${this.$route.path}`, 'success')
    },
    userPersonalModal (val = true) {
      this.$setCompat(this, 'showModalUserPersonaSettings', val)
    },
    async userEmailResubscribe () {
      const resubscribed = await this.$res.user.emailResubscribe()

      if (resubscribed) {
        const newUser = {
          ...this.user,
          emailUnsub: false
        }

        this.$store.commit('user', newUser)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  #backpackHeaderNav {
    padding-top: $site-header-height;

    header {
      max-height: $site-header-height;
    }

    #navbarSupportedContent {
      max-height: $site-header-height;
    }
  }

  .backpack-header {
    .dropdown-menu {
      z-index: 1200 !important;
    }
  }

  header .nav-item {
    font-size: .875rem;
    margin: 0.25rem;
    font-family: 'DM Sans';

  }

  header .dropdown-menu {
    padding: 0.25rem;
  }

  header.navbar {
    padding: 0 .75rem;
    font-family: 'DM Sans';
    font-weight: 300;
    background-color: $site-header-bg-color;
    border-bottom: $site-header-border;
  }

  .user img {
    height: 1.8rem;
    border-radius: .3rem;
    margin-right: .3rem;
  }

  .navbar-brand {
    margin-right: 1.5rem;
  }

  .navbar {
    align-items: flex-start;
  }

  .nav-link {
    font-weight: 300;
    padding: 13px 0 14px 0;
    border-bottom: 2px solid transparent !important;
  }

  .nav-link.beta {
    position: relative;
    padding-right: 23px;
    overflow: hidden;
  }

  .nav-link.hide {
    display: none;
  }

  .nav-link.beta:after {
    content: 'beta';
    position: absolute;
    top: 10px;
    right: -25px;
    transform: rotate(45deg);
    padding: 0 30px;
    font-size: 14px;
    line-height: 14px;
    height: 16px;
    background: rgba($fluency-red, 0.5);
    color: $fluency-yellow;
  }

  .nav-link.beta:hover:after {
    background: rgba($fluency-red, 0.65);
  }

  .local-dev {
    color: $fluency-red;
    font-size: small;
    font-weight: 900;
  }
  .header-local {
    background-color: $fluency-paleblue !important;
  }
  .header-record {
    background-color: lighten($fluency-red, 32) !important;
  }
  .record {
    color: $fluency-gray;
    font-size: small;
    font-weight: 900;
  }

</style>
<style lang="scss">
  .header-navigation .highlight {
  color: $fluency-gray;
  transition: color 150ms linear;

  &:hover,
  &.active-link,
  &.active-child-link {
    color: darken($fluency-green, 15%) !important;
    /*font-weight: 400;*/
    border-bottom: 2px solid #6B9469 !important;
  }
}

  .header-navigation .landing-page-control {
    transition: transform 250ms ease;

    &.active {
      transform: rotate(360deg);
      color: $fluency-green;
    }
  }
  .dropdown-menu {
    min-width: 15rem;
  }

  .tooltip-header-nav-z-index {
    z-index: 1140 !important;
  }
  //.header-navigation .tooltip-z-index {
  //  z-index: 1040 !important;
  //}
</style>
