<template>
  <div class="d-flex-center">
    <b-checkbox :checked="checkboxVal"
                switch
                v-shortkey="['meta', 'shift', 'a']"
                @shortkey="keyboardToggle()"
                @change="checkboxVal = $event; userModeToggle($event)">
      Advanced View <shortkey-view :value="['meta', 'shift', 'a']" class="ml-2" />
    </b-checkbox>
  </div>

</template>

<script>
import ShortkeyView from '@/components/common/shortkeyView'
export default {
  name: 'UserModeSwitcher',
  components: { ShortkeyView },
  props: {
    variant: {
      type: String,
      default: 'success'
    }
  },
  data () {
    return {
      checkboxVal: null,
      backpackUserModeOptions: [
        { text: 'Basic', value: 'basic' },
        { text: 'Advanced', value: 'advanced' }
      ]
    }
  },
  watch: {
    toggleValueBool: {
      handler (val) {
        this.checkboxVal = val
      },
      immediate: true
    }
  },
  methods: {
    userModeToggle (value) {
      // this.$store.commit('setting', { key: 'userMode', value: value.value ? 'advanced' : 'basic' })
      this.setUserMode(value ? 'advanced' : 'basic')
    },
    keyboardToggle () {
      // this.$store.commit('setting', { key: 'userMode', value: (this.toggleValueBool) ? 'basic' : 'advanced' })
      this.checkboxVal = !this.checkboxVal
      this.setUserMode((this.toggleValueBool) ? 'basic' : 'advanced')
    },
    async setUserMode (value) {
      this.$store.commit('userMode', value)
      const resp = await this.$res.set.userPreference({
        type: 'DEFAULT',
        key: 'USER_MODE',
        value
      })
      if (!resp) {
        this.$toast('There was a problem setting your user mode.', 'warning')
        this.$store.commit('userMode', value === 'basic' ? 'advanced' : 'basic')
        return
      }
      this.$emit('toggle', value)
    }
  },
  computed: {
    userMode () {
      return this.$store.getters.userMode
    },
    toggleValueBool () {
      return this.userMode === 'advanced'
    }
  }
}
</script>

<style scoped lang="scss">
</style>
