<template>
  <div class="budget-amount-editor-v2" v-if="budgetPlan">
    <template v-if="!customSegmentedBudget || amountOrPercentage === 'BY_ALLOCATION'">
      <t-row>

        <!-- HELPER CALCULATOR ===================================================================================== -->
        <t-col v-if="showHelperCalculator && (computedBudgetType === 'MONTHLY' || computedBudgetType === 'DAILY')" cols="12">
          <div class="border rounded p-3 mb-5">
            <div>
              <div class="d-flex-start border-bottom mb-4 pb-2">
                <h6 class="flex-grow-1">Calculate the Monthly Budget</h6>
                <span class="cursor-pointer" @click="helperToggle()" ><fluency-icon type="x"/></span>
              </div>
            </div>
            <div>
              <span class="mad-lib-container">
                <span class="mr-2">Estimated Daily:</span>
                <basic-mad-lib-input :value="recurringDailyBudget"
                                     format="CURRENCY"
                                     :min-currency-value="0"
                                     @input="recurringDailyBudget = $event; recurringDailyBudgetInput($event)" />
                <span class="ml-2">
                  <info-tooltip popover title="Ideal daily spend to reach your monthly campaign budget." />
                </span>
              </span>
            </div>
          </div>
        </t-col>

        <!-- BUDGET AMOUNTS ===================================================================================== -->
        <!--<t-col v-if="showBudgetAmount" cols="12">-->
        <!--  <div v-if="computedBudgetType === 'MONTHLY' || computedBudgetType === 'DAILY'" class="mb-3">-->
        <!--    <b-btn variant="link" :disabled="showHelperCalculator" class="p-0" @click="helperToggle()">-->
        <!--      <fluency-icon type="calculator" />-->
        <!--      Help Me Calculate My Monthly Budget-->
        <!--    </b-btn>-->
        <!--  </div>-->
        <!--</t-col>-->
        <t-col :cols="columnLayout_01" class="my-3">
          <label v-if="showBudgetAmount && computedBudgetType === 'MONTHLY'" class="mb-0 d-flex-center">
            <span class="w-50"><template v-if="syntheticBudgetType === 'MONTHLY'">Recurring </template>Monthly</span>
            <b-btn variant="link" :disabled="showHelperCalculator" class="p-0" @click="helperToggle()">
              <fluency-icon type="calculator" /> Calculate
            </b-btn>
          </label>
          <label v-else>Budget Amount</label>
          <div class="mad-lib-container">
            <basic-mad-lib-input :value="budgetAmount"
                                 format="CURRENCY"
                                 :disabled="disableBudgetAmountInput"
                                 @focus="editingStatus('focus')"
                                 @blur="editingStatus('blur')"
                                 @input="updateAmount($event, true)">
              <template v-if="!noAdd" v-slot:edit="{value, input, blur, keyupEnterNative}">
                <budget-adjuster :value="value"
                                 @keyup.enter="keyupEnterNative()"
                                 @input="input($event); blur()"
                                 @enter="enterKeypress($event)" />
                <div v-if="blurHelper" class="d-block my-2"><b>NOTE:</b> Click outside input fields or hit tab key to enable Apply button.</div>
              </template>
            </basic-mad-lib-input>

            <fluency-icon v-if="budgetPlan.explicitlyShared" type="link" class="ml-4" />

            <b-button v-if="showBudgetBalancer && fullPage"
                      variant="link"
                      v-p-tooltip.right="`${budgetBalancerButtonText} to align this account's Applied Budget with its Approved Budget`"
                      class="px-0 mx-4"
                      @click="doBalance()">
              <fluency-icon type="budget" /> {{budgetBalancerButtonText}}
            </b-button>
          </div>
          <div v-if="!fullPage && showBudgetBalancer" class="mt-3">
            <div class="mt-2">
              <b-button v-if="showBudgetBalancer"
                        variant="link"
                        class="px-0"
                        @click="doBalance()">
                <fluency-icon type="budget" /> {{budgetBalancerButtonText}}
              </b-button>
            </div>
            <div class="text-muted">Apply this recommendation to align your applied budget with your approved account budget.</div>
            <t-alert v-if="showBudgetBalancer && nonBudgetedSpend > 0" show>
              This account has {{$filters.currency(nonBudgetedSpend)}} of non budgeted spend this month. This normally occurs when a campaign is paused mid-month, after it has already spent some of the account's budget.
              Making this adjustment can cause overspend this month, which can be mitigated by enabling overspend protection.
            </t-alert>
          </div>
          <div class="d-flex-center mt-4">
            <small v-if="fieldState.amount === false" class="text-danger">Budget Amount must be greater than $0</small>
            <div v-else-if="computedBudgetType !== 'LIFETIME'" class="d-flex-center">
              <b-form-checkbox :checked="budgetAmount === 0" switch @input="placeBudgetOnHold()"></b-form-checkbox>
              <span>Place Budget On Manual Hold</span>
            </div>
          </div>
        </t-col>

        <t-col v-if="showLifetimeInput" :cols="columnLayout_01" class="my-3">
          <label class="mb-0">Lifetime</label>
          <div class="mad-lib-container">
            <basic-mad-lib-input v-if="hasStartAndEndDates"
                                 :value="paceToSpendLifetimeValue"
                                 format="CURRENCY"
                                 @input="updatePaceToSpendLifetimeValue($event)" />
            <t-alert v-else show variant="warning">Lifetime requires both start &amp; end dates to be set.</t-alert>
          </div>
        </t-col>

        <!-- STARTDATE, ENDDATE, & PRORATION =================================================================================== -->
        <t-col v-if="showStartAndEndDates" cols="12">
          <div v-if="shouldShowProratedAmount()" class="mt-3 mb-5 text-muted">
            <b class="font-italic">Prorated Budget Amount this month:</b> <span>{{ $filters.currency(budgetPlan.proratedAmount, 2) }}</span>
          </div>

          <div class="d-flex my-3">
            <div class="w-50 mr-2">
              <label class="d-block">Budget Start Date:</label>
              <single-date-picker :value="budgetPlan.startDate"
                                  opens="right" :min-date="null"
                                  :hide-clear-button="computedBudgetType === 'LIFETIME'"
                                  @input="budgetPlan.startDate = $event; setDate()" />
            </div>
            <div class="w-50 ml-2">
              <label class="d-block">Budget End Date:</label>
              <single-date-picker :value="budgetPlan.endDate"
                                  :min-date="budgetPlan.startDate || null"
                                  :hide-clear-button="computedBudgetType === 'LIFETIME'"
                                  @input="budgetPlan.endDate = $event; setDate()" />
            </div>
          </div>

          <div v-if="shouldShowProrateToggle()" class="d-flex-center align-items-center my-3">
            <locked-field-v3 field="proratePartialMonth"
                             :value="budgetPlan.budgetManagerConfiguration.lockedFields"
                             @input="setBudgetManagerConfigLockedFields($event)"
                             class="mr-3" />
            <b-form-checkbox :checked="budgetPlan.proratePartialMonth"
                            switch
                            @change="budgetPlan.proratePartialMonth = $event; setProrateBudget($event)"
                            >Prorate Budget Amount</b-form-checkbox>
            <info-tooltip :title="`Allow Fluency to adjust the budget amount when the budget starts or ends mid-month.  If the budget start date is not set, the creation date ${budgetPlan.createdOn} will be considered.`" />
          </div>
        </t-col>
      </t-row>
    </template>
    <div v-else>
      This budget is segmented, please edit from the main budget view.
    </div>
  </div>
</template>

<script>
import { round as _round, cloneDeep as _cloneDeep } from 'lodash'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import BudgetAdjuster from '@/components/common/budgetAdjuster'
import LockedFieldV3 from '@/components/common/lockedFieldV3'
import SingleDatePicker from '@/components/common/dateSelector/components/singleDatePicker.vue'

export default {
  name: 'budgetAmountEditorV2',
  components: {
    SingleDatePicker,
    BasicMadLibInput,
    BudgetAdjuster,
    LockedFieldV3
  },
  props: {
    value: { // budgetPlan
      type: Object,
      required: true
    },
    accountPlan: {
      type: Object
      // required: true
    },
    amountOrPercentage: {
      type: String
    },
    noAdd: {
      type: Boolean,
      default: false
    },
    showLifetimeInput: {
      type: Boolean,
      default: true
    },
    showStartAndEndDates: {
      type: Boolean,
      default: true
    },
    showProratedAmount: {
      type: Boolean,
      default: true
    },
    showBudgetAmount: {
      type: Boolean,
      default: true
    },
    blurHelper: {
      type: Boolean,
      default: false
    },
    fullPage: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    validate: {
      from: 'validate',
      default: () => {
        return (name, { isValid }) => isValid
      }
    }
  },
  data () {
    return {
      magicNullDate: '1999-09-09',
      minDate: new Date(),
      showHelperCalculator: false,
      initialBudgetAmount: 0,
      recurringMonthlyBudget: 0,
      recurringDailyBudget: 0,
      lifetimeBudgetValue: null,
      helperCalcValueUsed: 'DAILY',
      lifetimeSupportedChannels: [2, 10],
      budgetHoldPriorAmount: 0
    }
  },
  watch: {
    'budgetPlan.budgetPlanId': {
      handler: function (val) {
        this.initialBudgetAmount = _round(parseFloat(this.budgetPlan?.budgetAmount ?? 0), 2)
      },
      immediate: true
    },
    budgetAmount: {
      handler (newVal) {
        this.recurringMonthlyBudgetInput(newVal)
      },
      immediate: true
    }
  },
  computed: {
    customSegmentedBudget () {
      const budgetBiasStrategy = this.budgetPlan?.budgetManagerConfiguration?.budgetBiasStrategy || this.budgetPlan?.budgetBiasStrategy
      if (budgetBiasStrategy?.includes('SEGMENT')) {
        return true
      } else {
        return false
      }
    },
    budgetPlan () {
      return this.value
    },
    approvedAccountBudget () {
      if (this.accountPlan?.approvedAccountBudget > 0) {
        return _round(parseFloat(this.accountPlan.approvedAccountBudget), 2)
      }
      return null
    },
    appliedBudget () {
      const budgetAmount = this.accountPlan?.budget ?? this.accountPlan?.metricsDTO?.budget ?? -1
      if (budgetAmount >= 0) {
        return _round(parseFloat(budgetAmount), 2)
      }
      return null
    },
    budgetAmount () {
      if (isNaN(this.budgetPlan.budgetAmount)) {
        return 0
      }
      return _round(parseFloat(this.budgetPlan.budgetAmount), 2).toFixed(2)
    },
    accountBudgetDifference () {
      return this.approvedAccountBudget - this.appliedBudget
    },
    budgetBalancerButtonText () {
      return `${this.accountBudgetDifference < 0 ? 'Reduce' : 'Increase'} by $${Math.abs(this.accountBudgetDifference).toFixed(2)}`
    },
    targetBudgetAmount () {
      return (this.initialBudgetAmount + this.accountBudgetDifference)
    },
    showBudgetBalancer () {
      return this.budgetPlan.budgetType !== 'LIFETIME' &&
        this.approvedAccountBudget != null &&
        this.appliedBudget != null &&
        this.accountBudgetDifference !== 0 &&
        this.budgetAmount !== this.targetBudgetAmount &&
        this.targetBudgetAmount > 0
    },
    nonBudgetedSpend () {
      return (this.accountPlan?.children || []).reduce((accumulator, current) => accumulator + (current.metricsDTO?.nonBudgetedMonthlySpend || 0), 0)
    },
    fieldState () {
      return {
        amount: this.validate('budget.amount', { isValid: this.budgetAmount >= 0, message: 'Budget Amount must be greater than $0' })
      }
    },
    computedBudgetType () {
      if (this.budgetPlan?.budgetManagerConfiguration?.budgetBiasStrategy?.indexOf('SEGMENT') > -1) {
        return 'SEGMENTED'
      }
      return this.budgetPlan.budgetType
    },
    syntheticBudgetType () {
      return this.budgetPlan?.syntheticBudgetType || this.budgetPlan?.budgetType
    },
    disableBudgetAmountInput () {
      return !!(this.value?.budgetManagerConfiguration?.paceToSpendLifetimeValue && this.value?.budgetManagerConfiguration?.paceToSpendLifetimeValue !== '')
    },
    paceToSpendLifetimeValue: {
      async set (val) {
        await this.lifetimeBudgetValueInput(val)

        let budgetPlan = _cloneDeep(this.budgetPlan)

        budgetPlan = await this.addPreviewSpecs({
          ...budgetPlan,
          budgetManagerConfiguration: {
            ...budgetPlan.budgetManagerConfiguration,
            paceToSpendLifetimeValue: val
          }
        })

        this.$emit('input', budgetPlan)
      },
      get () {
        return this.value?.budgetManagerConfiguration?.paceToSpendLifetimeValue
      }
    },
    hasStartAndEndDates () {
      return this.budgetPlan.startDate && this.budgetPlan.endDate && ![this.budgetPlan.startDate, this.budgetPlan.endDate].includes(this.magicNullDate)
    },
    columnLayout_01 () {
      return (this.fullPage && this.showLifetimeInput) ? 6 : 12
    },
    columnLayout_02 () {
      return (this.fullPage) ? 6 : 12
    },
    isTrueLifetime () {
      const ch = typeof this.budgetPlan.advertisingChannelId === 'string' ? parseInt(this.budgetPlan.advertisingChannelId) : this.budgetPlan.advertisingChannelId
      return this.lifetimeSupportedChannels.includes(ch)
    }
  },
  methods: {
    shouldShowProrateToggle () {
      return this.showProratedAmount &&
             this.budgetPlan.capabilities?.ProrateBudget &&
             this.budgetPlan?.budgetManagerConfiguration &&
             !(this.isTrueLifetime && this.budgetPlan?.syntheticBudgetType === 'FLIGHTED')
    },
    shouldShowProratedAmount () {
      if (this.showProratedAmount) {
        // if (this.budgetPlan.proratedAmount && this.$filters.currency(this.budgetPlan.budgetAmount, 2) !== this.$filters.currency(this.budgetPlan.proratedAmount, 2)) {
        //   return true
        // }

        if (this.budgetPlan.hasOwnProperty('prorationFactor') && this.budgetPlan.prorationFactor !== 1) {
          return true
        }
      }

      return false
    },
    async setDate () {
      // make sure calculator is open and has 'lifetime' value set
      if (this.lifetimeBudgetValue && this.showHelperCalculator) {
        await this.lifetimeBudgetValueInput(this.lifetimeBudgetValue)
      }

      await this.updateAmount(this.budgetAmount, true)

      this.$emit('dateInput', this.budgetPlan)

      // If all conditions for a `LIFETIME` budget exist, recalculate the monthly to make sure it correctly
      // divides the Lifetime value across the months.
      if (this?.paceToSpendLifetimeValue && this.hasStartAndEndDates) {
        const params = []
        if (this.budgetPlan.budgetPlanId) {
          params.push(`budgetPlanId=${this.budgetPlan.budgetPlanId}`)
        }
        params.push(`totalBudget=${this.paceToSpendLifetimeValue}`)
        params.push(`startDate=${this.budgetPlan.startDate}`)
        params.push(`endDate=${this.budgetPlan.endDate}`)

        const response = await this.$res.budget.calculateMonthlyBudget(params)

        this.budgetPlan.budgetAmount = this.formatBudgetAmount(response)
      }
    },
    helperToggle () {
      this.$setCompat(this, 'showHelperCalculator', !this.showHelperCalculator)
    },
    editingStatus (val) {
      this.$emit(val)
    },
    async lifetimeBudgetValueInput (newVal) {
      // clear other calc value(s)
      this.$setCompat(this, 'recurringDailyBudget', null)
      this.$setCompat(this, 'helperCalcValueUsed', 'LIFETIME')

      if (isNaN(newVal) || newVal === null) {
        // this.budgetPlan.budgetAmount = 0

        // if blank just leave monthly budget alone.
        return
      }

      // both `startDate` and `endDate` must be set to get a `200` back from the API
      if (!this.hasStartAndEndDates) {
        this.$toast('You must provide a Start Date and End Date', 'danger')
        return
      }

      const params = []
      if (this.budgetPlan.budgetPlanId) {
        params.push(`budgetPlanId=${this.budgetPlan.budgetPlanId}`)
      }
      params.push(`totalBudget=${newVal}`)
      params.push(`startDate=${this.budgetPlan.startDate}`)
      params.push(`endDate=${this.budgetPlan.endDate}`)

      const response = await this.$res.budget.calculateMonthlyBudget(params)

      this.budgetPlan.budgetAmount = this.formatBudgetAmount(response)
    },
    async recurringDailyBudgetInput (newVal) {
      // clear other calc value(s)
      this.$setCompat(this, 'lifetimeBudgetValue', null)
      this.$setCompat(this, 'helperCalcValueUsed', 'DAILY')

      if (isNaN(newVal) || newVal === null) {
        this.budgetPlan.budgetAmount = 0
        return
      }

      const params = [`dailyBudget=${newVal}`]

      if (this.budgetPlan.budgetPlanId) {
        params.push(`budgetPlanId=${this.budgetPlan.budgetPlanId}`)
      }

      // const monthly = (newVal * 365.0) / 12.0
      const monthly = await this.$res.budget.calculateMonthlyBudget(params)

      this.budgetPlan.budgetAmount = this.formatBudgetAmount(monthly)
      this.updateAmount(monthly)
    },
    recurringMonthlyBudgetInput (newVal) {
      if (isNaN(newVal)) {
        this.recurringDailyBudget = 0
        return
      }

      if (this.helperCalcValueUsed === 'DAILY') {
        // clear other calc value(s)
        this.$setCompat(this, 'lifetimeBudgetValue', null)
        this.recurringDailyBudget = _round((newVal * 12.0) / 365.0, 2).toFixed(2)
      }
      if (this.helperCalcValueUsed === 'LIFETIME') {
        // clear other calc value(s)
        this.$setCompat(this, 'recurringDailyBudget', null)
      }
    },
    changeBudgetType (val) {
      if (val === 'Recurring') {
        this.budgetPlan.budgetType = 'MONTHLY'
      } else {
        this.budgetPlan.budgetType = 'LIFETIME'
      }
    },
    async updateAmount (value, triggerPreview = false) {
      if (this.computedBudgetType === 'MONTHLY') {
        this.recurringMonthlyBudgetInput(value)
      }
      const amount = this.formatBudgetAmount(value)

      let budgetPlan = _cloneDeep(this.budgetPlan)

      if (triggerPreview) {
        budgetPlan = await this.addPreviewSpecs(budgetPlan)
      }

      this.$emit('input', {
        ...budgetPlan,
        budgetAmount: amount,
        budget: this.computedBudgetType === 'MONTHLY' ? amount : null,
        lifetimeBudget: this.computedBudgetType === 'LIFETIME' ? amount : null
      })
    },
    enterKeypress (e) {
      this.$emit('enterKeypress')
    },
    formatBudgetAmount (rawValue) {
      const parsedVal = parseFloat(rawValue)
      if (isNaN(parsedVal)) {
        return 0
      }
      return Math.round(parsedVal * 100) / 100
    },
    formatBudgetAmountString (val) {
      return `${this.formatBudgetAmount(val)}`
    },
    doBalance () {
      this.$emit('input', {
        ...this.budgetPlan,
        budgetAmount: (this.initialBudgetAmount + this.accountBudgetDifference).toFixed(2)
      })
    },
    async setBudgetManagerConfigLockedFields ($event) {
      const budgetPlan = _cloneDeep(this.budgetPlan)

      if (!budgetPlan.budgetManagerConfiguration) {
        return
      }

      this.$emit('input', {
        ...budgetPlan,
        budgetManagerConfiguration: {
          ...budgetPlan.budgetManagerConfiguration,
          lockedFields: $event
        }
      })
    },
    async setProrateBudget ($event) {
      let newBudget = _cloneDeep(this.budgetPlan)

      newBudget.proratePartialMonth = $event
      newBudget.budgetManagerConfiguration.lockedFields = this.updateLockedFields('proratePartialMonth', newBudget.budgetManagerConfiguration)
      newBudget = await this.addPreviewSpecs(newBudget)

      this.$emit('input', newBudget)
    },
    updateLockedFields (key, budgetManagerConfiguration) {
      const lockedFields = new Set((budgetManagerConfiguration?.lockedFields || '').split(','))
      lockedFields.add(key)
      return [...lockedFields].join(',')
    },
    async getPreviewPlan (budget) {
      if (budget && budget.budgetPlanId && budget.budgetManagerConfiguration) {
        const newBudget = _cloneDeep(budget)

        if (newBudget.budgetManagerConfiguration.budgetBiasStrategy === 'NONE') {
          newBudget.budgetManagerConfiguration.budgetBiasStrategy = 'EVEN'
        }

        const previewPlanResponse = await this.$res.budget.previewPlan(newBudget)

        const base = {
          exhausted: null,
          spendPlan: [],
          metricPlan: [],
          metricsSoFar: []
        }

        return { ...base, ...previewPlanResponse }
      }
      return []
    },
    async addPreviewSpecs (budgetPlan) {
      const newBudget = budgetPlan
      const strategy = newBudget.budgetManagerConfiguration?.budgetBiasStrategy

      if (strategy === 'CUSTOM') {
        const customPlan = await this.$res.budget.customSpendPlan(newBudget)
        if (customPlan) {
          newBudget.budgetOptimizerStatusDTO.spendPlanForMonth = customPlan
        }
      } else if (strategy !== 'SEGMENTED' && newBudget?.budgetOptimizerStatusDTO) {
        const previewPlan = await this.getPreviewPlan(newBudget)
        if (previewPlan) {
          newBudget.budgetOptimizerStatusDTO.exhausted = previewPlan.exhausted
          newBudget.budgetOptimizerStatusDTO.spendPlanForMonth = previewPlan.spendPlan
          newBudget.budgetOptimizerStatusDTO.metricPlanForMonth = previewPlan.metricPlan
          newBudget.budgetOptimizerStatusDTO.metricsSoFar = previewPlan.metricsSoFar
        }
      }

      return newBudget
    },
    updatePaceToSpendLifetimeValue (e) {
      this.$setCompat(this, 'paceToSpendLifetimeValue', e)
    },
    placeBudgetOnHold () {
      const amount = this.budgetHoldPriorAmount
      this.budgetHoldPriorAmount = this.budgetAmount
      this.updateAmount(amount, true)
    }
  }
}
</script>

<style lang='scss'>
.budget-amount-editor-v2 .calculator-datepicker {
  width: 100%;
}
</style>
